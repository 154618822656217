import { useMemo, useEffect } from 'react';
import React, { useState, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { pdfHeaderForPreview } from '../utils/ExportUtil'
// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PrePDF = (props: pdfProps) => {
  const [numPages, setNumPages] = useState(0);
  const [unmount, setUnmount] = useState(true)
  const [pdfWidth, setPdfWidth] = React.useState('100%');
  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf.numPages);
  }
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setPdfWidth(node.getBoundingClientRect().width)
    }
  }, []);
  useEffect(() => {
    return () => setUnmount(false)
  }, [])
  const file = useMemo(() => props?.local ? props?.file : pdfHeaderForPreview(props?.file), [props?.file]);// eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div style={{ overflowY: 'auto', width: '100%', height: '100%' }} ref={measuredRef}>
      <Document
        file={file}
        onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf)}
        key={props?.name}
        options={{
          cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
          cMapPacked: true,
          disableWorker: true,
          disableFontFace: true
        }}
        style={{
          width: '100%'
        }}
      >
        {
          props?.name !== 'pre' ? unmount && Array.from(
            new Array(numPages),
            (_, index) => (
              <Page
                width={pdfWidth}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                onClick={props?.handleOpen()}
              />
            )
          ) : <Page
            width={pdfWidth}
            key={`page_${props?.pre ? Number(props?.pre) : 1}`}
            pageNumber={props?.pre ? props?.pre > numPages ? numPages : Number(props?.pre) : 1}
            onClick={props?.handleOpen()}
          />
        }

      </Document>
    </div>
  )
}

interface pdfProps {
  pre: number,
  name: string,
  file: any;
  width?: number;
  handleOpen: any;
  local?: boolean;
}

export default React.memo(PrePDF);
