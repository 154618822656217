import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
  Checkbox,
  Input,
  InputAdornment
} from '@material-ui/core';
import Steper from '../../components/Steper';
import UpIcon from './images/up_arrow.png';
import DownIcon from './images/down_arrow.png';
import HowToGetUrlImg from './images/step3_img.png';
import {
  setStep2WebInfo,
  teamsBotRequestBeforeStep3,
  saveTeamsBotCallIdAndUrl,
  updateById,
  getContractsList,
  getCsvPdf
} from '../../api/readyBefore';
import { getConversationById } from '../../api/history';
import DataPicker from '../../components/DataPicker';
import { formatDateToYYMMDD, formatTimeToHHmm } from '../../utils/General';
import Message from '../../components/Message';
import { useSelector } from 'react-redux';
import { RootState } from '../../../src/store';
import UrlParse from './images/urlParse.png';
import UrlDel from './images/urlDel.png';
import NativeSelectWrap from '../../components/NativeSelectWrap';
import Radio from '../../components/RadioButtons';
import ConfirmDialog from '../../components/management/ConfirmDialog';
import EventBus from '../../components/EventBus';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    asCode: {
      borderRadius: '8px',
      boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)',
      maxWidth: '50%',
      padding: '20px',
      display: 'inline-block',
      marginTop: '20px',
      backgroundColor: '#fff'
    },
    asCodeWidth: {
      width: '50%'
    },
    asCodeTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '20px'
    },
    rowSelect: {
      cursor: 'pointer'
    },
    rowSelectSpan: {
      border: '2px solid #00a5d9',
      padding: '1px 10px',
      backgroundColor: '#00a5d9',
      color: '#fff'
    },
    rowSelected: {
      border: '2px solid #00a5d9',
      backgroundColor: '#fff',
      color: '#00a5d9'
    },
    selectStyle: {
      width: '100%',
      border: '1px solid #ccc',
      borderRadius: '5px'
    },
    detail: {
      borderRadius: '8px',
      boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)',
      padding: '15px 35px',
      marginTop: '20px',
      backgroundColor: '#fafafa'
    },
    Aname: {
      marginTop: '5px'
    },
    stepBtnBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: '5px'
    },
    detailInfo: {
      marginTop: '25px'
    },
    dateField: {
      border: '1px solid #ccc',
      height: '35px',
      width: '100%'
      // fontSize: '16px'
    },
    detailGMargin: {
      marginTop: '0px'
    },
    textFieldDate: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      width: 200
    },
    detailSpanDate: {
      border: '1px solid #ccc',
      borderRadius: '5px',
      width: '100%',
      // height: '60px',
      marginLeft: '0',
      marginTop: '5px',
      fontSize: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer'
    },
    detailSpanTime: {
      border: '2px solid #00a5d9',
      borderRadius: '2px',
      width: '130px',
      height: '60px',
      marginLeft: '0',
      marginTop: '5px',
      fontSize: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer'
    },
    detailSpan1: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer'
    },
    imData: {
      width: '100%',
      border: 0,
      height: '100%',
      fontSize: '20px',
      '&:focus': {
        outline: 'none'
      }
    },
    activeStepShow: {
      display: 'none'
    },
    activeStep3: {
      display: 'none'
    },
    step2Box: {
      borderRadius: '8px',
      boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)',
      padding: '15px 25px',
      marginTop: '20px'
    },
    step2URL: {
      // border:'1px solid #d9d9d9',
      marginTop: '30px'
    },
    step2URLabel: {
      border: '1px solid #d9d9d9',
      borderRight: '0px',
      backgroundColor: '#f2f2f2',
      padding: '11px 24px',
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: '0!important'
    },
    step2URLInput: {
      width: '347px',
      fontSize: '14px',
      border: '1px solid #d9d9d9',
      outline: 'none',
      padding: '9.5px 16px 4px 16px',
      borderLeft: '0px',
      '&.Mui-disabled': {
        color: 'var(--granite)'
      }
    },
    step2Msg: {},
    step2MsgFont: {
      fontSize: '14px!important'
    },
    getUrlMethodBox: {
      borderRadius: '5px',
      width: '100%',
      marginTop: '15px'
    },
    getUrlMethodBtn: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      borderRadius: '5px',
      border: '1px solid #d9d9d9',
      padding: '8px 16px 8px 24px',
      background: '#fbfbfb',
      alignItems: 'center'
    },
    methodBtnDIcon: {
      background: `url(${DownIcon}) no-repeat center center`,
      width: '12px',
      height: '8px',
      cursor: 'pointer',
      backgroundSize: 'contain'
    },
    methodBtnUIcon: {
      background: `url(${UpIcon}) no-repeat center center`,
      width: '12px',
      height: '8px',
      cursor: 'pointer',
      backgroundSize: 'contain'
    },
    GetUrlContent: {
      marginTop: '-2px',
      // display: 'none',
      background: '#FBFBFB',
      border: '0.678715px solid #D6E4EC',
      padding: '16px 24px'
    },
    GetUrlContentUl: {
      listStyle: 'none',
      paddingLeft: '0px'
    },
    GetUrlContentLi: {
      padding: '10px 5px',
      textAlign: 'left',
      color: '#333',
      fontSize: '12px',
      lineHeight: 1.5
    },
    GetUrlContentLiSpan: {
      paddingRight: `.25rem!important`
    },
    roleSpanLabel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 700
    },
    roleSpanLabel1: {
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 700
    },
    personCard: {
      width: '100%',
      background: '#fff',
      border: '1px solid #d9d9d9',
      boxSizing: 'border-box',
      boxShadow: '0 4px 10px rgb(0 0 0 / 15%)',
      borderRadius: '6px',
      display: 'flex',
      // padding: '8px 16px',
      minHeight: '92px',
      height: 'auto',
      fontFamily: 'Roboto',
      // marginLeft: "5px",
      // marginTop: '5px',
      cursor: 'pointer'
    },
    personCardImg: {
      marginLeft: '10px',
      marginRight: '15px',
      width: '80px',
      height: '90px'
    },
    personCardInfo: {
      padding: '12px'
    },
    personCardActive: {
      border: '1px solid #00a5d9'
    },
    inputStyle: {
      width: '100%',
      border: '1px solid #ccc',
      borderRadius: '5px',
      paddingLeft: '14px'
    },
    disableBtn: {
      border: '0px',
      backgroundColor: '#ccc',
      color: '#fff',
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: '#ccc',
        color: '#fff',
        cursor: 'not-allowed'
      }
    },
    voiceLink: {
      alignItems: 'center',
      color: '#40ADDC',
      cursor: 'pointer'
    },
    voiceLinkLoading: {
      alignItems: 'center',
      color: '#40ADDC',
      cursor: 'not-allowed'
    }
  })
);

const PrepareStart = (props) => {
  const [clipboardPermisson, setClipboardPermisson] = useState(true);
  navigator?.permissions?.query({ name: 'clipboard-read' }).then(function (result) {
    if (result.state === 'denied') {
      console.log('You clipboard read permission was denied.');
      setClipboardPermisson(false);
    }
  });
  const curUserInfo = useSelector((state: RootState) => state.user.userInfo);

  // role for globel
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  // get state from new
  const step = location.state ? location.state['step'] : 0;
  const conversationId = location.state ? location.state['conversationId'] : '';
  const teamsUrl = location.state ? location.state['teamsUrl'] : '';
  const [activeStep, setActiveStep] = useState(step);
  const [curStep, setCurStep] = useState(step);
  // default web true
  const [purpose, setPurpose] = useState(true);
  // team url
  const [teamUrl, setTeamUrl] = useState(teamsUrl);
  const teamUrlCheck = new RegExp('https://teams\\.microsoft\\.com.*/[^/]+/[^/]+\\?context=*');
  const AgeList = [25, 35, 45, 55, 65, 75];
  //

  const [AFstate, setAFstate] = useState<any[]>([]);

  // default male
  const [sex, setSex] = useState('男性');
  //
  const [isGetUrlBoxOpen, setGetUrlBoxOpen] = useState(false);

  // start time
  const [startDate, setStartDate] = useState('');
  const [startDataR, setStartDataR] = useState('');
  const [startTime, setStartTime] = useState('');

  // customer name
  const [customerName, setCustomerName] = useState('');
  // customer age
  const [customerAge, setCustomerAge] = useState('');
  // customer age
  const [customerAgeRole, setCustomerAgeRole] = useState(25);
  // saved/conversation id
  const [saveId, setSaveId] = useState(conversationId);

  // message
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgType, setMsgType] = useState('err');
  const [msgText, setMsgText] = useState('');

  const [purposeRadio, setPurposeRadio] = useState('オンライン相談');

  const [isRecordAgree, setIsRecordAgree] = useState(false);

  const [pdfNameVcc, setPdfNameVcc] = useState('');

  const [saveOpen, setSaveOpen] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const today = new Date();
  const [dateNow, setDateNow] = useState(formatDateToYYMMDD(today));
  const [timeNow, setTimeNow] = useState(timeAddA(formatTimeToHHmm(today)));

  const getProduct = (products) => {
    return products
      .map((v: any, i) => {
        if (v.checked) {
          return v.value;
        }
      })
      .filter(Boolean);
  };
  useEffect(() => {
    getContractsList()
      .then(function (response) {
        if (response.status === 200) {
          let a = [] as any;
          response.data.map((item) => {
            item.phrases?.map((item1) => {
              a.push({ value: item1, checked: false });
            });
          });

          if (conversationId) {
            getConversationById({ id: conversationId })
              .then(function (response) {
                if (response.status === 200) {
                  const {
                    timeStart,
                    pattern,
                    implementDate,
                    customerName,
                    customerAge,
                    customerGender,
                    contractExist
                  } = response.data;
                  const t = genImplementDateTime(implementDate, timeStart);
                  setTimeNow(t[1]);
                  setDateNow(t[0]);
                  setPurpose(pattern === 'ロープレ' ? false : true);
                  setCustomerName(customerName);
                  if (pattern === 'ロープレ') {
                    setCustomerAgeRole(customerAge);
                  } else {
                    setCustomerAge(customerAge);
                  }
                  setSex(customerGender);
                  const newAFstate = a.map((item, i) => {
                    if (contractExist.indexOf(item?.value) > -1) {
                      item.checked = true;
                    }
                    return item;
                  });
                  setAFstate(newAFstate);
                } else {
                  console.log('code error');
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            setAFstate(a);
          }
        } else {
          messageSet('Code error:' + response);
        }
      })
      .catch(function (error) {
        // messageSet(error);
        messageSet('server error.');
      });

    getCsvPdf()
      .then(function (response) {
        if (response.status === 200) {
          setPdfNameVcc(response.data.fileSets[0].name);
          // setPdfFileOpen(true);
          // getPdf({ name: response.data.fileSets[0].name })
          //   .then(function (response) {
          //     if (response.status === 200) {
          //       blobViewInBroswer('', response.data);
          //       setLoadingPdf(false);
          //     }
          //   })
          //   .catch(function (response) {
          //     setLoadingPdf(false);
          //   });
        }
      })
      .catch(function (response) {
        if (response.status !== 200) {
          messageSet('Server error.');
        }
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const genImplementDateTime = (data, sTime) => {
    if (!data) {
      return '';
    }

    let s, e;
    if (sTime) {
      s = sTime;
    } else {
      s = '00:00';
    }

    // return formatUtcStringToLocalString(data + 'T' + s + ':00Z').split(' ');
    return [data, sTime];
  };

  function setTeamUrlInfo() {
    teamsBotRequestBeforeStep3({ id: saveId, teamUrl: teamUrl })
      .then(function (response) {
        if (response.status === 200) {
          const finishedUrl = response.data?.urls?.finished;
          saveTeamsBotCallIdAndUrl({
            id: saveId,
            teamUrl: teamUrl,
            callId: response.data?.callId
          })
            .then(function (response) {
              if (response.status === 200) {
                navigate('../customersupport:' + saveId, {
                  state: {
                    id: saveId,
                    callId: response.data?.id,
                    teamsCallId: response.data?.teamsCallId,
                    finishedUrl: finishedUrl
                  }
                });
                // jira https://xenera.atlassian.net/browse/AFV2N-69
                // window.open(teamUrl, '_blank');
              } else {
                messageSet('code error:' + response);
              }
            })
            .catch(function (error) {
              messageSet('server error.');
            });
        } else {
          messageSet('code error:' + response);
        }
      })
      .catch(function (error) {
        EventBus.dispatch('show_snack', { message: 'ボットはすでに会議に参加しています.', type: 'error' });
      });
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep + 1 >= 3 && saveId) {
        if (teamUrlCheck.test(teamUrl)) {
          setTeamUrlInfo();
        } else {
          EventBus.dispatch('show_snack', { message: 'TeamsURLを入力する必要があります.', type: 'error' });
          setCurStep(prevActiveStep);
          return prevActiveStep;
        }
      } else if (prevActiveStep + 1 === 2) {
        // save step2 values
        if (
          (purpose && customerName && customerAge && getProduct(AFstate).length > 0) ||
          (!purpose && getProduct(AFstate).length > 0)
        ) {
          if (saveId) {
            updateStepValues();
          } else {
            getStep2Values();
          }
        } else {
          if (purpose) {
            EventBus.dispatch('show_snack', {
              message: '実施日、実施時間、お客様氏名、年齢、既契約を入力する必要があります。',
              type: 'error'
            });
          } else {
            EventBus.dispatch('show_snack', {
              message: '実施日、年齢、既契約を入力する必要があります。',
              type: 'error'
            });
          }
          setCurStep(prevActiveStep);
          return prevActiveStep;
        }
      }
      setCurStep(prevActiveStep + 1);
      return prevActiveStep + 1 > 3 ? 3 : prevActiveStep + 1;
    });
  };

  const messageSet = (msg, type = 'error') => {
    setMsgType(type);
    setMsgText(msg);
    setMsgOpen(true);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      setCurStep(prevActiveStep - 1);
      return prevActiveStep - 1 > 0 ? prevActiveStep - 1 : 0;
    });
  };

  const handleAFListChange = (e, i) => {
    const c = e.target.checked;
    let tem = [...AFstate];
    tem[i].checked = c;
    setAFstate(tem);
  };

  const genCheckBoxList = (v, i) => {
    return (
      <Grid style={{ width: '220px' }}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={v.checked}
                onChange={(e) => {
                  handleAFListChange(e, i);
                }}
                name={v.value}
                color="primary"
              />
            }
            label={v.value}
          />
        </Grid>
      </Grid>
    );
  };

  const handlePurposeClick = () => {
    setPurpose(!purpose);
    if (!purpose) {
      setPurposeRadio('オンライン相談');
    } else {
      setPurposeRadio('ロープレ');
    }
  };

  const handleSexClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    setSex(v);
  };

  function timeAddA(timeNow) {
    const a = timeNow.split(':');
    let a0 = 0;
    if (Number(a[1]) === 0 && Number(a[2]) <= 59) {
      a0 = Number(a[0]);
    } else {
      a0 = Number(a[0]) + 1;
    }

    let a1 = '00'; //jira 137
    let hh = '';
    if (a0 < 10) {
      hh = '0' + a0.toString();
    } else {
      hh = a0.toString();
    }

    return hh + ':' + a1;
  }

  function getImplementDate() {
    const D = purpose ? startDate : startDataR;
    if (D && startTime) {
      // for edit
      return [formatDateToYYMMDD(D), startTime];
    } else if (D || startTime) {
      if (startTime) {
        return [dateNow, startTime.trim()];
      } else {
        return [formatDateToYYMMDD(D), timeNow];
      }
    } else {
      return [dateNow, timeNow];
    }
  }

  function getRweb() {
    const DT = getImplementDate();
    return {
      timeStart: DT[1],
      timeEnd: '',
      pattern: purpose ? 'オンライン相談' : 'ロープレ',
      implementDate: DT[0],
      customerName: customerName,
      customerAge: customerAge,
      customerGender: sex,
      status: '未承認',
      contractExist: getProduct(AFstate),
      teamsUrl: ''
    };
  }

  function getRrole() {
    // console.log("startData", startData)
    const DT = getImplementDate();
    return {
      pattern: purpose ? 'オンライン相談' : 'ロープレ',
      status: '未承認',
      timeStart: DT[1],
      timeEnd: '',
      implementDate: DT[0],
      customerName: '',
      customerAge: customerAgeRole,
      customerGender: sex,
      contractExist: getProduct(AFstate),
      teamsUrl: ''
    };
  }

  const getStep2Values = () => {
    const Rweb = getRweb();
    const Rrole = getRrole();
    const params = purpose ? Rweb : Rrole;
    setStep2WebInfo({ params: { ...params } })
      .then(function (response) {
        if (response.status === 200) {
          setSaveId(response.data?.id);
        }
      })
      .catch(function (response) {
        if (response.status !== 200) {
          messageSet('Server error.');
        }
      });
  };

  const updateStepValues = () => {
    const Rweb = getRweb();
    const Rrole = getRrole();
    const params = purpose ? Rweb : Rrole;
    updateById({ params: { ...params }, author: { id: saveId } })
      .then(function (response) {
        if (response.status === 200) {
          setSaveId(response.data?.id);
        }
      })
      .catch(function (response) {
        if (response.status !== 200) {
          messageSet('Server error');
        }
      });
  };

  // const [pdfValue, setPdfValue] = React.useState('');

  const onSave = () => {
    if (saveId) {
      updateStepValues();
    } else {
      getStep2Values();
    }
    navigate('../history');
  };

  const getStep2BtnDis = () => {
    return curStep === 1
      ? purpose
        ? customerName && customerAge && getProduct(AFstate).length > 0
          ? false
          : true
        : getProduct(AFstate).length > 0
        ? false
        : true
      : activeStep === 2
      ? isRecordAgree && teamUrl
        ? false
        : true
      : false;
  };

  return (
    <Box className="root-1">
      <Message open={msgOpen} closeFun={setMsgOpen} msg={msgText} type={msgType} />
      <Steper activeStep={activeStep} />
      <Box className={[classes.detailInfo, activeStep !== 0 ? classes.activeStepShow : ''].join(' ')}>
        <Typography style={{ display: 'inline-block', width: '100px', fontSize: '25px' }}>目的:</Typography>
        <Grid container item xs={10} spacing={1} style={{ display: 'inline-flex' }}>
          <RadioGroup row aria-label="purpose" name="purpose" value={purpose} onClick={handlePurposeClick}>
            <FormControlLabel
              key={'0'}
              value={true}
              control={<Radio color="default" />}
              label={<span className={`radio-left ${purpose ? 'fw-700' : ''}`}>オンライン相談</span>}
            />
            <FormControlLabel
              key={'1'}
              value={false}
              control={<Radio color="default" />}
              label={<span className={`radio-left ${!purpose ? 'fw-700' : ''}`}>ロープレ</span>}
            />
          </RadioGroup>
        </Grid>
      </Box>
      <Box className={classes.detail}>
        {curStep > 0 && (
          <Box className={classes.Aname}>
            <Typography>
              {curUserInfo['role'].indexOf('AF_') !== 0 && `アソシエイツ名：${curUserInfo['agentName']}`}
              {curUserInfo['role'].indexOf('AF_') !== 0 && (
                <span style={{ marginLeft: '15px', marginRight: '15px' }}>{'|'}</span>
              )}
              {`募集人名：${curUserInfo['role'].indexOf('AF_') === 0 ? curUserInfo['account'] : curUserInfo['name']}`}
            </Typography>
          </Box>
        )}
        <Box className={classes.stepBtnBox}>
          <Box>
            {curStep > 0 && (
              <Button variant="contained" onClick={handleBack}>
                戻る
              </Button>
            )}
            {activeStep === 2 && (
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={isRecordAgree} onClick={() => setIsRecordAgree(!isRecordAgree)} />
                }
                label="音声取得の同意"
                style={{ marginLeft: '15px' }}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: '15px' }}
              onClick={getStep2BtnDis() ? () => {} : handleNext}
              className={getStep2BtnDis() ? classes.disableBtn : ''}
            >
              {activeStep === 2 ? '録音開始' : '次へ'}
            </Button>
            {curStep === 1 && (
              <Button
                variant="contained"
                style={{ marginLeft: '15px' }}
                color="primary"
                onClick={() => {
                  if (curStep === 1) {
                    if (purpose) {
                      if (customerName && customerAge && getProduct(AFstate).length > 0) {
                        setSaveOpen(true);
                      } else {
                        EventBus.dispatch('show_snack', {
                          message: '実施日、実施時間、お客様氏名、年齢、既契約を入力する必要があります。',
                          type: 'error'
                        });
                      }
                    } else {
                      if (getProduct(AFstate).length > 0) {
                        setSaveOpen(true);
                      } else {
                        EventBus.dispatch('show_snack', {
                          message: '実施日、年齢、既契約を入力する必要があります。',
                          type: 'error'
                        });
                      }
                    }
                  }
                }}
              >
                一時保存
              </Button>
            )}
          </Box>
          {activeStep === 2 && (
            <a
              className={loadingPdf ? classes.voiceLinkLoading : classes.voiceLink}
              title={loadingPdf ? 'loading...' : ''}
              // onClick={handlehrefClick}
              target="_blank"
              href={process.env.REACT_APP_API_BASE_URL + `/fileset/preview/${pdfNameVcc}`}
            >
              お客様の音声取得PDF
            </a>
          )}
        </Box>
        <Box
          className={[
            classes.detailInfo,
            activeStep !== 1 ? classes.activeStepShow : !purpose ? classes.activeStepShow : ''
          ].join(' ')}
        >
          <Grid container spacing={1}>
            <Grid container item xs={12} spacing={2}>
              <Grid item container xs={2} className={classes.roleSpanLabel}>
                <Grid item xs={10}>
                  <span>目的</span>
                </Grid>
                <Grid item xs={2}>
                  <span>:</span>
                </Grid>
              </Grid>
              <Grid container item xs={10}>
                <Typography style={{ margin: '8px 0px 8px 0px' }} className="fw-700">
                  オンライン相談
                </Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2} className={classes.detailGMargin}></Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item container xs={2} className={classes.roleSpanLabel}>
                <Grid item xs={10}>
                  <span>実施日</span>
                </Grid>
                <Grid item xs={2}>
                  <span>:</span>
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={1}>
                <Grid item xs={3}>
                  <DataPicker type="date" style={classes.dateField} setDate={setStartDate} defaultValue={dateNow} />
                </Grid>
                <Grid container item xs={9}>
                  <Grid item xs style={{ alignItems: 'center', display: 'inline-flex' }}>
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontFamily: 'unset',
                        marginLeft: '15px',
                        marginRight: '10px',
                        whiteSpace: 'nowrap'
                      }}
                    >
                      実施時間:
                    </Typography>
                    <DataPicker type="time" style={classes.dateField} setDate={setStartTime} defaultValue={timeNow} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} className={classes.detailGMargin}>
              <Grid item container xs={2} className={classes.roleSpanLabel}>
                <Grid item xs={10}>
                  <span>お客様氏名</span>
                </Grid>
                <Grid item xs={2}>
                  <span>:</span>
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={1}>
                <Grid item xs={3}>
                  <Input
                    disableUnderline
                    className={classes.inputStyle}
                    value={customerName}
                    onChange={(v) => setCustomerName(v.target.value)}
                  />
                </Grid>
                <Typography style={{ alignItems: 'center', display: 'inline-flex' }}>様</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} className={classes.detailGMargin}>
              <Grid item container xs={2} className={classes.roleSpanLabel}>
                <Grid item xs={10}>
                  <span>年齢</span>
                </Grid>
                <Grid item xs={2}>
                  <span>:</span>
                </Grid>
              </Grid>
              <Grid container item xs={10} spacing={1}>
                <Grid item xs={3}>
                  <Input
                    disableUnderline
                    placeholder="0~100"
                    className={classes.inputStyle}
                    value={customerAge}
                    onChange={(v) => {
                      const cv = v.target.value;
                      if (cv.trim() === '') {
                        setCustomerAge('');
                      } else if (Number(cv) <= 0) {
                        setCustomerAge('0');
                      } else if (Number(cv) >= 100) {
                        setCustomerAge('100');
                      } else {
                        setCustomerAge(Number(cv).toString().replace(/\D/g, ''));
                      }
                    }}
                  />
                </Grid>
                <Typography style={{ alignItems: 'center', display: 'inline-flex' }}>歳</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item container xs={2} className={classes.roleSpanLabel}>
                <Grid item xs={10}>
                  <span>性別</span>
                </Grid>
                <Grid item xs={2}>
                  <span>:</span>
                </Grid>
              </Grid>
              <Grid container item xs={10}>
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender"
                  value={sex}
                  onChange={handleSexClick}
                  style={{ margin: '8px 0px 8px 12px' }}
                >
                  <FormControlLabel
                    key={'0'}
                    value={'男性'}
                    control={<Radio color="default" />}
                    label={<span className={`radio-left  ${sex === '男性' ? 'fw-700' : ''}`}>男性</span>}
                  />
                  <FormControlLabel
                    key={'1'}
                    value={'女性'}
                    control={<Radio color="default" />}
                    label={<span className={`radio-left  ${sex === '女性' ? 'fw-700' : ''}`}>女性</span>}
                  />
                </RadioGroup>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <Grid item container xs={2} className={classes.roleSpanLabel1}>
                <Grid item xs={10}>
                  <span>既契約</span>
                </Grid>
                <Grid item xs={2}>
                  <span>:</span>
                </Grid>
              </Grid>
              <Grid container item xs={10} style={{ border: '1px solid #ccc' }}>
                {AFstate?.map((v, i) => {
                  return genCheckBoxList(v, i);
                })}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box
          className={[
            classes.step2Box,
            activeStep !== 1 ? classes.activeStepShow : purpose ? classes.activeStepShow : ''
          ].join(' ')}
        >
          <Grid container item xs={12} spacing={2}>
            <Grid item container xs={2} className={classes.roleSpanLabel}>
              <Grid item xs={10}>
                <span>目的</span>
              </Grid>
              <Grid item xs={2}>
                <span>:</span>
              </Grid>
            </Grid>
            <Grid container item xs={10}>
              <Typography style={{ margin: '8px 0px 8px 0px' }} className="fw-700">
                ロープレ
              </Typography>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item container xs={2} className={classes.roleSpanLabel}>
              <Grid item xs={10}>
                <span>実施日</span>
              </Grid>
              <Grid item xs={2}>
                <span>:</span>
              </Grid>
            </Grid>
            <Grid container item xs={10} spacing={1}>
              <Grid item xs={3}>
                <DataPicker type="date" style={classes.dateField} setDate={setStartDataR} defaultValue={dateNow} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2} className={classes.detailGMargin}>
            <Grid item container xs={2} className={classes.roleSpanLabel}>
              <Grid item xs={10}>
                <span>年齢</span>
              </Grid>
              <Grid item xs={2}>
                <span>:</span>
              </Grid>
            </Grid>
            <Grid container item xs={10} spacing={1}>
              <Grid item xs={3}>
                <NativeSelectWrap
                  onChange={(v) => setCustomerAgeRole(v.target.value)}
                  className={classes.selectStyle}
                  default={customerAgeRole}
                  value={customerAgeRole}
                >
                  {AgeList?.map((v: any, i) => {
                    return (
                      <option value={v} key={i}>
                        {v}歳
                      </option>
                    );
                  })}
                </NativeSelectWrap>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item container xs={2} className={classes.roleSpanLabel}>
              <Grid item xs={10}>
                <span>性別</span>
              </Grid>
              <Grid item xs={2}>
                <span>:</span>
              </Grid>
            </Grid>
            <Grid container item xs={10}>
              <RadioGroup
                row
                aria-label="gender"
                name="gender"
                value={sex}
                onChange={handleSexClick}
                style={{ margin: '8px 0px 8px 12px' }}
              >
                <FormControlLabel
                  key={'0'}
                  value={'男性'}
                  control={<Radio color="default" />}
                  label={<span className={`radio-left ${sex === '男性' ? 'fw-700' : ''}`}>男性</span>}
                />
                <FormControlLabel
                  key={'1'}
                  value={'女性'}
                  control={<Radio color="default" />}
                  label={<span className={`radio-left ${sex === '女性' ? 'fw-700' : ''}`}>女性</span>}
                />
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item container xs={2} className={classes.roleSpanLabel1}>
              <Grid item xs={10}>
                <span>既契約</span>
              </Grid>
              <Grid item xs={2}>
                <span>:</span>
              </Grid>
            </Grid>
            <Grid container item xs={10} style={{ border: '1px solid #ccc' }}>
              {AFstate?.map((v, i) => {
                return genCheckBoxList(v, i);
              })}
            </Grid>
          </Grid>
        </Box>
        <Box className={[classes.step2Box, activeStep !== 2 ? classes.activeStepShow : ''].join(' ')}>
          <Typography style={{ fontSize: '18px', marginBottom: '3px' }} className="fw-700">
            Teamsの開始
          </Typography>
          <Box className={classes.step2Msg}>
            <Typography className={classes.step2MsgFont}>WEB面談の開始前</Typography>
            <Typography className={classes.step2MsgFont}>①以下の注意事項をお客様へご説明する</Typography>
            <Typography className={classes.step2MsgFont} style={{ marginLeft: '15px' }}>
              音声を取得することに懸念を示された場合は、音声を取得しない選択肢もあることをお伝えする
            </Typography>
            <Typography className={classes.step2MsgFont}>②お客様の同意を得る</Typography>
            <Typography className={classes.step2MsgFont}>
              ③「お客様に音声録音の同意を取りました」のボタンを押したら、初めてボット（音声を録音するためのボット）を参加させる
            </Typography>
            <Typography className={classes.step2MsgFont} style={{ marginLeft: '15px' }}>
              ※ボットをteamsに参加させることによって音声の録音が可能となる
            </Typography>
            <Typography className={classes.step2MsgFont} style={{ marginLeft: '15px' }}>
              ※ボタンの押下は履歴としてトレース可能
            </Typography>
          </Box>
          <Box className={classes.step2URL}>
            <label className={classes.step2URLabel}>Teams URL:</label>
            <Input
              className={classes.step2URLInput}
              value={teamUrl}
              disableUnderline
              placeholder="URLを貼り付けてください"
              onChange={(v) => setTeamUrl(v.target.value)}
              disabled
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{ cursor: 'pointer' }}
                  title={
                    !clipboardPermisson
                      ? 'Clipboard read permission was denied by your Browser, you can use CTRL+V parse your value.'
                      : ''
                  }
                >
                  <img
                    alt=''
                    src={teamUrl ? UrlDel : UrlParse}
                    onClick={() => {
                      if (teamUrl) {
                        setTeamUrl('');
                      } else {
                        navigator?.clipboard?.readText().then((text) => setTeamUrl(text));
                      }
                    }}
                  />
                </InputAdornment>
              }
            />
          </Box>
          <Box className={classes.getUrlMethodBox}>
            <button className={classes.getUrlMethodBtn} onClick={() => setGetUrlBoxOpen(!isGetUrlBoxOpen)}>
              <span>
                <span style={{ fontWeight: 700 }}>ヘルプ：</span>
                URLの取得方法
              </span>
              <span className={isGetUrlBoxOpen ? classes.methodBtnUIcon : classes.methodBtnDIcon} />
            </button>
            <Box className={[classes.GetUrlContent, isGetUrlBoxOpen ? '' : classes.activeStepShow].join(' ')}>
              <Grid style={{ borderRadius: '4px' }} container item xs={12}>
                <Grid
                  item
                  xs={4}
                  style={{ border: '1px solid #d9d9d9', backgroundColor: '#FFFFFF', padding: '16px 8px' }}
                >
                  <div>
                    <h5 style={{ textAlign: 'left', paddingLeft: '5px', fontSize: '14px' }}>[手順]</h5>
                    <ul className={classes.GetUrlContentUl}>
                      <li className={classes.GetUrlContentLi}>
                        <span className={classes.GetUrlContentLiSpan}>①</span>teamsカレンダーに移動。
                      </li>
                      <li className={classes.GetUrlContentLi}>
                        <span className={classes.GetUrlContentLiSpan}>②</span>
                        実施中のミーティングにカーソルを合わせ、右クリックし、「リンクをコピー」をクリックします。
                      </li>
                      <li className={classes.GetUrlContentLi}>
                        <span className={classes.GetUrlContentLiSpan}>③</span>
                        貼り付けアイコンをクリックして、コピーしたURLを貼り付けます。
                      </li>
                      <li className={classes.GetUrlContentLi}>
                        <span className={classes.GetUrlContentLiSpan}>④</span>
                        「録音開始」押下すると、Teamsボットがオンライン相談に参加します。
                      </li>
                    </ul>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={8}
                  style={{ border: '1px solid #d9d9d9', padding: '16px 24px', backgroundColor: '#FFFFFF' }}
                >
                  <img
                    src={HowToGetUrlImg}
                    style={{ margin: 'auto!important', display: 'block!important', maxWidth: '100%' }}
                    aria-hidden
                    alt="how to get url image"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* <PreviewPdf open={pdfFileOpen} setOpen={setPdfFileOpen} pdfValue={pdfHeaderForPreview(pdfValue)} /> */}
      {/* {pdfFileOpen && (
        <NewWindow
          title={'お客様の音声取得PDF'}
          center={'screen'}
          copyStyles={true}
          onUnload={() => {
            setPdfFileOpen(false);
          }}
          features={{ width: 900, height: 480, top: 100, left: 100 }}
        >
          <PrePDF pre={0} name="all" file={pdfHeaderForPreview(pdfValue)} handleOpen={() => { }}></PrePDF>
        </NewWindow>
      )} */}
      <ConfirmDialog
        title="入力内容を一時保存して、対応履歴画面へ遷移します。
        よろしいでしょうか？"
        open={saveOpen}
        setOpen={setSaveOpen}
        onSubmit={onSave}
      />
    </Box>
  );
};

export default PrepareStart;
