import { memo } from 'react';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Grid, Modal, Button, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import cancel from '../../images/cancel.png';

const YesButton = withStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '55px',
    fontWeight: 700,
    fontSize: '14px',
    borderRadius: '12px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    backgroundColor: '#00a5d9',
    marginBottom: '1rem',
    '&:hover': {
      backgroundColor: '#00a5d9'
    }
  }
}))(Button);

const NoButton = withStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '55px',
    fontWeight: 700,
    fontSize: '14px',
    borderRadius: '12px',
    textTransform: 'uppercase',
    color: '#00a5d9',
    backgroundColor: '#e9faff',
    border: '1px solid #00a5d9'
  }
}))(Button);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    // minWidth: '800px',
    flexDirection: 'column'
  },
  paper: {
    width: '30%',
    minWidth: '400px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '3px',
    padding: '15px'
  },
  textRight: {
    textAlign: 'right',
    '&.MuiDialogTitle-root': {
      padding: 'unset'
    }
  },
  cancel: {
    cursor: 'pointer',
    left: '95%',
    top: '-9%'
  }
}));

const ConfirmDialog = (props) => {
  const { open, setOpen, title, onSubmit, saveTitle } = props;
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div className={classes.paper}>
      <DialogTitle id="confirm-dialog" className={classes.textRight}>
        <img
          src={cancel}
          alt="Cancel"
          className={classes.cancel}
          onClick={() => {
            setOpen(false);
          }}
        />
      </DialogTitle>
      <DialogContent className="fw-700" style={{ padding: '8px 24px' }}>
        {title}
      </DialogContent>
      <DialogActions>
        <Grid xs={12}>
          <YesButton onClick={onSubmit}>{saveTitle ? saveTitle : 'はい'}</YesButton>
          <NoButton onClick={() => setOpen(false)}>キャンセル</NoButton>
        </Grid>
      </DialogActions>
    </div>
  );
  return (
    <div>
      <Modal className={classes.modal} open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
};

export default memo(ConfirmDialog);
