import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      width: '100%',
      height: '100%',
      padding: '1rem',
      borderRadius: '4px',
      backgroundColor: '#FFFFFFFF',
      boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)'
    },
    basic: {
      width: '100%',
      boxShadow: 'none',
      background: 'none',
      borderRadius: 'none'
    },
    title: {
      height: '40px',
      fontWeight: 700,
      fontSize: '14px',
      textAlign: 'left',
      lineHeight: '40px',
      marginBottom: '.5rem'
    }
  });
});

const withWrap =
  <P extends object>(props?: WithWrapProps) =>
  (Component: React.ComponentType<P>): React.FunctionComponent<P> => {
    return (wrappedComponentProps: P): JSX.Element => {
      const classes = useStyles();
      return (
        <>
          <Paper className={ props?.styled ? classes.root : classes.basic }>
            { props?.title && <Box className={ classes.title }>{ props.title }</Box> }
            <Component { ...wrappedComponentProps } />
          </Paper>
        </>
      );
    };
  };

interface WithWrapProps {
  title?: string;
  styled?: boolean;
}

export default withWrap;
