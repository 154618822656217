import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router, } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import store from './store';
import './index.css'

ReactDOM.render(
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.querySelector('#root')
);
