import React, { ChangeEvent, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import NativeSelectWrap from '../../components/NativeSelectWrap';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignItems: 'center',
      width: '100%',
      // backgroundColor: '#fafafa',
      padding: '15px 30px',
      margin: '0px',
      marginBottom: '20px'
    },
    asCode: {
      borderRadius: '8px',
      boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)',
      maxWidth: '50%',
      padding: '10px 15px',
      display: 'inline-block',
      marginTop: '20px',
      backgroundColor: '#fff'
    },
    asCodeWidth: {
      width: '50%'
    },
    asCodeTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '10px'
    },
    rowSelect: {
      backgroundColor: '#00a5d9',
      padding: '2px',
      cursor: 'pointer'
    },
    rowSelectSpan: {
      padding: '1px 10px'
    },
    rowSelected: {
      backgroundColor: '#fff'
    },
    selectStyle: {
      width: '90%',
      border: '1px solid #ccc',
      borderRadius: '5px'
    },
    detailGMargin: {
      marginTop: '0px'
    },
    detailInfoLabel: {
      fontSize: '20px',
      fontWeight: 800,
      backgroundColor: '#00a5d9',
      height: '100%',
      marginLeft: '2px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'justify'
    },
    detailSpan: {
      border: '3px solid #00a5d9',
      width: '100%',
      height: '60px',

      fontSize: '20px',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer'
    },
    tableLabel: {
      fontSize: '20px',
      fontWeight: 800,
      backgroundColor: '#00a5d9',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'justify',
      wordWrap: 'break-word',
      wordBreak: 'normal',
      height: '40px'
    },
    tableCell: {
      padding: '4px',
      borderBottom: '0px'
    },
    tableCellActive: {
      backgroundColor: '#b4eafb'
    },
    tableCellR1: {
      height: '60px'
    },
    tableCellR2: {
      height: '128px'
    },
    tableCellR4: {
      height: '264px'
    },
    tableCellR6: {
      height: '452px'
    }
  })
);

const ContractStatus: React.FC = (): JSX.Element => {
  const userName = ['XXXX', 'CCCCC'];
  const Slist = ['世帯単価', '51,230円'];
  const threeNotIn = ['-', '××.×％', '××.×％', '××.×％'];
  const productPList = ['単品', '85.0％', '2商品', '12.0％', '3商品以上', '3.0％'];
  const agencyCode = [
    { id: 2610441, name: '2610441/アイビージェイ' },
    { id: 1905054, name: '1905054/トータルライフサービス' },
    { id: 1400753, name: '1400753/井田ライフ保険' },
    { id: 9011910, name: '9011910/テストアソシエイツ１' }
  ];
  const toCodeList = [
    { id: 2610441, name: '024/コンサルティング営業部第一課' },
    { id: 1905054, name: '025/コンサルティング営業部第二課' },
    { id: 1400753, name: '026/コンサルティング営業部第三課' },
    { id: 9011910, name: '027/コンサルティング営業部第④課' }
  ];

  const classes = useStyles();
  const [toCode, setToCode] = useState('');
  const [, setAname] = useState('アソシエイツ名： ');
  const [sevenCheck, setSevenCheck] = useState(true);

  const getAgencyNameById = (id): string => {
    let tepName = '';
    agencyCode.forEach((v: { id: number; name: string }) => {
      if (v.id === id) {
        tepName = v.name;
      }
    });
    return tepName.split('/')[1] ? tepName.split('/')[1] : '';
  };

  const handleASCodeChange = (t) => {
    setToCode('');
    const cv = t.target.value;
    const agencyName = 'アソシエイツ名： ' + getAgencyNameById(cv);
    if (sevenCheck) {
      setAname(agencyName);
    }
    toCodeList.forEach((v: { id: number; name: string }) => {
      if (v.id === cv) {
        setToCode(v.name);
      }
    });
  };

  const handleRowsClick = (param) => {
    switch (param) {
      case 's':
        if (!sevenCheck) {
          setSevenCheck(true);
        }
        break;
      case 't':
        if (sevenCheck) {
          setSevenCheck(false);
        }
        break;
      default:
        setSevenCheck(true);
        break;
    }
  };

  const handleUserChange = (t) => {
    const cv = t.target.value;
    if (cv) {
      // setUname(cv)
    }
  };

  const genSpan = (t, i) => {
    return (
      <Grid key={uuid()} item xs={2}>
        {i % 2 === 0 ? (
          <Typography className={classes.detailInfoLabel}>{t}</Typography>
        ) : (
          <span className={classes.detailSpan}>{t}</span>
        )}
      </Grid>
    );
  };

  const genTableSpan = (t, f, h = 1, c = '') => {
    // t -- text, f -- label, h -- rows, c -- background #b4eafb
    return (
      <Box
        key={uuid()}
        className={[
          f === 'label' ? classes.tableLabel : classes.detailSpan,
          classes[`tableCellR${h}`],
          c ? classes.tableCellActive : ''
        ].join(' ')}
      >
        {t}
      </Box>
    );
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Box className={[classes.asCode, !sevenCheck ? classes.asCodeWidth : ''].join(' ')}>
          <Box className={classes.asCodeTitle}>
            <Typography>AS コード</Typography>
            <Typography className={classes.rowSelect}>
              <span
                className={[!sevenCheck ? classes.rowSelected : '', classes.rowSelectSpan].join(' ')}
                onClick={() => handleRowsClick('s')}
              >
                7桁
              </span>
              <span
                className={[sevenCheck ? classes.rowSelected : '', classes.rowSelectSpan].join(' ')}
                onClick={() => handleRowsClick('t')}
              >
                10桁
              </span>
            </Typography>
          </Box>
          <Grid container item xs={12} spacing={2}>
            <Grid style={{ display: 'inline-block' }} item xs={sevenCheck ? 12 : 6}>
              <NativeSelectWrap
                onChange={(v) => handleASCodeChange(v)}
                className={classes.selectStyle}
                disableUnderline
              >
                <option value="">代理店コード</option>
                {agencyCode.map((v: { id: number; name: string }, index: number) => {
                  return (
                    <option key={v.name + index} value={v.id}>
                      {v.name}
                    </option>
                  );
                })}
              </NativeSelectWrap>
            </Grid>
            {!sevenCheck && (
              <Grid style={{ display: 'inline-block' }} item xs={6}>
                <NativeSelectWrap
                  className={classes.selectStyle}
                  // onChange={(v) => handleToCodeChange(v)}
                  disableUnderline
                >
                  <option value="">出先コード</option>
                  {toCode ? <option>{toCode}</option> : ''}
                </NativeSelectWrap>
              </Grid>
            )}
          </Grid>
        </Box>

        <Box className={classes.asCode} style={{ marginLeft: '10px' }}>
          <Box className={classes.asCodeTitle} style={{ padding: '2px' }}>
            <Typography>募集人名</Typography>
            <Typography />
          </Box>
          <NativeSelectWrap
            disableUnderline
            className={classes.selectStyle}
            onChange={(v: ChangeEvent<HTMLSelectElement>) => handleUserChange(v)}
          >
            <option value="">募集人を選択してください</option>
            {userName.map((v: string, i: number) => {
              return (
                <option key={v + i} value={v}>
                  {v}
                </option>
              );
            })}
          </NativeSelectWrap>
        </Box>
      </Box>

      <Grid container item xs={12} spacing={2} style={{ marginTop: '10px' }}>
        {Slist.map((v, i) => {
          return genSpan(v, i);
        })}
      </Grid>

      <Grid container item xs={12} spacing={2}>
        {productPList.map((v, i) => {
          return genSpan(v, i);
        })}
      </Grid>

      <TableContainer style={{ marginTop: '20px', minWidth: '720px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell rowSpan={2} colSpan={2} className={classes.tableCell} style={{ width: '32%' }} />
              <TableCell rowSpan={2} className={classes.tableCell} style={{ width: '16%' }}>
                {genTableSpan(`第一分野未加入`, 'label', 2)}
              </TableCell>
              <TableCell colSpan={2} className={classes.tableCell} style={{ width: '32%' }}>
                {genTableSpan('1種類', 'label')}
              </TableCell>
              <TableCell className={classes.tableCell} style={{ width: '16%' }}>
                {genTableSpan('2種類', 'label')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{genTableSpan('死後整理のみ', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('遺族保障のみ', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('遺族保障+死後整理', 'span', 1, '1')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} className={classes.tableCell}>
                {genTableSpan('第三分野未加入', 'label')}
              </TableCell>
              {threeNotIn.map((v: string, i: number) => {
                return (
                  <TableCell key={v + i} className={classes.tableCell}>
                    {genTableSpan(v, 'span')}
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell rowSpan={4} className={classes.tableCell}>
                {genTableSpan('1種類', 'label', 4)}
              </TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('がんのみ', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{genTableSpan('医療のみ', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{genTableSpan('介護のみ', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{genTableSpan('給与のみ', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={6} className={classes.tableCell}>
                {genTableSpan('2種類', 'label', 6)}
              </TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('がん＋医療', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{genTableSpan('がん＋介護', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{genTableSpan('がん＋給与', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{genTableSpan('医療＋介護', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{genTableSpan('医療＋給与', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{genTableSpan('介護＋給与', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={4} className={classes.tableCell}>
                {genTableSpan('3種類', 'label', 4)}
              </TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('がん＋医療＋介護', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{genTableSpan('がん＋医療＋給与', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{genTableSpan('医療＋給与＋介護', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tableCell}>{genTableSpan('がん＋介護＋給与', 'span', 1, '1')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={1} className={classes.tableCell}>
                {genTableSpan('4種類', 'label', 1)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {genTableSpan('がん＋医療＋介護＋給与', 'span', 1, '1')}
              </TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
              <TableCell className={classes.tableCell}>{genTableSpan('××.×％', 'span')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ContractStatus;
