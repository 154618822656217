import React, { memo, useCallback, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { TextField, Box, InputLabel, Select, MenuItem, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useFormik } from 'formik';
import Radio from '../../../../components/RadioButtons';

export interface KeyWordsChangeSet {
  words: string[];
  category: string,
  sortOrder: string,
  label: string,
  condition: string,
}

interface KeywordsFormProps {
  onSave: (changeSet: KeyWordsChangeSet) => void;
  status: 'Default' | 'Processing' | 'Complete' | 'Error';
  onClose: () => void;
  initial: {
    word: any[],
    category: string,
    label: string,
    condition: string,
    sortOrder: string,
  };
}

interface AddWordFormValues {
  word: string;
  category: string,
  label: string,
  condition: string,
  sortOrder: string,
}

const useStyles = makeStyles((theme) => ({
  addWordForm: {
    marginTop: theme.spacing(4),
    display: 'flex',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  addWordButton: {
    paddingLeft: theme.spacing(3),
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'top'
  },
  input: {
    marginBottom: theme.spacing(2)
  },
  role: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  wordsBox: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    // padding: '10px'
    padding: `${theme.spacing(2)}px 5px`
  },
  fieldset: {
    borderColor: theme.palette.divider,
    borderBlockEndStyle: "solid",
    padding: `${theme.spacing(2)}px 5px`
  },
  roleSpanLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // fontWeight: 700
  },
}));

function KeywordsForm({ status, onSave, onClose, initial }: KeywordsFormProps) {
  const [words, setWords] = useState(initial?.word);
  const [category, setCategory] = useState(initial?.category);
  const [label, setLabel] = useState(initial?.label);
  const [sortOrder, setSortOrder] = useState(initial?.sortOrder);
  const [sortOrderCheck, setSortOrderCheck] = useState('');
  const [condition, setCondition] = useState(initial?.condition);
  const classes = useStyles();

  const categoryList = [
    {
      value: 'content',
      text: '会話内容'
    },
    {
      value: 'process',
      text: '提案プロセス'
    },
    {
      value: 'domain',
      text: '提案領域'
    },
    {
      value: 'contract',
      text: '既契約'
    },
    {
      value: 'questionNum',
      text: '質問回数'
    },
    {
      value: 'cushionWords',
      text: 'クッション言葉'
    },
    {
      value: 'habit',
      text: '口グセ'
    },
    {
      value: 'NG',
      text: 'NGワード'
    },
    {
      value: 'resonance',
      text: '共感回数'
    },
  ]

  const contractList = [
    { value: '新がん', checked: false },
    { value: 'Sがん', checked: false },
    { value: '21がん', checked: false },
    { value: 'フォルテ', checked: false },
    { value: 'Days', checked: false },
    { value: 'EVER', checked: false },
    { value: '新EVER', checked: false },
    { value: 'ちゃんと応える EVER', checked: false },
    { value: 'EVER Prime', checked: false },
    { value: '給与', checked: false },
    { value: '介護', checked: false },
    { value: '死亡', checked: false },
    { value: '既往症', checked: false },
    { value: '学資・年金', checked: false },
    { value: 'その他', checked: false }
  ]

  const addWordForm = useFormik<AddWordFormValues>({
    initialValues: {
      category: category,
      label: label,
      word: '',
      sortOrder: sortOrder,
      condition: condition,
    },
    validate(values) {
      if (words?.includes(values?.word.trim())) {
        return { word: 'キーワードが既に存在しています.' };
      } else if (!values?.word.trim()) {
        return { word: 'キーワードを入力の上、"追加"ボタンを押してください.' };
      } else if (values?.word?.trim().includes('\t')) {
        return { word: 'キーワードを入力の上、"追加"ボタンを押してください.' };
      } else {
        return {};
      }

    },
    onSubmit(values, helpers) {
      setWords([...words, values?.word.trim()]);
      helpers.resetForm();
    }
  });

  const handleSave = useCallback(() => {
    if (sortOrder) {
      onSave({ words, category, sortOrder, label, condition });
    } else {
      setSortOrderCheck('0より大きい数字を入力してください.')
    }
  }, [words, category, sortOrder, label, condition]);// eslint-disable-line react-hooks/exhaustive-deps

  const handleCateChange = (params) => {
    if (params === 'contract') {
      setLabel('新がん')
    } else {
      setLabel('')
    }
    setCategory(params)
  }
  const handleLabelChange = (params) => {
    setLabel(params)
  }
  const handleSortChange = (params) => {
    const reg = /^[1-9]\d*$/;
    const p = params.replace(/^0/g, "");
    if (p.trim() === '') {
      setSortOrder('')
    } else {
      if (reg.test(p)) {
        setSortOrder(p)
      } else {
        setSortOrder(p.replace(/\D/, ''))
      }
      setSortOrderCheck('')
    }
  }
  const handleCom = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    setCondition(v);
  };
  return (
    <>
      <DialogContent dividers>
        <Box className={classes.role}>
          <InputLabel id={'category '}>{'カテゴリ'}</InputLabel>
          <Select
            labelId={'category '}
            name={'category '}
            value={category}
            onChange={(v) => { handleCateChange(v.target.value) }}
            fullWidth
            variant="outlined"
          >
            {categoryList?.map((v: any, i) => {
              return <MenuItem value={v?.text} key={i}>{v?.text}</MenuItem>
            })}
          </Select>
        </Box>

        {category === 'contract' ? <>
          <Box className={classes.role}>
            <InputLabel id={'contract '}>{'契約オプション'}</InputLabel>
            <Select
              labelId={'contract '}
              name={'contract '}
              value={label}
              onChange={(v) => { handleLabelChange(v.target.value) }}
              fullWidth
              variant="outlined"
            >
              {contractList?.map((v: any, i) => {
                return <MenuItem value={v?.value} key={i}>{v?.value}</MenuItem>
              })}
            </Select>
          </Box>
          <TextField
            // autoFocus={true}
            fullWidth
            variant="outlined"
            label={'並び順'}
            placeholder={'並び順'}
            // type="number"
            name={'sortOrder'}
            value={sortOrder}
            onChange={(v) => { handleSortChange(v.target.value) }}
            className={classes.input}
            error={sortOrderCheck ? true : false}
            helperText={sortOrderCheck}
          />
        </>
          :
          <>
            <TextField
              // autoFocus={true}
              fullWidth
              variant="outlined"
              label={'カテゴリ名称'}
              placeholder={'カテゴリ名称'}
              name={'label'}
              value={label}
              onChange={(v) => { handleLabelChange(v.target.value) }}
              className={classes.input}
            />
            <TextField
              // autoFocus={true}
              fullWidth
              variant="outlined"
              label={'並び順'}
              placeholder={'並び順'}
              // type="number"
              name={'sortOrder'}
              value={sortOrder}
              onChange={(v) => { handleSortChange(v.target.value) }}
              className={classes.input}
              error={sortOrderCheck ? true : false}
              helperText={sortOrderCheck}
            />
            <fieldset className={classes.wordsBox} >
              <legend>
                <Typography >{'キーワード一覧'}</Typography>
              </legend>
              <List dense >

                {words?.map((word) => {
                  const handleRemove = () => setWords(words.filter((w) => w !== word));
                  return (
                    <ListItem key={word}>
                      <ListItemText>{word}</ListItemText>
                      <ListItemSecondaryAction>
                        <IconButton onClick={handleRemove} edge="end" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
                <form onSubmit={addWordForm.handleSubmit} className={classes.addWordForm}>
                  <TextField
                    autoFocus
                    fullWidth
                    placeholder="キーワード"
                    name="word"
                    value={addWordForm.values.word}
                    onBlur={addWordForm.handleBlur}
                    onChange={addWordForm.handleChange}
                    error={!!addWordForm.errors.word}
                    helperText={addWordForm.errors.word}
                  />
                  <div className={classes.addWordButton}>
                    <div>
                      <Button type="submit" color="secondary" variant="contained">
                        追加
                      </Button>
                    </div>
                  </div>
                </form>
              </List>
              <Grid container item xs={12} spacing={2} style={{ marginLeft: '10px' }}>
                <Grid item container xs={2} className={classes.roleSpanLabel}>
                  <Grid item xs={10}>
                    <span>条件</span>
                  </Grid>
                  <Grid item xs={2}>
                    <span>:</span>
                  </Grid>
                </Grid>
                <Grid container item xs={10}>
                  <RadioGroup
                    row
                    name={"condition"}
                    value={condition}
                    style={{ margin: '8px 0px 8px 12px' }}
                    onChange={handleCom}
                  >
                    <FormControlLabel
                      key={'0'}
                      value={'and'}
                      control={<Radio color="default" />}
                      label={<span className={`radio-left ${condition === 'and' ? 'fw-700' : ''}`}>AND</span>}
                    />
                    <FormControlLabel
                      key={'1'}
                      value={'or'}
                      control={<Radio color="default" />}
                      label={<span className={`radio-left ${condition === 'or' ? 'fw-700' : ''}`}>OR</span>}
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
            </fieldset>
          </>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={handleSave} color="primary">
          保存
        </Button>
      </DialogActions>
    </>
  );
}

export default memo(KeywordsForm);
