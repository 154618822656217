import React, { memo, useCallback, useMemo, useEffect } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { ProductDataChangeSet, ProductDataReference } from './ProductData';
import Button from '@material-ui/core/Button';
import { Form, Formik, FormikProps } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ProductDataInput from './ProductDataInput';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import ProductDataReferencesSubform from './ProductDataReferencesSubform';
import { getFileSet } from '../../../../api/recomandApi';


const STRING_VALUE_KEYS: (keyof Omit<ProductDataChangeSet, 'references'>)[] = [
  'product',
  'need',
  'summary',
  'description',
  'isShow'
];

const useStyles = makeStyles((theme) => ({
  addProductDataForm: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column'
  },
  fieldset: {
    borderColor: theme.palette.divider,
    borderBlockEndStyle: 'solid',
    padding: `${theme.spacing(2)}px 5px`
  }
}));

const ProductDataForm = (props) => {
  const { onSave, onClose, initialValues } = props;
  const classes = useStyles();
  const disabled = false;
  const [fileSetList, setFileSetList] = React.useState<any[]>([]);

  const FieldData = [
    {
      fieldLabel: 'お客様のニード',
      fieldName: 'need',
      multiline: false,
      autoFocus: false
    },
    {
      fieldLabel: '表示内容',
      fieldName: 'summary',
      multiline: true,
      autoFocus: false
    },
    {
      fieldLabel: '説明文',
      fieldName: 'description',
      multiline: true,
      autoFocus: false
    },
    {
      fieldLabel: '商品',
      fieldName: 'product',
      autoFocus: true
    }
  ];
  const validate = useCallback((values: ProductDataChangeSet) => {
    for (const key of STRING_VALUE_KEYS) {
      if (key !== 'isShow' && values[key]?.includes('\t')) {
        return { [key]: 'No tab characters.' };
      }
    }

    const references: Array<ProductDataReference | undefined> = [];
    values.references.forEach((reference, index) => {
      const descError: any = { description: '', url: '', numPages: '' };
      if (!reference.numPages) {
        descError.numPages = '入力エラー(必須入力)';
        references[index] = descError;
      }
      if(reference.numPages  && reference.numPages < 0){
        descError.numPages = 'ページ数は正でなければなりません';
        references[index] = descError;
      }
      if (!reference.description) {
        descError.description = '入力エラー(必須入力)';
        references[index] = descError;
      }
      if (!reference.url) {
        descError.url = '入力エラー(必須入力)';
        references[index] = descError;
      }
    });
    if (references.filter((d) => d).length > 0) {
      return { references: references };
    }
    return {};
  }, []);

  const handleSubmit = useCallback(
    (values, helpers) => {
      onSave(values);
      helpers.resetForm();
    },
    [onSave]
  );

  const formInitialValues = useMemo(
    () => ({
      product: initialValues?.product || '',
      need: initialValues?.need || '',
      summary: initialValues?.summary || '',
      description: initialValues?.description || '',
      references: initialValues?.references || [],
      isShow: initialValues?.isShow || false,
    }),
    [initialValues]
  );
  useEffect(() => {
    getFileSet()
      .then(function (response) {
        if (response.status === 200) {
          setFileSetList(response.data);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validate={validate}
      className={classes.addProductDataForm}
    >
      {(form: FormikProps<ProductDataChangeSet>) => (
        <>
          <DialogContent>
            <Form>
              {FieldData.map((field: any) => (
                <ProductDataInput
                  fieldLabel={field.fieldLabel}
                  fieldName={field.fieldName}
                  form={form}
                  disabled={disabled}
                  multiline={field.multiline}
                  autoFocus={field.autoFocus}
                />
              ))}
              <fieldset className={classes.fieldset}>
                <legend>
                  <Typography variant="h6">参考フリップ</Typography>
                </legend>
                <ProductDataReferencesSubform disabled={disabled} fileSets={fileSetList} />
              </fieldset>
              <FormControlLabel
                name="isShow"
                control={<Checkbox color="primary" checked={form.values.isShow} onChange={form.handleChange} />}
                label={'初回起動時に画面表示'}
              // inputRef={uref}
              />
            </Form>
          </DialogContent>

          <DialogActions>
            <Button disabled={disabled} onClick={onClose}>
              キャンセル
            </Button>
            <Button onClick={form.submitForm} color="primary">
              保存
            </Button>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

export default memo(ProductDataForm);
