import service from './service';

export function getConversionList(parameter) {
  const url = parameter.lastTime
    ? `/conversations/${parameter.id}/calls/lines/latest?lasttime=${parameter.lastTime}`
    : `/conversations/${parameter.id}/calls/lines/latest`;
  return service({
    url: url,
    method: 'get'
  });
}

export function getProductExtData(parameter) {
  const url = `/products/data/extract`;
  return service({
    url: url,
    method: 'post',
    data: {
      state: null,
      product: parameter.product,
      text: parameter.text
    }
  });
}

export function productFeedback(parameter) {
  const url = `/products/data/${parameter.id}/feedbacks`;
  return service({
    url: url,
    method: 'post',
    data: {
      context: '次回のアポイントを取りましょう。',
      good: true
    }
  });
}

export function getPdf(parameter) {
  return service({
    url: `/fileset/preview/${parameter.name}`,
    method: 'get',
    requestBase: 'pdf',
    headers: {
      Accept: 'application/pdf',
      // 'Content-Type': 'application/json'
    }
  });
  // return axios.get(
  //   `/fileset/preview/${parameter.name}`,
  //   {
  //     baseURL: process.env.REACT_APP_API_BASE_URL,
  //     timeout: 8000,
  //     headers: {
  //       Authorization: 'Bearer ' + parameter.token,
  //       ...addAanetHeader(),
  //       Accept: 'application/pdf',
  //       'Content-Type': 'application/json'
  //     }
  //   } // baseURL: process.env.REACT_APP_API_BASE_URL,
  // );
}
