import { useState, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, Typography, Divider, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import darrow from './images/down_arrow.png';
import uarrow from './images/up_arrow.png';
import { getProcessKeywords } from '../../../../../api/history';

const ProcessDialog = (props) => {
  const { conversionId, keywordsId, open } = props;
  const mockCard = [''];
  const [titleTxt, setTitleTxt] = useState('');
  const [condition, setCondition] = useState('');
  const [keywList, setKeywList] = useState<any>([]);
  const [keywListChecked, setKeywListChecked] = useState<any>([]);
  useEffect(() => {
    if (conversionId && keywordsId && open) {
      getProcessKeywords({ kId: keywordsId, pId: conversionId })
        .then(function (response) {
          if (response.status === 200) {
            const { process, recogPhrases } = response.data;
            setTitleTxt(process?.label);
            setKeywList(process?.phrases);
            setCondition(process?.condition.toUpperCase())
            setKeywListChecked(recogPhrases);
          } else {
            console.log('code error');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [conversionId, keywordsId, open]);

  const GenCard = () => {
    const useStyles = makeStyles(() => {
      return createStyles({
        basic: {},
        cardTitle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        },
        span: {
          backgroundColor: '#ccc',
          color: '#fff',
          borderRadius: '5px',
          padding: '3px 5px'
        },
        activeSpan: {
          backgroundColor: '#00a5d9'
        },
        card: {
          padding: '25px',
          marginBottom: '20px'
        },
        activeBgColor: {
          backgroundColor: '#ecfdff'
        },
        condition: {
          width: '100%',
          height: '100%',
          fontSize: '50px',
          alignItems: 'center',
          // color: '#ccc',
          paddingRight: '20px',
        },
        conditionCheck: {
          color: '#00a5d9'
        }
      });
    });
    const classes = useStyles();
    const [slide, setSlide] = useState(true);
    const conditionData = { failed: "x", ok: "〇" }
    const checkedOk = condition === 'AND' ? (keywListChecked.length === keywList.length) : keywListChecked.length > 0

    return (
      titleTxt && (
        <Box className={['select-root-b', classes.card, slide ? checkedOk ? classes.activeBgColor : '' : ''].join(' ')}>
          <Typography className={classes.cardTitle} component="div">
            <h3>{titleTxt}</h3>
            <span onClick={() => setSlide(!slide)} style={{ cursor: 'pointer' }} className={checkedOk ? classes.conditionCheck : ''}>
              {slide ? '詳細の非表示' : '詳細の表示'}
              <img alt='' style={{ marginLeft: '5px' }} src={slide ? uarrow : darrow} />
            </span>
          </Typography>
          {slide && (
            <Box>
              <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
              <Grid container xs={12} >
                <Grid item xs={2} spacing={1} style={{ textAlign: 'center' }}>
                  <span className={[classes.condition,].join(' ')}>{checkedOk ? conditionData.ok : conditionData.failed}</span>
                </Grid>
                <Grid item container xs={10} spacing={1}>
                  <Grid item xs={12} style={{ marginBottom: '5px' }}>
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{condition}</span>
                  </Grid>
                  <Grid item container xs={12} spacing={1}>
                    {keywList.map((v, i) => {
                      return (
                        <Grid item key={i}>
                          <span
                            className={[classes.span, keywListChecked.indexOf(v) > -1 ? classes.activeSpan : ''].join(' ')}
                          >
                            {v}
                          </span>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      )
    );
  };

  return (
    <Dialog open={props?.open} onClose={props?.handleClose} fullWidth>
      <DialogTitle>
        <span style={{ fontSize: '1.3em', fontWeight: 'bold' }}>{'プロセス'}</span>
      </DialogTitle>
      <DialogContent style={{ marginBottom: '20px' }}>
        {mockCard.map((v, i) => {
          return GenCard();
        })}
      </DialogContent>
    </Dialog>
  );
};

export default ProcessDialog;
