import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: '10px'
    },
    Btn: {
      border: '1px solid #1890ff',
      backgroundColor: '#fff',
      padding: ' 5px 15px',
      cursor: 'pointer'
    },
    BtnActive: {
      backgroundColor: '#1890ff'
    }
  })
);

const ExpressBtn: React.FC<ExpressBtnProps> = (props: ExpressBtnProps): JSX.Element => {
  const { type, comIndex, layouts } = props;
  const classes = useStyles();
  const [express, setExpress] = useState(props?.express);

  const updateLayouts = (t) => {
    const temL = layouts.map((el: any, i) => {
      if (i === comIndex) {
        if (type === 'dis') {
          el.header = express;
        } else {
          el.setMax = express;
        }
      }
      return el;
    });
    setExpress(!express);
    props?.layoutFun(temL);
  };

  const hangleExpressChange = (v) => {
    if (v === 't' && !express) {
      updateLayouts(v);
    }
    if (v === 'f' && express) {
      updateLayouts(v);
    }
  };

  return (
    <Box className={classes.root}>
      <span
        className={[classes.Btn, express ? classes.BtnActive : ''].join(' ')}
        onMouseDown={(e) =>
          e.stopPropagation()
        }
        onClick={() => hangleExpressChange('t')}
      >
        表示
      </span>
      <span
        className={[classes.Btn, !express ? classes.BtnActive : ''].join(' ')}
        onMouseDown={(e) =>
          e.stopPropagation()
        }
        onClick={() => hangleExpressChange('f')}
      >
        非表示
      </span>
    </Box>
  );
};

interface ExpressBtnProps {
  express?: any;
  type?: string;
  comIndex?: any;
  layouts?: any;
  layoutFun?: any;
}

export default ExpressBtn;
