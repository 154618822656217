import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import withWrap from '../../withWrap';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    basic: {
      height: '40px',
      borderWidth: '2px',
      borderRadius: '4px',
      textAlign: 'center',
      borderStyle: 'solid',
      borderColor: '#009BDDFF',
      padding: theme.spacing(1)
    },
    title: {
      color: '#FFFFFFFF',
      backgroundColor: '#00A5D9FF'
    },
    selected: {
      color: '#000000FF',
      backgroundColor: '#D7F2FCFF'
    },
    selectedRed: {
      color: '#000000FF',
      borderColor: '#E94609FF',
      backgroundColor: '#FDD9CBFF'
    },
    unselected: {
      color: '#000000FF',
      backgroundColor: '#FFFFFFFF'
    },
    limitedWidth: {
      maxWidth: '800px'
    },
    editBtn: {
      float: 'right',
      position: 'absolute',
      top: '120px',
      right: '50px',
      fontSize: '16px',
      padding: '8px 50px',
      backgroundColor: '#faad14',
      border: '2px solid #ad7506',
      borderRadius: '5px',
      color: '#fff',
      cursor: 'pointer'
    }
  });
});

const DetailsGrid: React.FC<DetailsGridProps> = (props: DetailsGridProps): JSX.Element => {
  const { data, editClick } = props;
  const classes = useStyles();
  const classNames = (classes: (string | false)[]): string => classes.join(' ');
  return (
    <>
      {data?.map((details: DetailsData, index: number) => {
        const {
          title,
          selected: { value, colored },
        } = details;
        return (
          <Grid container item xs={12} spacing={2} key={title + index} className={classes.limitedWidth}>
            <Grid item xs={2}>
              <Paper className={classNames([classes.basic, classes.title])}>{title}</Paper>
            </Grid>
            {details.data.map((option: string, optionIndex: number) => {
              return (
                <Grid item xs={2} key={option + index + optionIndex}>
                  <Paper
                    className={classNames([
                      classes.basic,
                      value?.includes(option) ? (colored ? classes.selectedRed : classes.selected) : classes.unselected
                    ])}
                  >
                    {option}
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        );
      })}
      {props?.data[0]?.editBtn && (
        <button color="primary" onClick={editClick} className={classes.editBtn}>
          編集
        </button>
      )}
    </>
  );
};

interface DetailsGridProps {
  data: DetailsData[];
  editClick: () => void;
}

type DetailsData = {
  title: string;
  data: string[];
  selected: {
    value?: string[];
    colored?: boolean;
  };
  editBtn?: boolean;
};

export type { DetailsData };
export default withWrap<DetailsGridProps>({
  styled: true
})(DetailsGrid);
