import React, { useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Box, Drawer, Grid, Typography } from '@material-ui/core';
import Logo from './images/logo1.png';
import MenuTitleMap from '../../config/menuTitle.json';
import OpenSideIcon from './images/OpenSlideBar.svg';
import CloseSideIcon from './images/CloseSlideBar.svg';
import SidebarRoleIcon from './images/sidebarRoleplay.svg';
import SidebarDashboardIcon from './images/sidebarDashboard.svg';
import SidebarSettingIcon from './images/sidebarSetting.svg';
import { sidebarBgClass, environment } from '../../config/environments';
import { useSelector } from 'react-redux';
import { RootState } from '../../../src/store';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: '#00a5d9'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      // backgroundColor: '#00a5d9',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      overflowX: 'hidden'
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      // backgroundColor: '#00a5d9',
      overflowX: 'hidden',
      width: 60
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    menuLogoBottom: {
      borderBottom: '1px solid #FFFFFF',
      margin: '20px 8px 15px',
      paddingBottom: '8px',
      height: '40px'
    },
    menuLogo: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    menuView: {
      // cursor: 'pointer',
      // backgroundColor: '#027ec3',
      marginBottom: '5px',
      fontSize: '12px'
    },
    logo: {
      verticalAlign: 'center'
    },
    logoTitle: {
      color: '#FFFFFF',
      textDecoration: 'none',
      fontSize: '18px',
      marginLeft: '10px'
    },
    menuList: {
      // marginLeft: '-10px',
      color: '#FFFFFF'
    },
    menuTitle1: {
      marginLeft: '-10px',
      height: '50px',
      lineHeight: '50px',
      verticalAlign: 'top',
      // paddingLeft: '10px',
      display: 'inline'
    },
    menuTitle: {
      marginLeft: '-10px',
      height: '30px',
      lineHeight: '30px',
      verticalAlign: 'top',
      // paddingLeft: '10px',
      display: 'inline'
    },
    displayMenu: {
      display: 'none'
    },
    menuDetail: {
      marginLeft: '30px'
    },
    menuDetailLi: {
      marginLeft: '40px',
      listStyleType: 'none',
      height: '24px',
      lineHeight: '24px',
      cursor: 'pointer'
    },
    isOpen: {
      display: 'none'
    },
    menuClose: {
      cursor: 'pointer',
      position: 'fixed',
      bottom: '0px',
      width: '60px'
    },
    show: {
      fontSize: '12px',
      display: 'inline',
      color: '#fff',
      marginLeft: '3px',
      height: '50px',
      lineHeight: '50px',
      verticalAlign: 'top'
    },
    imageShow: {
      marginRight: '15px',
      marginTop: '8px',
      display: 'inline',
      verticalAlign: 'middle'
    }
  })
);

const TypographyUser = withStyles({
  body1: {
    fontSize: '12px'
  }
})(Typography);

const SwipeableTemporaryDrawer: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [displayMenu, setDisplayMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [clickClose, setClickClose] = useState(false);

  const curUserInfo = useSelector((state: RootState) => state.user.userInfo);

  const isAdmin = curUserInfo['role'] === 'AF_Admin';
  const handleDrawerClose = () => {
    setIsOpen(!isOpen);
    setClickClose(!clickClose)
  };

  // const menuUrl = ['readybefore', 'new', 'history', ]
  const menuUrl = ['readybefore', 'new'];
  const historyUrl = ['history'];
  const settingUrl = [
    'usermanagement',
    'recomanagement',
    'manualmanagement',
    'dictmanagement',
    'machinelearnreflection',
    'annotationmanagement',
    'keywordsmanagement'
  ];

  const handleDetailClick = (path) => {
    navigate('../app/' + path);
  };
  const handleManagementClick = (path) => {
    navigate('../management/' + path);
  };

  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen
        })}
        classes={{
          paper: clsx(`${sidebarBgClass}`, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen
          })
        }}
        onMouseOver={() => {
          if (!clickClose) {
            setIsOpen(true);
          }
        }}
        onMouseLeave={() => {
          setIsOpen(false);
          setClickClose(false);
        }}
      >
        <Box className={classes.menuLogoBottom}>
          <Typography className={isOpen ? classes.menuLogo : classes.isOpen}>
            <img alt="Logo Image" aria-hidden src={Logo} className={classes.logo} />
            <span className={classes.logoTitle}>営業サポートAI</span>
          </Typography>
        </Box>
        <Grid className={classes.menuList}>
          <Box className={[`${environment === 'staging' ? 'bg-darkGreen' : 'bg-darkBlue'}`, classes.menuView].join(' ')}>
            <img
              alt="Sidebar Role Icon"
              src={SidebarRoleIcon}
              style={{ width: '28px', margin: '14px 15px 14px 17px', display: 'inline' }}
            />
            <Typography className={isOpen ? classes.menuTitle1 : classes.isOpen} style={{ fontSize: '14px' }}>
              営業サポートAI
            </Typography>
          </Box>
          <Box
            className={isOpen ? [classes.menuDetail, displayMenu ? classes.displayMenu : ''].join(' ') : classes.isOpen}
          >
            <img alt="" src={SidebarRoleIcon} className={classes.imageShow} style={{ width: '16px' }} />
            <TypographyUser className={isOpen ? classes.menuTitle : classes.isOpen} style={{ fontWeight: 700 }}>
              新規お客さま対応
            </TypographyUser>
            <Grid>
              {menuUrl.map((v, i) => {
                return (
                  <li key={i} className={classes.menuDetailLi} onClick={() => handleDetailClick(v)}>
                    <TypographyUser>{MenuTitleMap[v]}</TypographyUser>
                  </li>
                );
              })}
            </Grid>
          </Box>
          <Box
            className={isOpen ? [classes.menuDetail, displayMenu ? classes.displayMenu : ''].join(' ') : classes.isOpen}
          >
            <img alt="" src={SidebarDashboardIcon} className={classes.imageShow} />
            <TypographyUser className={isOpen ? classes.menuTitle : classes.isOpen} style={{ fontWeight: 700 }}>
              対応履歴
            </TypographyUser>
            <Grid>
              {historyUrl.map((v, i) => {
                return (
                  <li key={i} className={classes.menuDetailLi} onClick={() => handleDetailClick(v)}>
                    <TypographyUser>{MenuTitleMap[v]}</TypographyUser>
                  </li>
                );
              })}
            </Grid>
          </Box>
          {isAdmin && (
            <Box
              className={
                isOpen ? [classes.menuDetail, displayMenu ? classes.displayMenu : ''].join(' ') : classes.isOpen
              }
            >
              <img alt="" src={SidebarSettingIcon} className={classes.imageShow} />
              <TypographyUser className={isOpen ? classes.menuTitle : classes.isOpen} style={{ fontWeight: 700 }}>
                設定
              </TypographyUser>
              <Grid>
                {settingUrl.map((v, i) => {
                  return (
                    <li key={i} className={classes.menuDetailLi} onClick={() => handleManagementClick(v)}>
                      <TypographyUser>{MenuTitleMap[v]}</TypographyUser>
                    </li>
                  );
                })}
              </Grid>
            </Box>
          )}
        </Grid>
        <Box className={classes.menuClose}>
          <img
            alt="Side Icon"
            src={isOpen ? OpenSideIcon : CloseSideIcon}
            style={{ width: '30px', margin: '10px 10px 10px 15px', display: 'inline' }}
            onClick={handleDrawerClose}
          />
          <Typography className={isOpen ? classes.show : classes.isOpen}>メニューを閉じる</Typography>
        </Box>
      </Drawer>
    </div>
  );
};

export default SwipeableTemporaryDrawer;
