import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, Divider, Grid, Paper, Typography } from '@material-ui/core';
import MaxSvg from './images/max.svg';
import Slider from '../../components/Slider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    InsurBtnWarp: {
      display: 'flex',
      marginBottom: '10px'
    },
    InsurBtnS: {
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#3e75d9',
      color: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid hsla(0,0%,49%,0.5)',
      fontWeight: 600,
      borderRadius: '8px',
      fontSize: '16px',
      '&:hover': {
        backgroundColor: '#3e75d9'
      }
    },
    InsurBtn: {
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid hsla(0,0%,49%,0.5)',
      fontWeight: 600,
      borderRadius: '8px',
      color: 'hsla(0,0%,49%,0.5)',
      fontSize: '16px'
    },
    paper: {
      // padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(2)
    },
    paperHealth: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
      marginBottom: theme.spacing(1),
      height: '461px'
    },
    healthBtn: {
      // marginLeft: 'auto',
      padding: '0 16px',
      height: '32px',
      fontSize: '16px',
      textAlign: 'center',
      float: 'right',
      alignItems: 'center',
      lineHeight: '32px',
      border: '1px solid hsla(0,0%,49%,0.31)',
      borderRadius: '24px',
      // boxSizing: 'border-box',
      backgroundColor: 'rgba(0,165,217,1)',
      color: '#fff',
      cursor: 'pointer',
      transition: 'opacity 0.3s',
      marginBottom: '0px',
      '&:hover': {
        // color:'red',
        // borderRadius: '24px',
        backgroundColor: 'rgba(0,165,217,0.6)'
      }
    },
    fullList: {
      width: 'auto'
    }
  })
);

const Home: React.FC = (): JSX.Element => {

  const classes = useStyles();

  const handleMax = () => {
    console.log('Home - handleMax');
  };

  return (
    <>
      <Grid container>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Grid container className={classes.InsurBtnWarp} spacing={2}>
              <Grid item xs={6}>
                <Button className={classes.InsurBtnS} title='商品選択'>
                  がん保険
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button className={classes.InsurBtn} title='商品選択'>
                  医療保険
                </Button>
              </Grid>
            </Grid>
            <Paper>
              <Grid style={{ padding: '0 10px' }}>
                <Grid className={classes.paperHealth}>
                  <Box style={{ marginTop: '360px' }}>
                    <Slider />
                  </Box>
                </Grid>
                <Grid>
                  <Button className={classes.healthBtn}> 健康状態聴取フローの確認 </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.paper}>
              <Grid style={{ height: '330px' }}>
                <Typography style={{ height: '30px' }}> </Typography>
                <Divider />
              </Grid>
            </Paper>
            <Paper className={classes.paper}>
              <Grid style={{ height: '400px' }}>
                <Typography style={{ height: '30px', fontSize: '21px', padding: '0 16px' }} component='div'>
                  <Typography style={{ textAlign: 'left' }}>データ集</Typography>
                  <img alt='Max Icon' aria-hidden style={{ float: 'right', marginTop: '-20px', cursor: 'pointer' }} src={MaxSvg} onClick={handleMax} />
                </Typography>
                <Divider />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
