import service from './service';
import axios from 'axios';
import addAanetHeader from './addMoreHeaders';

export function getTrains(parameter) {
  const url =
    parameter.pageSize === -1
      ? `/trains/search?orderby=${parameter.orderby}&sort=${parameter.sort}`
      : `/trains/search?pagesize=${parameter.pageSize}&page=${parameter.page}&orderby=${parameter.orderby}&sort=${parameter.sort}`;
  return service({
    url: url,
    method: 'post',
    data: {
      ...parameter.data
    }
  });
}

export function getTrainsDetail(parameter) {
  return service({
    url: `/trains/${parameter.id}/lines`,
    method: 'get'
  });
}
export function getTrainsStatus(parameter) {
  return service({
    url: `/trains/${parameter.id}`,
    method: 'get'
  });
}

export function deleteTrains(parameter) {
  return service({
    url: `/trains/${parameter.id}`,
    method: 'delete'
  });
}

export function getProducts(parameter) {
  return service({
    url: `/products/data/search?page=0`,
    method: 'post',
    data: {
      keyWords: parameter.keywords,
    }
  });
}

export function getProductsRefisShow() {
  return service({
    url: `/products/data/search`,
    method: 'post',
    data: {
      isShow: true
    }
  });
}

export function saveTrains(parameter) {
  return service({
    url: `/trains/${parameter.trainid}/lines/${parameter.lineid}/annotations`,
    method: 'post',
    data: {
      ...parameter.data
    }
  });
}

export function updateTrains(parameter) {
  return service({
    url: `/trains/${parameter.trainid}/lines/${parameter.lineid}/annotations/${parameter.annotationid}`,
    method: 'put',
    data: {
      ...parameter.data
    }
  });
}

export function deleteLinesAnnotation(parameter) {
  return service({
    url: `/trains/${parameter.trainid}/lines/${parameter.lineid}/annotations/${parameter.annotationid}`,
    method: 'delete'
  });
}

export function annotationNoFeedback(parameter) {
  return service({
    url: `/trains/${parameter.trainid}/lines/${parameter.lineid}/annotations/${parameter.annotationid}/feedbacks`,
    method: 'post',
    data: {
      ...parameter.data
    }
  });
}

export function annotationHasFeedback(parameter) {
  return service({
    url: `/products/data/feedbacks/${parameter.feedBackId}`,
    method: 'put',
    data: {
      ...parameter.data
    }
  });
}
export function commitAnnotation(parameter) {
  return service({
    url: `/trains/${parameter.trainid}/annotations/commit`,
    method: 'post',
    data: {
      implementStatus: parameter.implementStatus,
      appraiseStatus: parameter.appraiseStatus
    }
  });
}

export function importAnnotation(parameter) {
  let formData = new FormData();
  formData.append('file', parameter.data.file);
  formData.append('filename', parameter.data.filename);
  // return service({
  //   url: `/trains/import`,
  //   method: 'post',
  //   data: {
  //     ...formData
  //   }
  // });
  return axios.post(`/trains/import`, formData, {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    // timeout: 8000,
    headers: {
      Authorization: 'Bearer ' + parameter.token,
      ...addAanetHeader()
    }
  });
}
