import { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePaginationActions from '../../../components/TablePaginationActions';
import WechatSvg from './images/wechat.svg';
import DeleteDialog from '../../../components/management/DeleteDialog';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  deleteTrains,
  getTrains,
  getTrainsDetail,
  importAnnotation,
  getTrainsStatus
} from '../../../api/annotationApi';
import UploadFileDialog from './components/UploadFileDialog';
import AnnotatedModal from './components/AnnotatedModal';
import { formatDate } from '../../../utils/ExportUtil';
import { useDispatch } from 'react-redux';
import { setTrainId } from '../../../features/annotation/annotationSlice';
import EventBus from '../../../components/EventBus';

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    color: '#FFFFFF',
    backgroundColor: '#d98d00',
    '&:hover': {
      backgroundColor: '#d98d00'
    }
  }
}))(Button);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      textAlign: 'left',
      marginLeft: theme.spacing(2) + 2,
      fontSize: '18px',
      fontWeight: 700
      // fontFamily:"'M plus 1p'",
    },
    underline: {
      content: ''
    },
    selectStyle: {
      width: '90%',
      border: '1px solid #ccc',
      borderRadius: '5px',
      paddingLeft: '14px'
    },
    selectStyleTime: {
      width: '90%',
      border: '1px solid #ccc',
      borderRadius: '5px',
      height: '32px',
      marginLeft: '5px'
    },
    tableWrap: {
      alignItems: 'center',
      marginTop: '35px',
      boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)',
      borderRadius: '0px'
    },
    table: {
      width: '100%'
    },
    startBtn: {
      backgroundColor: '#00a5d9'
    },
    dataField: {},
    timeDateBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '5px 0px'
    },
    wechatImg: {
      width: '24px',
      cursor: 'pointer'
    },
    button: {
      width: '200px',
      height: '40px',
      boxShadow: '2px 2px 5px rgb(0 0 0 / 30%)',
      borderRadius: '3px',
      borderColor: '#d98d00'
    }
  })
);

const AnnotationManagement = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const headCellsDef = [
    {
      id: '',
      disablePadding: false,
      label: '通話状態',
      active: false,
      order: 'asc',
      sorted: false,
      style: {
        minWidth: '100px'
      }
    },
    {
      id: 'file_name', // db column name
      disablePadding: false,
      label: 'ファイル名', // table header column name
      active: false, // mouseout display is none except current is cliked
      order: 'asc', // default sort
      sorted: true // this column sort or not
    },
    // {
    //   id: 'agent_name',
    //   disablePadding: false,
    //   label: 'エージェント名',
    //   active: false,
    //   order: 'asc',
    //   sorted: true
    // },
    {
      id: 'line_count',
      disablePadding: false,
      label: '発話数',
      active: false,
      order: 'asc',
      sorted: true
    },
    // {
    //   id: 'product',
    //   disablePadding: false,
    //   label: '商品',
    //   active: false,
    //   order: 'asc',
    //   sorted: true
    // },
    // {
    //   id: 'status',
    //   disablePadding: false,
    //   label: '状況',
    //   active: false,
    //   order: 'asc',
    //   sorted: true
    // },
    {
      id: 'created_at',
      disablePadding: false,
      label: '作成日',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'updated_at',
      disablePadding: false,
      label: '更新日',
      active: false,
      order: 'asc',
      sorted: true
    }
  ];


  const [rows, setRows] = useState([]);
  // cur page
  const [page, setPage] = useState(0);
  const [cPageChange, setcPageChange] = useState(false);
  //
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [totalCount, setTotalCount] = useState(0);

  const [orderby, setOrderby] = useState('');

  const [sort, setSort] = useState('');

  const [open, setOpen] = useState(false);

  const [headCells, setHeadCells] = useState(headCellsDef);

  // dialog fileName
  const [fileName, setFileName] = useState('');

  const [product, setProduct] = useState('');

  // edit dialog
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const [annotatOpen, setAnnotatOpen] = useState(false);

  const [id, setId] = useState(0);

  const [detailData, setDetailData] = useState([]);

  const [implementStatus, setImplementStatus] = useState('未');
  const [appraiseStatus, setAppraiseStatus] = useState('未');

  // dialog upload
  const [uploadF, setUploadF] = useState<any>();
  const filetoString = (file: File) => {
    setUploadF(file);
    // const readerDataURL = new FileReader();
    // readerDataURL.readAsArrayBuffer(file);
    // readerDataURL.onloadend = () => setUploadF(readerDataURL.result || '');
  };

  const handleSort = (property) => {
    const changeSort = headCells?.map((v: any, i) => {
      if (v?.id === property) {
        if (v.order === 'asc') {
          v.order = 'desc';
        } else {
          v.order = 'asc';
        }
        setOrderby(property);
        setSort(v.order);
        v.active = true;
      } else {
        v.active = false;
      }
      return v;
    });
    setHeadCells([...changeSort]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setcPageChange(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setcPageChange(true);
  };

  function getformData() {
    let data = {};

    getTrains({
      orderby: orderby,
      sort: sort,
      pageSize: rowsPerPage,
      page: page,
      // words: searchData
      data: {
        ...data
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          setRows(response.data.trains);
          setTotalCount(response.data.total);
          setcPageChange(false);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (page) {
      //
      if (cPageChange) {
        getformData();
      }
    } else {
      getformData();
    }
  }, [orderby, sort, open, annotatOpen, page, rowsPerPage, cPageChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const onHandleDelClick = (params) => {
    setOpen(true);
    setId(params);
  };

  const handleDelete = () => {
    deleteTrains({ id: id })
      .then(function (response) {
        if (response.status === 204) {
          setOpen(false);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onOpenAnnotatedClick = (id, product) => {
    getTrainsDetail({
      id: id
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(setTrainId(id));
          setDetailData(response.data);
          setAnnotatOpen(true);
          setProduct(product);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    getTrainsStatus({
      id: id
    })
      .then(function (response) {
        if (response.status === 200) {
          setImplementStatus(response.data.implementStatus ? response.data.implementStatus : '未');
          setAppraiseStatus(response.data.appraiseStatus ? response.data.appraiseStatus : '未');
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleUploadFile = () => {
    setProduct('');
    setFileName('');
    setEditDialogOpen(true);
  };

  let EditDialog = {
    title: '',
    data: [
      {
        type: 'upload',
        fieldLabel: 'ファイル',
        fieldName: 'name',
        value: fileName,
        menuName: 'Annotation',
        acceptedFiles: ['.csv', 'text/csv'],
        handleBlur: () => {
          console.log('handleBlur');
        },
        handleChange: () => {
          console.log('handleChange');
        },
        onSave: (v) => {
          setFileName(v?.name);
          filetoString(v);
        }
      }
    ],

    handleSubmit: () => {
      const data = {
        file: uploadF,
        filename: fileName
      };
      importAnnotation({
        data: {
          ...data
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            setProduct('');
            setFileName('');
            setEditDialogOpen(false);
          } else {
            console.log('code error', response);
          }
        })
        .catch(function (error) {
          if (error.response?.status === 409) {
            EventBus.dispatch('show_snack', { message: 'ファイルは既にインポートされています。', type: 'error' });
            console.log(error);
          }else if (error.response?.status === 400){
            EventBus.dispatch('show_snack', { message: 'ファイルをアップロードしてください', type: 'error' });
            console.log(error);
          }
        });
    }
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };
  return (
    <Box className="root-1">
      <Grid>
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0px' }}>
          <ColorButton
            variant="contained"
            className={classes.button}
            startIcon={<AddCircleIcon />}
            onClick={handleUploadFile}
          >
            通話データインポート
          </ColorButton>
        </div>
      </Grid>
      <TableContainer component={Paper} className={classes.tableWrap}>
        <Table className={classes.table} aria-label="visitor detail">
          <EnhancedTableHead handleSort={handleSort} headCells={headCells} />
          <TableBody>
            {rows?.map((row: any) => (
              <TableRow key={row?.id}>
                <TableCell align="center">
                  <img
                    src={WechatSvg}
                    alt=""
                    className={classes.wechatImg}
                    onClick={() => onOpenAnnotatedClick(row?.id, row?.product)}
                  />
                  <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => onHandleDelClick(row?.id)} />
                </TableCell>
                <TableCell align="left">{row.fileName}</TableCell>
                {/* <TableCell align="left">{row.agentName}</TableCell> */}
                <TableCell align="left">{row.lineCount}</TableCell>
                {/* <TableCell align="left">{row.product}</TableCell> */}
                {/* <TableCell align="left">{row.status}</TableCell> */}
                <TableCell align="left">{formatDate(row.createdAt)}</TableCell>
                <TableCell align="left">{formatDate(row.updatedAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, { label: 'All', value: totalCount }]}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                labelRowsPerPage={''}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <DeleteDialog open={open} setOpen={setOpen} handleDelete={handleDelete} modalName="アノテーション" />
      <UploadFileDialog
        data={EditDialog.data}
        handleSubmit={EditDialog.handleSubmit}
        open={editDialogOpen}
        setOpen={handleEditDialogClose}
      />
      <AnnotatedModal
        open={annotatOpen}
        setOpen={setAnnotatOpen}
        product={product}
        detailData={detailData}
        implementStatus={implementStatus}
        setImplementStatus={setImplementStatus}
        appraiseStatus={appraiseStatus}
        setAppraiseStatus={setAppraiseStatus}
      />
    </Box>
  );
};

export default AnnotationManagement;
