import React from 'react';
import _ from 'lodash';
import { Responsive, WidthProvider } from 'react-grid-layout';
import './LayoutSetting.css';
import Translate from './Translate';
import Evaluation from './Evaluation';
import ChatContent from './ChatContent';
import DataCollection from './DataCollection';
import DataCollection2 from './DataCollection2';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */



class AddRemoveLayout extends React.PureComponent {

  static defaultProps = {
    className: 'layout',
    cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 },
    rowHeight: 100
  };

  constructor(props) {
    super(props);
    this.state = {
      items: props?.el_layout,
      newCounter: 0,
      static: props?.forPage,
    };
    this.onAddItem = this.onAddItem.bind(this);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
  }

  createElement(el, i, layouts) {
    const boxStyle = {
      border: '1px solid #ccc',
      borderRadius: '10px',
      backgroundColor: (!this.props?.forPage) ? (el?.bgColor) : '#fff',
      padding: '5px',
      minWidth: '410px',
    };
    const addi = el.add ? '+' : el.i;
    el.static = this.state.static
    return (
      <div key={addi} data-grid={el} style={boxStyle}>
        {!this.props?.forPage && <span onMouseDown={(e) => e.stopPropagation()} onClick={this.onRemoveItem.bind(this, layouts, i)} style={{ float: 'right', cursor: 'pointer', zIndex: 2, marginRight: '5px' }}>X</span>}
        {this.getEl(el, this.props?.forPage, this.props?.data, i, layouts, this.props?.el_layoutFun)}
      </div>
    );
  }

  getEl(el, set, data, i, layouts, layoutFun) {
    switch (el.component) {
      case "Translate":
        return <Translate data={data?.Translate} Max={el.max} set={!set} header={el.header} setMax={el.setMax} settingIndex={i} layouts={layouts} layoutFun={layoutFun} setData={this.props?.setExtractText} />
      case "Evaluation":
        return <Evaluation data={data?.Evaluation} Max={el.max} set={!set} header={el.header} setMax={el.setMax} settingIndex={i} layouts={layouts} layoutFun={layoutFun} />
      case "ChatContent":
        return <ChatContent data={data?.ChatContent} Max={el.max} set={!set} header={el.header} setMax={el.setMax} settingIndex={i} layouts={layouts} layoutFun={layoutFun} />
      case "DataCollection":
        return <DataCollection data={data?.dataCollection} Max={el.max} set={!set} header={el.header} setMax={el.setMax} settingIndex={i} layouts={layouts} layoutFun={layoutFun} />
      case "DataCollection2":
        return <DataCollection2 data={data?.dataCollection2} Max={el.max} set={!set} header={el.header} setMax={el.setMax} settingIndex={i} layouts={layouts} layoutFun={layoutFun} />
      default:
        break
    }
  }

  onAddItem() {
    /*eslint no-console: 0*/
    this.setState({
      // Add a new item. It must have a unique key!
      items: this.state.items.concat({
        i: 'n' + this.state.newCounter,
        x: (this.state.items.length * 2) % (this.state.cols || 12),
        y: Infinity, // puts it at the bottom
        w: 6,
        h: 2
      }),
      // Increment the counter to ensure key is always unique.
      newCounter: this.state.newCounter + 1
    });
  }

  // We're using the cols coming back from this to calculate where to add new items.
  onBreakpointChange(breakpoint, cols) {
    this.setState({
      breakpoint: breakpoint,
      cols: cols
    });
  }

  onLayoutChange(layout) {
    // update layout style

    const tempL = layout.map((v, i) => {
      return {
        w: v.w,
        h: v.h,
        x: v.x,
        y: v.y,
        i: v.i,
        index: i,
        static: v.static,
        add: 0,
        component: this.props?.el_layout[i].component,
        data: this.props?.el_layout[i].data,
        max: this.props?.el_layout[i].max,
        set: this.props?.el_layout[i].set,
        setMax: this.props?.el_layout[i].setMax,
        header: this.props?.el_layout[i].header,
        bgColor: this.props?.el_layout[i].bgColor,
      };
    });
    this.props.el_layoutFun(tempL);
  }

  onRemoveItem(els, i) {
    let temp = [...els]
    temp.splice(i, 1)
    this.props.el_layoutFun(temp);
  }

  render() {
    return (
      <ResponsiveReactGridLayout
        {...this.props}
        className={'react-grid-layout'}
        style={{ position: 'relative' }}
        onLayoutChange={this.onLayoutChange}
        onBreakpointChange={this.onBreakpointChange}
      >
        {_.map(this.props?.el_layout, (el, i) => this.createElement(el, i, this.props?.el_layout))}
      </ResponsiveReactGridLayout>
    );
  }
}

export default React.memo(AddRemoveLayout);
