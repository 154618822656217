const RUN_ENV = process.env.REACT_APP_RUN_ENV;
let sidebarBgClass = ''
let environment = ''
switch (process.env.NODE_ENV) {
    case 'production':
        if (RUN_ENV === 'prod') {
            sidebarBgClass = 'sidebar-bg'
            environment = 'production'
        }
        if (RUN_ENV === 'stage') {
            sidebarBgClass = 'sidebar-bg-green'
            environment = 'staging'
        }
        if (RUN_ENV === 'dev') {
            sidebarBgClass = 'sidebar-bg'
            environment = 'development'
        }
        break
    case 'development':
        sidebarBgClass = 'sidebar-bg'
        environment = 'development'
        break
    default:
        break
}

export {
    environment,
    sidebarBgClass
};
