import service from './service';
export function getUsers(parameter) {
  const url =
    parameter.pageSize === -1
      ? `/users/search?orderby=${parameter.orderby}&sort=${parameter.sort}`
      : `/users/search?pagesize=${parameter.pageSize}&page=${parameter.page}&orderby=${parameter.orderby}&sort=${parameter.sort}`;
    return service({
      url: url,
      method: 'post',
      data: {
        keyWords: parameter.words
      }
    });
}

export function updateUserById(parameter) {
  return service({
    url: `/users/${parameter.account}`,
    method: 'put',
    data: {
      email: parameter.email,
      principle: parameter.principle
    }
  });
}

export function exportFile() {
  return service({
    url: `/users/data/export`,
    method: 'get'
  });
}

