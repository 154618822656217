import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  InputBase,
  Typography
} from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { DropzoneDialog } from 'material-ui-dropzone';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3)
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    }
  })
)(InputBase);

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    color: '#FFFFFF',
    backgroundColor: '#00a5d9',
    '&:hover': {
      backgroundColor: '#00a5d9'
    }
  }
}))(Button);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '56px',
      height: '56px',
      border: '2px',
      borderRadius: '50%',
      fontSize: '30px',
      bottom: '0px',
      right: '0px',
      margin: '50px',
      position: 'fixed',
      backgroundColor: '#303f9f',
      color: '#fff',
      cursor: 'pointer',
      padding: '6px',
      textAlign: 'center'
    },
    email: {
      marginBottom: theme.spacing(5)
    },
    role: {
      width: '100%'
    },
    input: {
      marginBottom: theme.spacing(2)
    },
    button: {
      height: '40px',
      width: '100px',
      // marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2)
    },
    name: {
      lineHeight: '40px',
      marginTop: theme.spacing(2),
      marginLeft: '16px',
      width: '100%'
    },
    errorMessage: {
      color: 'red',
      fontWeight: 500,
      marginTop: '16px'
    }
  })
);

const UploadFileDialog = (props) => {
  const classes = useStyles();
  const { open, setOpen, title, data, handleSubmit, errorMsg } = props;
  const [openDropZone, setOpenDropZone] = React.useState(false);

  const TextInput = (props) => {
    const {
      name,
      label,
      value,
      required,
      disabled = false,
      handleBlur = '',
      handleChange,
      errors = false,
      helperText = '',
      placeholder
    } = props;
    return (
      <TextField
        className={classes.email}
        variant="outlined"
        autoFocus={true}
        fullWidth
        required={required}
        name={name}
        label={label}
        value={value}
        disabled={disabled}
        onBlur={handleBlur}
        onChange={(v) => {
          handleChange(v.target.value);
        }}
        error={errors}
        helperText={helperText}
        placeholder={placeholder}
      />
    );
  };

  const SelectInput = (props) => {
    const { label, name, value, handleChange = '', option = [] } = props;
    return (
      <Box className={classes.role}>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          labelId={label}
          name={name}
          value={value}
          onChange={(v) => {
            handleChange(v.target.value);
          }}
          fullWidth
          variant="outlined"
          input={<BootstrapInput />}
          displayEmpty={true}
          renderValue={(selected: any) => (selected?.length ? selected : `選択してください`)}
        >
          {option?.map((v: any, i) => {
            return (
              <MenuItem value={v?.value} key={i}>
                {v?.text}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    );
  };

  const UploadInput = (props) => {
    const { onSave, menuName, value, acceptedFiles } = props;
    return (
      <Box>
        <Box display="flex" alignItems="top">
          <Box display="flex">
            <ColorButton
              variant="contained"
              color="primary"
              onClick={() => setOpenDropZone(true)}
              className={classes.button}
              disabled={false}
              disableRipple
            >
              ファイル
            </ColorButton>
          </Box>
          {menuName === 'Annotation' && <InputLabel className={classes.name}>{value}</InputLabel>}
        </Box>
        <Typography className={classes.errorMessage} style={{ display: errorMsg ? '' : 'none' }}>
          {errorMsg}
        </Typography>
        <DropzoneDialog
          acceptedFiles={acceptedFiles}
          dialogTitle="アップロード"
          dropzoneText="ここにファイルをドラッグアンドドロップするか、クリックします。"
          previewText="プレビュー:"
          getFileLimitExceedMessage={() => 'ファイルの追加数が上限を超えてます。(1つまで許可)'}
          getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
            if (!acceptedFiles.includes(rejectedFile.type)) {
              return 'Pdfファイルをアップロードしてください。(ファイル形式不正)';
            }

            if (rejectedFile.size > maxFileSize) {
              return `${rejectedFile.name}のアップロードサイズ上限を超過しています。10MByte以下に設定してください。`;
            }

            return 'ファイルのアップロードに失敗しました。';
          }}
          showAlerts={['error']}
          filesLimit={1}
          cancelButtonText={'キャンセル'}
          submitButtonText={'アップロード'}
          maxFileSize={10240000}
          open={openDropZone}
          onClose={() => setOpenDropZone(false)}
          onSave={(files) => {
            onSave(files[0]);
            setOpenDropZone(false);
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        />
      </Box>
    );
  };

  return (
    <>
      <Dialog open={open} onClose={setOpen} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {data?.map((v: any, i) => {
            if (v.type === 'input') {
              return <TextInput key={i} {...v} />;
            } else if (v.type === 'select') {
              return <SelectInput key={i} {...v} />;
            } else if (v.type === 'upload') {
              return <UploadInput key={i} {...v} />;
            } else {
              console.log('invalid type', v.type);
            }
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={setOpen}>キャンセル</Button>
          <Button onClick={handleSubmit} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadFileDialog;
