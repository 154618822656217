import service from './service';

export function login(parameter) {
  return service({
    url: `/login`,
    method: 'post',
  })
}
export function logout() {
  let formData = new FormData();
  formData.append('session_logout', '');
  return service({
    url: `/`,
    method: 'post',
    requestBase: 'logout',
    data: formData,
  })
}

export function getCurUserInfo(parameter) {
  return service({
    url: `/users/me`,
    method: 'get',
  })
}

export function updateCurUserInfo(parameter) {
  return service({
    url: `/users/me`,
    method: 'post',
    data: {
      name: parameter.name
    }
  })
}

export function getAgentInfo(parameter) {
  return service({
    url: `/slsmn-sts`,
    method: 'post',
    requestBase: 'proxy',
    data: {
      method: "GET",
      salt: "MjAyMTA3MjIwMzAy",
      url: process.env.REACT_APP_API_PROXY_PATH + "/slsmn-sts",
      params: {
        salsmanCde: parameter.name
      }
    }
  }
  )
}

export function getAgentByCodeInfo(parameter) {
  return service({
    url: `/agnt-mst`,
    method: 'post',
    requestBase: 'proxy',
    data: {
      method: "GET",
      salt: "MjAyMTA3MjIwMzAy",
      url: process.env.REACT_APP_API_PROXY_PATH + "/agnt-mst",
      params: {
        agentCode: parameter.agentCode
      }
    }
  }
  )
}
