import React from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const theme = createMuiTheme({
  overrides: {
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#00a5d9'
        // color: 'red'
      }
    }
  }
});

const Message = (props) => {
  const { vertical, horizontal, open, type, msg, closeFun } = props;
  //type enum
  // error warning info success

  const handleClose = () => {
    closeFun(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        anchorOrigin={{ vertical: vertical ? vertical : 'top', horizontal: horizontal ? horizontal : 'right' }}
        open={open}
        key={vertical + horizontal}
        autoHideDuration={5000}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert onClose={handleClose} severity={type}>
          {msg}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default Message;
