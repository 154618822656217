import React, { memo, useCallback, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useFormik } from 'formik';

export interface SynonymChangeSet {
  words: string[];
}

interface SynonymFormProps {
  onSave: (changeSet: SynonymChangeSet) => void;
  onClose: () => void;
  initialWords: string[];
}

interface AddWordFormValues {
  word: string;
}

const useStyles = makeStyles((theme) => ({
  addWordForm: {
    marginTop: theme.spacing(4),
    display: 'flex'
  },
  addWordButton: {
    flexBasis: 150,
    paddingLeft: theme.spacing(3),
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'top'
  }
}));

const SynonymForm = ({ onSave, onClose, initialWords }: SynonymFormProps) => {
  const [words, setWords] = useState(initialWords);
  const classes = useStyles();

  const addWordForm = useFormik<AddWordFormValues>({
    initialValues: {
      word: ''
    },
    validate(values) {
      if (words?.includes(values?.word.trim())) {
        return { word: 'キーワードが既に存在しています.' };
      } else if (!values?.word.trim()) {
        return { word: 'キーワードを入力の上、"追加"ボタンを押してください.' };
      } else if (values?.word?.trim().includes('\t')) {
        return { word: 'キーワードを入力の上、"追加"ボタンを押してください.' };
      } else {
        return {};
      }
    },
    onSubmit(values, helpers) {
      setWords([...words, values?.word.trim()]);
      helpers.resetForm();
    }
  });
  const handleSave = useCallback(() => {
    onSave({ words });
  }, [onSave, words]);

  return (
    <>
      <DialogContent dividers>
        <List dense>
          {words.map((word) => {
            const handleRemove = () => setWords(words.filter((w) => w !== word));
            return (
              <ListItem key={word}>
                <ListItemText>{word}</ListItemText>
                <ListItemSecondaryAction>
                  <IconButton onClick={handleRemove} edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <form onSubmit={addWordForm.handleSubmit} className={classes.addWordForm}>
          <TextField
            autoFocus
            fullWidth
            placeholder="ワード"
            name="word"
            value={addWordForm.values.word}
            onBlur={addWordForm.handleBlur}
            onChange={addWordForm.handleChange}
            error={!!addWordForm.errors.word}
            helperText={addWordForm.errors.word}
          />
          <div className={classes.addWordButton}>
            <div>
              <Button type="submit" color="secondary" variant="contained">
                追加
              </Button>
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={handleSave} color="primary">
          保存
        </Button>
      </DialogActions>
    </>
  );
}

export default memo(SynonymForm);
