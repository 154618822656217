import React, { useState, useEffect, useRef } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Divider, Grid, Typography} from '@material-ui/core';
import Slider from './Slider';
import Title from './DetailTitle';
import { getConversionList } from '../api/customerSupportApi'
import ExpressBtn from './ExpressBtn'
import NewWindow from 'react-new-window'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      border: '1px solid #ccc',
      borderRadius: '10px',
      backgroundColor: '#FFFFFF',
      padding: '5px 25px'
    },
    translateBox: {
      height: '100%',
      marginBottom: '10px',
      width: '100%',
      position: 'relative',
      paddingBottom: '100px'
    },
    fullScreen: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      zIndex: 6666,
      borderRadius: 0
    },
    translateBoxTitle: {
      display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '30px'
    },
    translateBoxMain: {
      display: 'flex',
      flexDirection: 'column',
      // overflow: `hidden !important`,
      height: '100%',
      overflowY: 'scroll',
      position: 'relative'
    },
    translateText: {
      position: 'relative',
      maxWidth: `calc(
          85% - 16px - 8%
        )`,
      height: '100%',
      padding: '16px',
      borderRadius: '16px',
      display: 'inline-block',
      boxShadow: `0 2px 5px 0 rgba(0, 0, 0, 0.16),
          0 2px 10px 0 rgba(0, 0, 0, 0.12)`,
      '&p':
      {
        wordBreak: 'break-all'
      },
      '&:after': {
        content: '\'\'',
        position: 'absolute',
        width: 0,
        height: 0,
        bottom: 'auto',
        border: '12px solid'
      }
    },
    operatorChatTextBox: {
      display: 'flex',
      flexDirection: 'row-reverse',
      margin: '5px'
    },
    operatorChatText: {
      backgroundColor: '#00a5d9',
      marginRight: '16px',
      color: '#fff',

      '&:after': {
        left: 'auto',
        right: '-10px',
        top: '14px',
        transform: 'rotate(45deg)',
        borderColor: '#00a5d9 transparent transparent transparent'
      }
    },
    customerChatTextBox: {
      display: 'flex',
      flexDirection: 'row',
      margin: '5px'
    },
    customerChatText: {
      backgroundColor: '#ecf7fb',
      marginLeft: '16px',
      flexDirection: 'row',
      '&:after': {
        left: '-10px',
        right: 'auto',
        top: '-4px',
        transform: `rotate(180deg)`,
        borderColor: `#ecf7fb transparent transparent transparent`
      }
    }
  })
);

const Translate: React.FC<TranslateProps> = (props: TranslateProps): JSX.Element => {

  const { data, set, setMax, header, setData } = props;
  const classes = useStyles();
  const [fSize, setFSize] = useState(16);
  const [conversionList, setConversionList] = useState<any[]>([]);

  const [stopIndexList, setStopIndexList] = useState<any[]>([[0, 0]]);
  const [popTranslate, setPopTranslate] = useState(false);
  const [connect, setConnect] = useState(false);
  const messagesEnd = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // set interval
    let lastConversionTime = ''
    const { id, callId } = data
    if (id && callId) {
      const timerId = setInterval(() => {
        getConversionList({
          ...data,
          lastTime: lastConversionTime
        })
          .then(function (response) {
            if (response.status === 200 && response.data.length > 0) {
              const le = response.data.length;
              if (le) {
                lastConversionTime = response.data[le - 1]['createdAt']
                setConversionList((pre) => pre.concat(response.data));
                setData && setData(response.data)
              }
            } else {
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }, 2000);
      // clear interval after com out
      return () => { clearInterval(timerId) };
    }
  }, [data]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (messagesEnd && messagesEnd.current) {
      const scrollHeight = messagesEnd.current?.scrollHeight
      const height = messagesEnd.current?.clientHeight;
      const maxScrollTop = Number(scrollHeight) - Number(height);
      messagesEnd.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

  }, [conversionList]);//eslint-disable-line react-hooks/exhaustive-deps

  const handleChatMax = () => {
    setPopTranslate(true)
  };


  return (
    !set ? <>
      {/* {account === 'jianbo.jia@xenera.jp' && <Button color='primary' variant='outlined' style={{ top: '-2px', left: '250px', position: 'fixed', cursor: 'pointer', zIndex: 2 }} onClick={handleTestDataClick}>Convsertion Test</Button>} */}
      <Box className={classes.translateBox}>
        <Title text={!header ? '会話内容' : ''} Max={!setMax} FnMax={handleChatMax} connect={connect} setCon={false} />
        <div
          className={classes.translateBoxMain}
          ref={messagesEnd}
        >
          {conversionList?.map((m, i) => {
            return <Box
              key={i} className={m.speaker === "0" ? classes.operatorChatTextBox : classes.customerChatTextBox}
            >
              <p className={[m.speaker === "0" ? classes.operatorChatText : classes.customerChatText, classes.translateText].join(' ')} style={{ fontSize: fSize }}>{m.recogText}</p>
            </Box>;
          })}
        </div>
      </Box>
      <Box>
        <Slider fn={setFSize} />
      </Box>
      {popTranslate && <NewWindow
        title={'会話内容'}
        center={'screen'}
        copyStyles={true}
        onUnload={() => {
          setPopTranslate(false)
        }}
      >
        <div className={setMax ? [classes.root, classes.fullScreen].join(" ") : ""}>
          <Box className={classes.translateBox}>
            <Title text={!header ? '会話内容' : ''} Max={!setMax} FnMax={handleChatMax} />
            <div
              className={classes.translateBoxMain}
              ref={messagesEnd}
            >
              {conversionList?.map((m, i) => {
                let filterI = false
                // stopIndexList.filter((item, index) => {      // for on/off func
                //   if (item[0] <= i && item[1] >= i || item[0] <= i && item[1] === 0) {
                //     filterI = true
                //     return
                //   }
                // })
                return filterI ? '' : <Box key={i} className={m.speaker === "0" ? classes.operatorChatTextBox : classes.customerChatTextBox}>
                  <p className={[m.speaker === "0" ? classes.operatorChatText : classes.customerChatText, classes.translateText].join(' ')} style={{ fontSize: fSize }}>{m.recogText}</p>
                </Box>;
              })}
            </div>
          </Box>
          <Box>
            <Slider fn={setFSize} />
          </Box>
        </div>
      </NewWindow>}
    </>
      :
      <>
        <span className='remove'>会話内容</span>
        <Divider />
        <Grid container xs={12} spacing={2} style={{ padding: '15px' }}>
          <Grid item xs={6}>
            <Typography>ヘッダー表示</Typography>
            <ExpressBtn express={!props?.header} type="dis" comIndex={props?.settingIndex} layouts={props?.layouts} layoutFun={props?.layoutFun} />
          </Grid>
          <Grid item xs={6}>
            <Typography>最大化</Typography>
            <ExpressBtn express={!props?.setMax} type="max" comIndex={props?.settingIndex} layouts={props?.layouts} layoutFun={props?.layoutFun} />
          </Grid>
        </Grid>
      </>
  );
};

interface TranslateProps {
  Max?: boolean;
  data?: any;
  set?: any;
  setMax?: boolean;
  header?: boolean;
  settingIndex?: any;
  layouts?: any;
  layoutFun?: any;
  setData?: any;
  token?: any;
}

export default React.memo(Translate);

