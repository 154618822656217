import React, { useEffect } from 'react';
import { useRoutes, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import routes from './routes';
import GlobalStyles from './components/GlobalStyles';
import SnackBar from './components/Snackbar';
import AutoLagoutTimer from './components/AutoLagoutTimer';
import { useSelector } from 'react-redux';
import { RootState } from '../src/store';

const useScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);//eslint-disable-line react-hooks/exhaustive-deps
  return null;
};

const App = () => {
  const navigate = useNavigate();
  const routing = useRoutes(routes, process.env.REACT_APP_BASE_NAME);
  const curUserInfo = useSelector((state: RootState) => state.user.userInfo);
  const isAdmin = curUserInfo['role'] === 'AF_Admin';
  const pathName = routing?.props.value.pathname.split('/')[1]
  if (pathName === 'management' && !isAdmin) {
    navigate('../logout');
    // if (RUN_ENV === 'dev') {
    // } else {
    //   navigate('../../');
    // }
  }

  useScrollToTop();
  return (
    <>
      <ThemeProvider theme={theme}>
        <AutoLagoutTimer />
        <GlobalStyles />
        <SnackBar />
        {/* <Menu />*/}
        {/*<RightMenu />*/}
        {routing}
      </ThemeProvider>
    </>
  );
};

export default App;
