import service from './service';

export function getFileList(parameter) {
  const url =
    parameter.pagesize === -1
      ? `/fileset/search?orderby=${parameter.orderby}&sort=${parameter.sort}`
      : `/fileset/search?pagesize=${parameter.pagesize}&page=${parameter.page}&orderby=${parameter.orderby}&sort=${parameter.sort}`;
  return service({
    url: url,
    method: 'post',
    data: {
      keyWords: parameter.keyWords
    }
  });
}

export function getFileById(parameter) {
  return service({
    url: `/fileset/${parameter.id}`,
    method: 'get'
  });
}

export function modFileById(parameter) {
  return service({
    url: `/fileset/${parameter.id}`,
    method: 'put',
    data: {
      name: parameter.name,
      description: parameter.description,
      content: parameter.content,
      isRecordAgree: parameter.isRecordAgree
    }
  });
}

export function deleteFileById(parameter) {
  return service({
    url: `/fileset/${parameter.id}`,
    method: 'delete'
  });
}

export function downloadFileById(parameter) {
  return service({
    url: `/fileset/${parameter.id}/content`,
    requestBase: 'pdf',
    method: 'get'
  });
}

export function uploadFile(parameter) {
  return service({
    url: `/fileset`,
    method: 'post',
    data: {
      name: parameter.name,
      description: parameter.description,
      content: parameter.content,
      isRecordAgree: parameter.isRecordAgree
    }
  });
}

export function exportFile(parameter) {
  return service({
    url: `/fileset/data/export`,
    method: 'get'
  });
}
