import { memo, useState } from 'react';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Grid, Modal, Input, Button,Typography } from '@material-ui/core';
import ModalHeadButton from './ModalHeadButton';
import DoneIcon from '@material-ui/icons/Done';

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    color: '#FFFFFF',
    backgroundColor: '#21c79a',
    '&:hover': {
      backgroundColor: '#21c79a'
    }
  }
}))(Button);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '3px',
    padding: '0px 0px 24px 24px'
  },
  button: {
    width: '140px',
    height: '40px',
    boxShadow: '2px 2px 5px rgb(0 0 0 / 30%)',
    borderRadius: '3px',
    marginRight: '24px'
  },
  selectStyle: {
    width: '100%',
    height: '36px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    padding: '5px',
    margin: '16px 0px',
    '&.Mui-focused': {
      border: '2px solid #222'
    }
  },
  errorMessage: {
    color: 'red',
    fontWeight: 500,
    marginTop: '16px'
  }
}));

const AddPharaseModal = (props) => {
  const { pharaseOpen, setPharaseOpen, pharaseSubmit } = props;
  const classes = useStyles();

  const [pharaseCode, setPharaseCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleClose = () => {
    setPharaseOpen(false);
  };
  const handleChange = (pharase) => {
    setPharaseCode(pharase)
    if(pharase){
      setErrorMsg('')
    }
  };
  const handleSubmit = () => {
    if (pharaseCode) {
      setErrorMsg('')
      pharaseSubmit(pharaseCode);
      setPharaseCode('')
      setPharaseOpen(false);
    } else {
      setErrorMsg('フレーズテキストを入力してください。');
    }
  };

  const body = (
    <div className={classes.paper}>
      <ModalHeadButton id="customized-dialog-title" onClose={handleClose} />
      <Grid xs={12} style={{ marginRight: '24px' }}>
        <Input
          disableUnderline
          className={classes.selectStyle}
          onChange={(e) => handleChange(e.target.value)}
          placeholder="追加するフレーズ"
          autoFocus
        />
      </Grid>
      <Typography className={classes.errorMessage} style={{ display: errorMsg ? '' : 'none' }}>
        {errorMsg}
      </Typography>
      <Grid container direction="row" justify="flex-end" alignItems="flex-start">
        <ColorButton
          variant="contained"
          className={classes.button}
          startIcon={<DoneIcon />}
          onClick={() => handleSubmit()}
        >
          追加
        </ColorButton>
      </Grid>
    </div>
  );
  return (
    <div>
      <Modal className={classes.modal} open={pharaseOpen} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
};

export default memo(AddPharaseModal);
