import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    aanetUser: '',
    aanetGroup: '',
    curUserAgentCode: '',
    userInfo: {}
  },
  reducers: {
    setAanetUser: (state, action) => {
      state.aanetUser = action.payload;
    },
    setAanetGroup: (state, action) => {
      state.aanetGroup = action.payload;
    },
    setCurUserAgentCode: (state, action) => {
      state.curUserAgentCode = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    }
  }
});

export const { setAanetUser, setAanetGroup, setCurUserAgentCode, setUserInfo } = userSlice.actions;

export default userSlice.reducer;
