import { createSlice } from '@reduxjs/toolkit';

export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    type: '',
    conversion: {},
    ColEval: {
    },
  },
  reducers: {
    setConversion: (state, action) => {
      state.conversion = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setCollAndEval: (state, action) => {
      state.ColEval = action.payload;
    },

  }
});

export const {
  setConversion,
  setType,
  setCollAndEval
} = globalSlice.actions;

export default globalSlice.reducer;
