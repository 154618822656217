import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';

const EnhancedTableHead = (props) => {
  const { headCells, handleSort } = props;
  const createSortHandler = (property) => () => {
    handleSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell?.id}
            align={'center'}
            padding={headCell?.disablePadding ? 'none' : 'default'}
            sortDirection={headCell?.order}
            style={headCell?.style}
          >
            <TableSortLabel
              active={headCell?.active}
              direction={headCell?.order}
              onClick={headCell?.sorted ? createSortHandler(headCell?.id) : () => {}}
              hideSortIcon={!headCell?.sorted}
            >
              {headCell?.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
