import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TablePaginationActions from '../../../components/TablePaginationActions';
import TitleWithSearch from '../../../components/management/TitleWithSerach';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import {
  getRecomandList,
  deleteRecomand,
  getRecomand,
  updateRecomand,
  addRecomand,
  exportFile
} from '../../../api/recomandApi';
import { formatDate, openCsvExportString } from '../../../utils/ExportUtil';
import DeleteDialog from '../../../components/management/DeleteDialog';
import DownloadDialog from '../../../components/management/DownloadDialog';
import ProductDataSetDialog from './components/ProductDataSetDialog';
import AddBtn from '../../../components/management/AddSynonymBtn';
import { ProductDataChangeSet, serializeProductDataChangeSet, parseProductDataSet } from './components/ProductData';

const useStyles = makeStyles(() =>
  createStyles({
    titleSearch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    titleText: {
      fontWeight: 800,
      fontSize: '20px'
    },
    inputDel: {
      cursor: 'pointer',
      marginRight: '5px',
      opacity: '0.3',
      '&:hover': {
        opacity: '0.5'
      }
    },
    tableWrap: {
      alignItems: 'center',
      marginTop: '30px',
      boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)',
      borderRadius: '0px'
    },
    table: {
      width: '100%'
    },
    tableCellAction: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  })
);

const RecommendationManagement = (props) => {
  const classes = useStyles();
  // const navigate = useNavigate()

  // table datas
  const [rows, setRows] = useState([]);

  // cur page
  const [page, setPage] = React.useState(0);
  const [cPageChange, setcPageChange] = React.useState(false);
  //
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);
  const [orderby, setOrderby] = useState('');
  const [sort, setSort] = useState('');
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(0);
  const [type, setType] = React.useState('');
  const [searchData, setSearchData] = React.useState(null);
  // current id
  const [currentId, setCurrentId] = React.useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [editData, setEditData] = React.useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setcPageChange(true)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setcPageChange(true)
  };

  const onHandleEditClick = (id) => {
    getRecomand({
      id: id
    })
      .then(function (response) {
        if (response.status === 200) {
          const d = response.data;
          setEditData(parseProductDataSet(d));
          setCurrentId(d?.id);
          setType('Edit');
          setOpen(true);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onHandleDelClick = (params) => {
    setOpen(true);
    setId(params);
    setType('Delete');
  };

  function initTable(orderby, sort, page, rowsPerPage, searchData) {
    getRecomandList({
      type: 'product',
      orderby: orderby,
      sort: sort,
      words: searchData,
      pageSize: rowsPerPage,
      page: page
    })
      .then(function (response) {
        if (response.status === 200) {
          setRows(response.data.products);
          setTotalCount(response.data.total);
          setcPageChange(false)
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (searchData && page) {
      //
      if (cPageChange) {
        initTable(orderby, sort, page, rowsPerPage, searchData);
      }
    } else {
      initTable(orderby, sort, page, rowsPerPage, searchData);
    }
  }, [orderby, sort, open, page, rowsPerPage, searchData, cPageChange]); // eslint-disable-line react-hooks/exhaustive-deps


  const title = 'レコメンド表示内容を管理';

  //  table header begin
  const headCellsDef = [
    {
      id: 'id', // db column name
      disablePadding: false,
      label: 'ID', // table header column name
      active: false, // mouseout display is none except current is cliked
      order: 'asc', // default sort
      sorted: true // this column sort or not
    },
    {
      id: 'product',
      disablePadding: false,
      label: '商品',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'summary',
      disablePadding: false,
      label: '内容',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'referencesCount',
      disablePadding: false,
      label: 'フリップ数',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'created_at',
      disablePadding: false,
      label: '作成日時',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'updated_at',
      disablePadding: false,
      label: '更新日時',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'アクション',
      active: false,
      order: 'asc',
      sorted: false,
      style: {
        minWidth: '100px'
      }
    }
  ];

  const [headCells, setHeadCells] = React.useState(headCellsDef);

  const handleSort = (property) => {
    const changeSort = headCells?.map((v: any, i) => {
      if (v?.id === property) {
        if (v.order === 'asc') {
          v.order = 'desc';
        } else {
          v.order = 'asc';
        }
        setOrderby(property);
        setSort(v.order);
        v.active = true;
      } else {
        v.active = false;
      }
      return v;
    });
    setHeadCells([...changeSort]);
  };
  //  table header end
  const onHandleSearch = (searchData) => {
    setSearchData(searchData);
    setPage(0);
  };

  const handleDelete = () => {
    deleteRecomand({ id: id })
      .then(function (response) {
        if (response.status === 204) {
          setOpen(false);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const onHandleDownload = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleDownload = () => {
    exportFile()
      .then(function (response) {
        openCsvExportString(`recommendation_management.csv`, response.data);
        setAnchorEl(null);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onHandleAddClick = () => {
    setOpen(true);
    setType('Add');
    setEditData([]);
  };

  const handleSave = (changeSet: ProductDataChangeSet) => {
    const data = serializeProductDataChangeSet(changeSet);
    data['keywords'] = [];
    if (type === 'Add') {
      addRecomand({
        data: {
          ...data
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            initTable(orderby, sort, page, rowsPerPage, searchData);
            setOpen(false);
          } else if (response.status === 409) {
            console.log(response);
          } else {
            console.log('code error');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      updateRecomand({
        id: currentId,
        data: {
          ...data
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            initTable(orderby, sort, page, rowsPerPage, searchData);
            setOpen(false);
          } else if (response.status === 409) {
            console.log(response);
          } else {
            console.log('code error');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };
  return (
    <Box className="root-m">
      <TitleWithSearch title={title} setSearchFn={onHandleSearch} onclick={onHandleDownload} />
      <TableContainer component={Paper} className={classes.tableWrap}>
        <Table className={classes.table} aria-label="visitor detail">
          <EnhancedTableHead handleSort={handleSort} headCells={headCells} />
          <TableBody>
            {rows?.map((row: any, i) => (
              <TableRow key={row?.id}>
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.product}</TableCell>
                <TableCell align="left">{row.summary}</TableCell>
                <TableCell align="center">{row.references?.length}件</TableCell>
                <TableCell align="left">{formatDate(row.createdAt)}</TableCell>
                <TableCell align="left">{formatDate(row.updatedAt)}</TableCell>
                <TableCell align="center">
                  <EditIcon
                    style={{ marginRight: '15px', cursor: 'pointer' }}
                    onClick={() => onHandleEditClick(row?.id)}
                  />
                  <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => onHandleDelClick(row?.id)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, { label: 'All', value: totalCount }]}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                labelRowsPerPage={''}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {type === 'Delete' && (
        <DeleteDialog open={open} setOpen={setOpen} handleDelete={handleDelete} modalName="レコメンド" />
      )}
      {(type === 'Add' || type === 'Edit') && (
        <ProductDataSetDialog open={open} setOpen={setOpen} onSave={handleSave} type={type} productDataSet={editData} />
      )}
      <DownloadDialog anchorEl={anchorEl} setAnchorEl={setAnchorEl} downloadFile={handleDownload} />
      <AddBtn onClick={onHandleAddClick} />
    </Box>
  );
};

export default RecommendationManagement;
