import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        backgroundColor: '#f4f6f8',
        height: '100%',
        width: '100%',
        color: '#333',
        fontFamily: 'Meiryo, sans-serif'
      },
      a: {
        textDecoration: 'none'
      },
      '#root': {
        height: '100%',
        width: '100%'
      },
      ...GlobalClasses
    }
  })
);

const GlobalStyles = () => {
  useStyles();
  return null;
};

export default GlobalStyles;

export const GlobalClasses = {
  'root-1': {
    alignItems: 'center',
    width: '100%',
    padding: '15px 30px',
    margin: '0px',
    marginBottom: '20px'
  },
  'root-m': {
    alignItems: 'center',
    width: '100%',
    padding: '15px 30px',
    margin: '0px',
    marginBottom: '20px'
  },
  '.detail-info-label': {
    fontSize: '20px',
    fontWeight: 800,
    backgroundColor: '#00a5d9',
    marginTop: '2px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'justify'
  },
  '.select-root': {
    textAlign: 'center',
    lineHeight: '60px',
    padding: '0'
  },
  '.select-root-b': {
    padding: '15px 10px 5px 10px',
    border: '1px solid #ccc',
    borderRadius: ' 8px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)'
  },
  '.detail-span': {
    border: '2px solid #00a5d9',
    borderRadius: '2px',
    width: '100%',
    height: '60px',
    margin: '5px',
    fontSize: '20px',
    display: 'flex',
    color: '#555',
    lineHeight: '20px',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    cursor: 'pointer'
  },
  '.detail-span-check': {
    backgroundColor: '#b5eeff',
    color: '#333',
    fontWeight: 700
  },
  '.btn-1': {},
  '.fw-700': {
    fontWeight: 700
  },
  '.tx-white': {
    color: 'white'
  },
  textRed: {
    color: 'red'
  },
  '.radio-left': {
    margin: '8px'
  },
  '.sidebar-bg': {
    backgroundColor: '#00a5d9'
  },
  '.sidebar-bg-green': {
    backgroundColor: '#69a926'
  },
  '.bg-darkGreen': {
    background: '#093'
  },
  '.bg-darkBlue': {
    background: '#027EC3'
  }
};
