import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, TextField, RadioGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { setResultInput } from '../../api/readyBefore';
import { getConversationById } from '../../api/history';
import ConfirmDialog from '../../components/management/ConfirmDialog';
import Radio from '../../components/RadioButtons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../src/store';

const useStyles = makeStyles(() =>
  createStyles({
    detailGMargin: {
      marginTop: '15px'
    },
    note: {
      width: '100%',
      // border: '2px solid #00a5d9',
      border: '1px solid #ccc',
      borderRadius: '2px',
      padding: '5px',
      marginBottom: '8px',
      marginTop: '8px'
    },
    add: {
      paddingTop: '10px',
      paddingBottom: '10px',
      border: '3px solid #00a5d9',
      backgroundColor: '#ccc',
      cursor: 'pointer',
      color: 'black',
      textAlign: 'center',
      fontSize: '23px',
      fontWeight: 700,
      width: '100%'
    },
    imData: {
      width: '100%',
      border: 0,
      height: '100%',
      fontSize: '22px',
      '&:focus': {
        outline: 'none'
      }
    },
    saveBtn: {
      marginTop: '15px'
    },
    disablegrid: {
      pointerEvents: 'none'
    },
    roleSpanLabel: {
      // display: 'flex',
      // alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 700
    },
    disableBtn: {
      border: '0px',
      backgroundColor: '#ccc',
      color: '#fff',
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: '#ccc',
        color: '#fff'
      }
    },
    fontWeight: {
      fontWeight: 700
    },
    radioMargin: {
      marginLeft: '8px'
    }
  })
);

const Result = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const R0 = ['成約', 'NG', '継続'];
  const R = ['満足', 'やや満足', 'やや不満', '不満'];
  const roleAF = ['AF_Admin', 'AF_I3NBA', 'AF_I3NBAacs', 'AF_I3NBAscc'];
  const [products, setProducts] = useState([
    { value: 'がん', checked: false },
    { value: '医療', checked: false },
    { value: '給与', checked: false },
    { value: '介護', checked: false },
    { value: '死亡', checked: false },
    { value: '学資', checked: false },
    { value: 'その他', checked: false }
  ]);
  const [result, setResult] = useState('');
  const [content, setContent] = useState('');
  const [notes, setNotes] = useState('');
  const [submitOpen, setSubmitOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);
  const curUserInfo = useSelector((state: RootState) => state.user.userInfo);
  const isAF = roleAF.indexOf(curUserInfo['role']) > -1 ? true : false;

  const curId = location.state ? location.state['id'] : 0;
  const backStatus = location.state ? location.state['backstatus'] : '';

  useEffect(() => {
    getConversationById({
      ...location?.state
    })
      .then(function (response) {
        if (response.status === 200) {
          const { contractResult, contractProduct, appraise, isPublic, recommend } = response?.data;
          const contractProduct1 = contractProduct ? JSON.parse(contractProduct)[0] : [];
          const dbProducts = products.map((item: any, i) => {
            if (contractProduct1.indexOf(item.value) > -1) {
              item.checked = true;
            }
            return item;
          });
          setProducts(dbProducts);
          setResult(contractResult ? contractResult : '');
          setContent(appraise ? appraise : '');
          setNotes(recommend ? recommend : '');
          setIsPublic(isPublic);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const productFlag = products.filter((item, i) => {
      if (item.checked) return true;
    });
    if (result === '' || content === '') {
      setIsUpdate(false);
    } else if (result === '成約') {
      if (productFlag[0]) {
        setIsUpdate(true);
      } else {
        setIsUpdate(false);
      }
    } else {
      setIsUpdate(true);
    }
  }, [result, content, notes, isPublic, products]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    setSubmitOpen(true);
  };
  const onSubmit = () => {
    let productText = [] as any;
    productText = products
      .map((v: any, i) => {
        if (v.checked) {
          return v.value;
        }
      })
      .filter(Boolean);
    let temp = [] as any;
    temp.push(productText);
    setResultInput({
      notes: notes,
      appraise: content,
      contractResult: result,
      contractProduct: result === '成約' ? JSON.stringify(temp) : JSON.stringify([[]]),
      isPublic: isPublic,
      ...location?.state
    })
      .then(function (response) {
        if (response.status === 200) {
          setSubmitOpen(false);
          if (historyStatus) {
            navigate('../history:' + response.data?.id, {
              state: {
                pageSize: hisPageSize,
                page: hisPage,
                sevenCheck: sevenCheck,
                selectedName: selectedName,
                selectedAS: selectedAS,
                companySelectedList: companySelectedList,
                companyCode: companyCode,
                branchCode: branchCode,
                selectedAge: selectedAge,
                selectedResult: selectedResult,
                selectedContent: selectedContent,
                selImplementStatus: selImplementStatus,
                selAppraiseStatus: selAppraiseStatus,
                selImplementAccount: selImplementAccount,
                branchCodeList: branchCodeList,
                historyStatus: historyStatus
              }
            });
          } else {
            navigate('../history:' + response.data?.id);
          }
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleNotesChange = (v) => {
    setNotes(v.target.value);
  };

  const handleAFListChange = (e, i) => {
    const c = e.target.checked;
    let tem = [...products];
    tem[i].checked = c;
    setProducts(tem);
    products && setResult('成約');
    let productText = [] as any;
    productText = products
      .map((v: any, i) => {
        if (v.checked) {
          return v.value;
        }
      })
      .filter(Boolean);
    productText.length > 0 ? setResult('成約') : setResult('');
  };

  const genCheckBoxList = (v, i) => {
    return (
      <Grid style={{ width: '220px' }} key={i}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={result === '成約' || result === '' ? v.checked : false}
                onChange={(e) => {
                  handleAFListChange(e, i);
                }}
                name={v.value}
                color="primary"
                disabled={result === '成約' || result === '' ? false : true}
              />
            }
            label={v.value}
          />
        </Grid>
      </Grid>
    );
  };

  const ContractedProduct = (props) => {
    return (
      <>
        <Grid item container xs={2} className={classes.roleSpanLabel}>
          <Grid item xs={10}>
            <span>成約商品</span>
          </Grid>
          <Grid item xs={2}>
            <span>:</span>
          </Grid>
        </Grid>
        <Grid container item xs={10} spacing={1}>
          {products.map((v, i) => {
            return genCheckBoxList(v, i);
          })}
        </Grid>
      </>
    );
  };

  const handleResultClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    setResult(v);
  };
  const handleContentClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    setContent(v);
  };

  const handlePublicClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    var isTrueSet = v === 'true';
    setIsPublic(isTrueSet);
  };

  const historyStatus = location.state ? location.state['historyStatus'] : '';
  const hisPageSize = location.state ? location.state['hisPageSize'] : '';
  const hisPage = location.state ? location.state['hisPage'] : '';
  const sevenCheck = location.state ? location.state['sevenCheck'] : '';
  const selectedName = location.state ? location.state['selectedName'] : '';
  const selectedAS = location.state ? location.state['selectedAS'] : '';
  const companySelectedList = location.state ? location.state['companySelectedList'] : '';
  const branchCode = location.state ? location.state['branchCode'] : '';
  const companyCode = location.state ? location.state['companyCode'] : '';
  const selectedAge = location.state ? location.state['selectedAge'] : '';
  const selectedResult = location.state ? location.state['selectedResult'] : '';
  const selectedContent = location.state ? location.state['selectedContent'] : '';
  const selImplementStatus = location.state ? location.state['selImplementStatus'] : '';
  const selAppraiseStatus = location.state ? location.state['selAppraiseStatus'] : '';
  const selImplementAccount = location.state ? location.state['selImplementAccount'] : '';
  const branchCodeList = location.state ? location.state['branchCodeList'] : '';
  const backClick = () => {
    console.log('result', hisPageSize);
    if (backStatus) {
      navigate('../history:' + curId, {
        state: {
          pageSize: hisPageSize,
          page: hisPage,
          sevenCheck: sevenCheck,
          selectedName: selectedName,
          selectedAS: selectedAS,
          companySelectedList: companySelectedList,
          companyCode: companyCode,
          branchCode: branchCode,
          selectedAge: selectedAge,
          selectedResult: selectedResult,
          selectedContent: selectedContent,
          selImplementStatus: selImplementStatus,
          selAppraiseStatus: selAppraiseStatus,
          selImplementAccount: selImplementAccount,
          branchCodeList: branchCodeList,
          historyStatus: historyStatus
        }
      });
    }
  };
  return (
    <div className="root-1">
      <Box>
        {backStatus && (
          <Button className={classes.saveBtn} style={{ marginRight: '10px' }} onClick={backClick} variant="contained">
            戻る
          </Button>
        )}
        <Button
          className={[classes.saveBtn, !isUpdate ? classes.disableBtn : ''].join(' ')}
          onClick={() => {
            if (isUpdate) {
              handleSubmit();
            }
          }}
          variant="contained"
          color="primary"
        >
          保存
        </Button>
        <Grid container item xs={12} spacing={2} className={classes.detailGMargin}>
          <Grid item container xs={2} className={classes.roleSpanLabel}>
            <Grid item xs={10}>
              <span>結果</span>
            </Grid>
            <Grid item xs={2}>
              <span>:</span>
            </Grid>
          </Grid>
          <Grid container item xs={10} spacing={1}>
            <RadioGroup
              row
              aria-label="result"
              name="result"
              value={result}
              onChange={handleResultClick}
              style={{ marginLeft: '12px' }}
            >
              {R0.map((v, i) => {
                return (
                  <FormControlLabel
                    key={i}
                    value={v}
                    control={<Radio color="default" />}
                    label={
                      <span className={[classes.radioMargin, result === v ? classes.fontWeight : ''].join(' ')}>
                        {v}
                      </span>
                    }
                  />
                );
              })}
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid container xs={12} spacing={2} className={classes.detailGMargin}>
          <ContractedProduct />
        </Grid>
        <Grid container item xs={12} spacing={2} className={classes.detailGMargin}>
          <Grid item container xs={2} className={classes.roleSpanLabel}>
            <Grid item xs={10}>
              <span>サポート機能の評価</span>
            </Grid>
            <Grid item xs={2}>
              <span>:</span>
            </Grid>
          </Grid>
          <Grid container item xs={10} spacing={1}>
            <RadioGroup
              row
              aria-label="content"
              name="content"
              value={content}
              onChange={handleContentClick}
              style={{ marginLeft: '12px' }}
            >
              {R.map((v, i) => {
                return (
                  <FormControlLabel
                    key={i}
                    value={v}
                    control={<Radio color="default" />}
                    label={
                      <span className={[classes.radioMargin, content === v ? classes.fontWeight : ''].join(' ')}>
                        {v}
                      </span>
                    }
                  />
                );
              })}
            </RadioGroup>
            <Box className={classes.note}>
              <TextField
                multiline
                rows={6}
                value={notes}
                onChange={handleNotesChange}
                placeholder="よろしければ、その理由を入力ください"
                InputProps={{
                  disableUnderline: true
                }}
                style={{ width: '100%' }}
              />
            </Box>
          </Grid>
        </Grid>
        {!isAF && (
          <Grid container item xs={12} spacing={2} className={classes.detailGMargin}>
            <Grid item container xs={2} className={classes.roleSpanLabel}>
              <Grid item xs={10}>
                <span>公開/非公開</span>
              </Grid>
              <Grid item xs={2}>
                <span>:</span>
              </Grid>
            </Grid>
            <Grid container item xs={10} spacing={1}>
              <RadioGroup
                row
                aria-label="isPublic"
                name="isPublic"
                value={isPublic}
                onChange={handlePublicClick}
                style={{ marginLeft: '12px' }}
              >
                <FormControlLabel
                  key={'0'}
                  value={true}
                  control={<Radio color="default" />}
                  label={<span className={`radio-left ${isPublic ? 'fw-700' : ''}`}>公開</span>}
                />
                <FormControlLabel
                  key={'1'}
                  value={false}
                  control={<Radio color="default" />}
                  label={<span className={`radio-left ${!isPublic ? 'fw-700' : ''}`}>非公開</span>}
                />
              </RadioGroup>
            </Grid>
          </Grid>
        )}
      </Box>
      <ConfirmDialog title="入力内容を保存しますか？" open={submitOpen} setOpen={setSubmitOpen} onSubmit={onSubmit} />
    </div>
  );
};

export default Result;
