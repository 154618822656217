import React, { useEffect } from 'react';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import eventBus from '../EventBus';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const SnackBar = () => {
  const providerRef = React.useRef();

  useEffect(() => {
    eventBus.on('show_snack', (data) => {
      providerRef.current.enqueueSnackbar(data.message, {
        variant: data.type,
        autoHideDuration: 5000,
        preventDuplicate: true,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        TransitionComponent: Slide
      });
    });

    eventBus.on('close_snack', () => {
      providerRef.current.closeSnackbar();
    });
  }, []);

  return (
    <SnackbarProvider
      ref={providerRef}
      maxSnack={4}
      action={(key) => (
        <IconButton
          aria-label="close notification"
          color="inherit"
          onClick={() => {
            providerRef.current.closeSnackbar();
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    ></SnackbarProvider>
  );
};

export default SnackBar;
