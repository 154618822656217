import React from 'react';
import { Box, Divider, Typography, Switch } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MaxSvg from './images/max.svg';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '30px'
    },
    maxIcon: {
      cursor: 'pointer'
    },
    maxIconP: {
      marginLeft: '20px'
    },
    maxIconWithout: {
      marginRight: '15px'
    }
  })
);

const DetailTitle: React.FC<DetailTitleProps> = (props: DetailTitleProps): JSX.Element => {
  const classes = useStyles();
  const { text, Max, FnMax, connect, setCon } = props;

  return (
    <Box>
      <Typography className={classes.title}>
        <span>{text && text}</span>

        <span >
          {setCon && <span>
            <span style={{ fontSize: '10px', marginRight: '20px' }}>別ウィンドウ反映</span><Switch
              checked={connect}
              onChange={(e) => setCon && setCon(e.target.checked)}
              color="primary"
            />
          </span>}
          <span className={[classes.maxIconP, Max ? classes.maxIcon : classes.maxIconWithout].join(' ')}
            onClick={() => Max ? FnMax && FnMax() : null}>
            {Max && <img
              alt=''
              src={MaxSvg}
            />}
          </span>
        </span>
      </Typography>
      <Divider />
    </Box>
  );
};

interface DetailTitleProps {
  text?: string;
  Max?: boolean;
  FnMax?: () => void;
  connect?: boolean,
  setCon?: any;
}

export default DetailTitle;
