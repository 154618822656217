import React from 'react';
import { Grid } from '@material-ui/core';
import { Outlet, useLocation } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Menu from '../../views/menu/index';
import HomeTitle from '../../components/HomeTitle';
import MenuTitleMap from '../../config/menuTitle.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      // height: '100%',
      // overflow: 'hidden',
      width: '100%',
      minHeight: '960px'
      // minWidth: '-moz-max-content'
    },
    wrapper: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden'
      // paddingTop: 64
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden'
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto',
      display: 'flex'
    },
    contentMain: {
      backgroundColor: '#fff',
      fontSize: '16px',
      padding: '10px 25px',
      paddingRight: '16px',
      overflowY: 'auto',
      height: '100%',
      width: '100%'
    }
  })
);

const MainLayout: React.FC = (): JSX.Element => {

  const classes = useStyles();
  const location = useLocation();
  let mTitle = location.pathname.split('/app')[1]?.slice(1).split(':')[0];
  let maTitle = location.pathname.split('/management')[1]?.slice(1).split(':')[0];
  const t = mTitle || maTitle ? MenuTitleMap[mTitle || maTitle] : '';
  const disLayout = ['結果の入力', 'お客様対応画面']

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {disLayout.indexOf(t) === -1 ? <Menu /> : ''}
            <div className={classes.contentMain}>
              <Grid>
                <Grid>
                  <HomeTitle data={t} />
                </Grid>
                <Grid>
                  <Outlet />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
