import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, styled } from '@material-ui/core/styles';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import withWrap from '../../withWrap';

const useStyles = makeStyles(() => {
  return createStyles({
    ok: {
      color: '#01A5D9FF'
    },
    tag: {
      height: 'auto',
      padding: '0 7px',
      margin: '0 8px 0 0',
      borderRadius: '4px',
      marginBottom: '8px',
      whiteSpace: 'nowrap',
      display: 'inline-block',
      boxSizing: 'border-box',
      border: '1px solid #E3E3E3FF',
      color: '#676C6DFF',
      background: '#F5F5F5FF',
      borderColor: '#E3E3E3FF'
    },
    tagChecked: {
      color: '#676C6DFF',
      background: '#E9FAFFFF',
      borderColor: '#6ECCEBFF',
      border: '1px solid #6ECCEBFF'
    },
    dataTag: {
      height: 'auto',
      margin: '0 8px 0 0',
      borderRadius: '4px',
      marginBottom: '8px',
      whiteSpace: 'nowrap',
      display: 'inline-block',
      boxSizing: 'border-box',
      border: '1px solid #6ECCEBFF',
      color: '#676C6DFF',
      background: '#E9FAFFFF',
      borderColor: '#6ECCEBFF'
    },
    dataTagTitle: {
      height: '100%',
      paddingLeft: '7px',
      paddingRight: '7px',
      borderRadius: '4px',
      display: 'inline-block'
    },
    dataTagValue: {
      height: '100%',
      paddingLeft: '7px',
      paddingRight: '7px',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      display: 'inline-block',
      background: '#FFFFFFFF',
      borderLeft: '1px solid #6ECCEBFF'
    }
  });
});

const HeadCell = styled(TableCell)({
  padding: '8px',
  height: '40px',
  fontWeight: 600,
  textAlign: 'center',
  backgroundColor: '#F2F2F2FF',
  border: '1px solid #D9D9D9FF'
});

const RowCell = styled(TableCell)({
  padding: '8px',
  height: '40px',
  textAlign: 'center',
  backgroundColor: '#FFFFFFFF',
  border: '1px solid #D9D9D9FF',
  whiteSpace: 'pre-wrap'
});

const TableGrid: React.FC<TableGridProps> = (props): JSX.Element => {
  const { NGWordData, CheckedNGWordData } = props;
  const checkedNGReturn = JSON.stringify(CheckedNGWordData) != "{}" ? true : false
  const classes = useStyles();
  const [talk, setTalk] = useState('00:00:00');
  const [listen, setListen] = useState('00:00:00');
  const [totalTime, setTotalTime] = useState('00:00:00');
  const [talkListenRate, setTalkListenRate] = useState(0);
  const [talkSpeed, setTalkSpeed] = useState(0);
  const [listenSpeed, setListenSpeed] = useState(0);
  const [silenceTimes, setSilenceTimes] = useState(0);
  const [interruptTimes, setInterruptTimes] = useState(0);
  const [lowerConversationTimes, setLowerConversationTimes] = useState(0);

  useEffect(() => {
    if (JSON.stringify(props?.data) != "{}") {
      const {
        talkTime,
        listenTime,
        totalTime,
        talkListenRate,
        talkSpeed,
        listenSpeed,
        silenceTimes,
        interruptTimes,
        lowerConversationTimes
      } = props?.data;
      setTalk(talkTime);
      setListen(listenTime);
      setTotalTime(totalTime);
      setTalkListenRate(talkListenRate * 100);
      setTalkSpeed(talkSpeed);
      setListenSpeed(listenSpeed);
      setSilenceTimes(silenceTimes);
      setInterruptTimes(interruptTimes);
      setLowerConversationTimes(lowerConversationTimes);
    }
  }, [props?.data]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Grid container item xs={12}>
        <Grid container item direction="column" xs={6} style={{ paddingRight: 10 }}>
          <Grid item>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeadCell>Talk</HeadCell>
                    <HeadCell>Listen</HeadCell>
                    <HeadCell>合計</HeadCell>
                    <HeadCell>Talk/Listen率</HeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <RowCell>{talk}</RowCell>
                    <RowCell>{listen}</RowCell>
                    <RowCell>{totalTime}</RowCell>
                    <RowCell>{talkListenRate.toFixed(2)}%</RowCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeadCell colSpan={4} style={{ borderTop: 'none' }}>
                      発話速度
                    </HeadCell>
                  </TableRow>
                  <TableRow>
                    <HeadCell colSpan={2}>募集人</HeadCell>
                    <HeadCell colSpan={2}>お客様</HeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <RowCell colSpan={2}>{`${Number(talkSpeed).toFixed(1)}文字/秒`} ​{'\n'} {`(お客様より${Number(listenSpeed) ? Math.round((talkSpeed - listenSpeed) / listenSpeed * 100) : 100}%速い)`}</RowCell>
                    <RowCell colSpan={2}>{Number(listenSpeed).toFixed(1)}文字/秒</RowCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeadCell style={{ borderTop: 'none' }}>被り回数</HeadCell>
                    <HeadCell style={{ borderTop: 'none' }}>沈黙回数</HeadCell>
                    <HeadCell style={{ borderTop: 'none' }}>ラリー回数</HeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <RowCell>{interruptTimes}回</RowCell>
                    <RowCell>{silenceTimes}回</RowCell>
                    <RowCell>{lowerConversationTimes}回</RowCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container item direction="column" xs={6} style={{ paddingLeft: 10 }}>
          <Grid item>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeadCell>質問回数</HeadCell>
                    <HeadCell>共感回数</HeadCell>
                    <HeadCell>クッション言葉</HeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <RowCell>{checkedNGReturn ? CheckedNGWordData.doubt : 0} 回</RowCell>
                    <RowCell>{checkedNGReturn ? CheckedNGWordData.resonance : 0} 回</RowCell>
                    <RowCell>
                      <b>{checkedNGReturn ? CheckedNGWordData.cushion : 'NG'}</b>
                    </RowCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <HeadCell style={{ borderTop: 'none', width: '50%' }}>口グセ</HeadCell>
                    <HeadCell style={{ borderTop: 'none', width: '50%' }}>NGワード</HeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow style={{ height: 162 }}>
                    <RowCell>
                      {CheckedNGWordData?.gutter?.map((item, index) => {
                        return (
                          <div className={classes.dataTag} key={index}>
                            <div className={classes.dataTagTitle}>{item.label}</div>
                            <div className={classes.dataTagValue}>{item.total}回</div>
                          </div>
                        );
                      })}
                    </RowCell>
                    <RowCell>
                      {NGWordData.map((item, index) => {
                        return <div key={index} className={[classes.tag, CheckedNGWordData.ngWord?.indexOf(item.label) > -1 ? classes.tagChecked : ''].join(' ')}>{item.label}</div>;
                      })}
                    </RowCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

interface TableGridProps {
  data?: any;
  mouthHabitData?: any;
  NGWordData?: any;
  CheckedNGWordData?: any;
}

export default withWrap<TableGridProps>({
  styled: false
})(TableGrid);
