import {setUserInfo } from '../features/user/userSlice';
import { getCurUserInfo, updateCurUserInfo, getAgentInfo } from '../api/userApi';
import { getBranchsByAgentCode } from '../api/history';
import EventBus from './../components/EventBus';
import { deleteCookie } from './General'

const roleNeedPostList = ['AF_Admin', 'AF_I3NBA', 'AF_I3NBAacs', 'AF_I3NBAscc']
const RUN_ENV = process.env.REACT_APP_RUN_ENV

export async function initUserMeInfo(navigate, dispatch){
    await getCurUserInfo()
    .then(function (response) {
      if (response.status === 200) {
        let Gresponse = response?.data;
        const { account, role, agentCode } = response?.data
        if (roleNeedPostList.indexOf(role) > -1) {   // AF
          updateCurUserInfo({ name: account })
          dispatch(setUserInfo(Gresponse));
          navigate('../app/history');
        } else {     // agent
          getAgentInfo({ name: account })
            .then(function (response) {
              const dataFlg = !(JSON.stringify(response?.data) === '{}');
              if(dataFlg){
                const { salsmanSeiKj, salsmanMeiKj } = response?.data
                Gresponse['name'] = salsmanSeiKj + salsmanMeiKj
                updateCurUserInfo({ name: salsmanSeiKj + salsmanMeiKj })
                  .then(function () {
                    getBranchsByAgentCode({ id: agentCode })
                      .then(function (response) {
                        Gresponse['agentName'] = response?.data?.agstInf[0]?.agstNmeK
                        dispatch(setUserInfo(Gresponse));
                        navigate('../app/history');

                      })
                      .catch(function (error) {

                      })
                  })
              }else{
                EventBus.dispatch('show_snack', { message: 'ユーザーが見つかりませんでした.', type: 'error' });
                deleteCookie('aanet-sess-mvp4');         // delete aanet cookie
                if (RUN_ENV === 'stage') {
                  window.open("https://st-aflac.platformerfuji.com/apigw/va2nba/", "_self");
                } else if (RUN_ENV === 'prod') {
                  window.open("https://aflac.platformerfuji.com/apigw/va2nba/", "_self");
                }
                else {
                  // do nothing
                }
              }

            })
            .catch(function (error) {

            })
        }
        // document.cookie = "aanet-sess-mvp4" + "=123456; path=/";
        // document.cookie = "aanet-sess-mvp4" + "=12ssww2; path=/apigw/va2nba/;";
      }
    })
    .catch(function (error) { });
}
