import IdleTimer from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';

const idleTimerState: { idleTimer: any; } = {
  idleTimer: undefined
}

export function sessionTimeoutReset() {
  console.log('sessionTimeoutReset');
  idleTimerState.idleTimer?.reset();
};

export default function SessionTimeout() {
  //   const idleTimer = useRef(null);
  const sessionTime = localStorage.getItem('isTimeout') ? 1000 * 60 * 2 : 1000 * 60 * 30;
  const navigate = useNavigate();

  const onActive = () => {
    console.log('active');
    // timer reset automatically.
  };

  const onIdle = () => {
    navigate('../logout', {
      state: {
        logoutStatus: 'sessionOut'
      }
    });
  };
  return (
    <>
      <IdleTimer
        ref={ref => { idleTimerState.idleTimer = ref }}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={sessionTime}
      />
    </>
  );
}
