import { combineReducers, createStore, applyMiddleware, } from 'redux';
import storage from 'redux-persist/lib/storage';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import userReducer from './features/user/userSlice';
import globalReducer from './features/global/globalSlice';
import annotationReducer from './features/annotation/annotationSlice';

const persistConfig = {
  storage,
  key: 'root'
};

const reducers = combineReducers({
  user: userReducer,
  global: globalReducer,
  annotation: annotationReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: [
//     ...getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
//       }
//     })
//   ]
// });
const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
]
const store = createStore(persistedReducer, applyMiddleware(...middleware));
export default (() => {
  let persistor = persistStore(store);
  return { store, persistor };
})();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
