import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Button, FormControl, Grid, Paper, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import AflacBg from './images/Aflac.png';
import Bg from './images/kosta-bratsos-lBPgzz3HGIo-unsplash.jpg';
import './css/index.model.css';
import { setAanetUser, setAanetGroup } from '../../features/user/userSlice';
import { initUserMeInfo } from '../../utils/initUser';
const RUN_ENV = process.env.REACT_APP_RUN_ENV;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      position: 'relative',
      minHeight: '100%',
      height: '100%',
      // backgroundColor: var+'(--white-lilac)',
      minWidth: '100%',
      background: 'url(' + Bg + ') no-repeat top center',
      flexDirection: 'column',
      backgroundSize: 'cover',
      '&::before': {
        content: "''",
        background: 'rgba(0, 165, 217, 0.8)',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
        zIndex: 0
      }
    },
    rootLoading: {
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    loading: {
      margin: '0 auto',
      top: '50%'
    },
    main: {
      position: 'absolute',
      padding: '40px 48px',
      textAlign: 'center',
      verticalAlign: 'center',
      border: '1px solid #ced4da',
      borderRadius: '10px',
      height: 'auto',
      width: '520px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#FFFFFF',
      maxHeight: 'calc(100vh - 50px)'
    },
    info: {
      margin: theme.spacing(3),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2)
    },
    title: {
      padding: '16px 16px 24px',
      margin: 0,
      textAlign: 'center',
      borderBottom: '1px solid #f5f5f5',
      lineHeight: '28px',
      fontSize: '18px'
    },
    margin: {
      margin: theme.spacing(1),
      width: '100%',
      fontSize: '16px'
    },
    inputLabel: {
      fontSize: '16px',
      color: '#808080',
      textAlign: 'left'
    },
    inputS: {
      width: '100%',
      padding: '16px 22px',
      border: '1px solid #808080',
      borderRadius: '6px',
      backgroundColor: 'inherit',
      marginleft: `0px !important`,
      minHeight: '64px',
      fontSize: '18px',
      fontFamily: 'RobotoRegular',
      marginTop: '8px',
      '&:focus': {
        borderWidth: '1px',
        outlineColor: '#1890ff'
      }
    },
    loginBtn: {
      width: '100%',
      background: '#00a5d9',
      border: '2px solid #037599',
      boxSizing: 'border-box',
      borderRadius: '12px',
      padding: '8px',
      minHeight: '64px',
      fontWeight: 700,
      marginTop: '10px',
      '&:hover': {
        background: '#00a5d9'
      }
    },
    selectStyle: {
      width: '90%',
      border: '1px solid #ccc',
      borderRadius: '5px'
    }
  })
);

const Login = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const dispatch = useDispatch();
  // const { t } = useTranslation();
  const [username, setUsername] = React.useState('');
  // const [password, setPassword] = React.useState('');
  const [role, setRole] = React.useState('');

  useEffect(() => {
    if (RUN_ENV !== 'dev') {
      initUserMeInfo(navigate, dispatch);
    }
  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    dispatch(setAanetUser(username));
    dispatch(setAanetGroup(role));
    initUserMeInfo(navigate, dispatch);
  };
  return RUN_ENV === 'dev' ? (
    <>
      <Paper className={classes.root}>
        <Box className={classes.main}>
          <Box className={classes.info}>
            <img src={AflacBg} alt="Aflac BG" style={{ height: '50px' }} />
            <h1 className={classes.title}>営業サポートAI （パイロット版）へようこそ。ログインしてください。</h1>
          </Box>
          <Box>
            <Grid>
              <FormControl className={classes.margin}>
                <h5 className={classes.inputLabel}>従業員ID*</h5>
                <input
                  placeholder="従業員IDを入力してください"
                  id="username"
                  onChange={(e) => setUsername(e.target.value)}
                  className={classes.inputS}
                />
              </FormControl>
            </Grid>
            <Grid>
              <FormControl className={classes.margin}>
                <h5 className={classes.inputLabel}>ユーザーロール*</h5>
                <input
                  placeholder="ロールを入力する"
                  id="role"
                  onChange={(e) => setRole(e.target.value)}
                  className={classes.inputS}
                />
              </FormControl>
            </Grid>
            <Grid>
              <FormControl className={classes.margin}>
                <Button variant="contained" color="primary" className={classes.loginBtn} onClick={handleSubmit}>
                  ログイン
                </Button>
              </FormControl>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </>
  ) : (
    <Paper className={classes.rootLoading}>
      <CircularProgress className={classes.loading} />
    </Paper>
  );
};

export default Login;
