import axios from 'axios'; // import axios
import EventBus from './../components/EventBus';
import store from '../store';

const Store = store.store;
const RUN_ENV = process.env.REACT_APP_RUN_ENV;
const StBase = 'https://st-aflac.platformerfuji.com/apigw/va2nba/';
const ProBase = 'https://aflac.platformerfuji.com/apigw/va2nba/';
const service = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 8000
  // headers: { 'Content-Type': 'application/json' },
});

service.interceptors.request.use((config) => {
  const { aanetUser, aanetGroup } = Store.getState().user;
  config.headers['Cache-Control'] = 'no-store, no-cache';
  config.headers['Accept'] = 'application/json, text/plain, */*';
  // config.headers['Authorization'] = 'Bearer ' + userInfo['token'];

  if (config.requestBase !== 'bot' && 'prod' !== RUN_ENV && 'stage' !== RUN_ENV) {
    config.headers['x-aanet-user'] = aanetUser;
    config.headers['x-aanet-group'] = aanetGroup;
  }

  config.params = config.params || {};
  if (config.requestBase === 'proxy') {
    config.baseURL = process.env.REACT_APP_API_MSTDB_URL;
  }
  if (config.requestBase === 'bot') {
    config.baseURL = process.env.REACT_APP_API_BOT_URL;
  }
  if (config.requestBase === 'pdf') {
    config.responseType = 'blob';
    config.timeout = 8000 * 5;
  }
  if (config.requestBase === 'longTimeOut') {
    config.timeout = 1000 * 60;
  }
  if (config.requestBase === 'logout') {
    if ('prod' === RUN_ENV) {
      config.baseURL = ProBase;
    } else {
      config.baseURL = StBase;
    }
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }
  // config.params['timestamp'] = `${new Date().getTime()}`;
  return config;
});

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log('error in service', error);
    if (error?.response?.status === 401) {
      // EventBus.dispatch('show_snack', { message: 'トークンの有効期限が切れました', type: 'error' });
      EventBus.dispatch('show_snack', { message: '操作する許可がありません', type: 'error' });
      if (RUN_ENV != 'dev') {
        return (window.location.href = '/apigw/va2nba' + '/logout');
      }
      // store.dispatch({ type: 'ACCESS_TOKEN', access_token: '' });
      // store.dispatch({ type: 'USERNAME', username: '' });
      // store.dispatch({ type: 'AGENT_COMPANY', agent_company: {} });
      // return (window.location.href = '/'); // login
    } else {
      console.log('error in service');
    }
    return Promise.reject(error);
  }
);

export default service;
