import React, { ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Slider, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    paddingRight: '30px',
    paddingLeft: '15px',
    bottom: 0,
    position: 'absolute'
  }
});

const marks = [
  { value: 0, label: '12' },
  // { value: 2, label: '13', },
  // { value: 5, label: '14', },
  // { value: 8, label: '15', },
  { value: 11, label: '16' },
  { value: 33, label: '24' },
  { value: 66, label: '36' },
  { value: 100, label: '48' }
];

const valuetext = (value: number): string => {
  return `${ value }`;
};

const valueLabelFormat = (value: number): string | undefined => {
  let i = marks.findIndex((mark) => mark.value === value);
  return marks[ i ]?.label;
};

const DiscreteSlider: React.FC<DiscreteSliderProps> = (props: DiscreteSliderProps): JSX.Element => {

  const { fn } = props;
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    let i = marks.findIndex((mark) => mark.value === newValue);
    fn && fn(Number(marks[ i ]?.label));
  };

  return (
    <div className={ classes.root }>
      <Typography id='discrete-slider-restrict' gutterBottom style={ {} }>
        フォントサイズ
      </Typography>
      <Divider/>
      <Slider
        step={ null }
        marks={ marks }
        defaultValue={ 11 }
        getAriaValueText={ valuetext }
        onChange={ (event: ChangeEvent<{}>, value: number | number[]) => handleChange(event, value) }
        valueLabelFormat={ valueLabelFormat }
        valueLabelDisplay='auto'
        aria-labelledby='discrete-slider-restrict'
      />
    </div>
  );
};

interface DiscreteSliderProps {
  fn?: (size: number) => void;
}

export default DiscreteSlider;
