import React, { memo, ReactNode } from 'react';
import { useMediaQuery, Dialog } from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import useTheme from '@material-ui/core/styles/useTheme';

interface ResponsiveDialogProps {
  open: boolean;
  onClose: () => void;
  maxWidth?: DialogProps['maxWidth'];
  children: ReactNode;
}

const ResponsiveDialog = (props: ResponsiveDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth={props.maxWidth} fullScreen={fullScreen}>
      {props.children}
    </Dialog>
  );
};

export default memo(ResponsiveDialog);
