import { useState, useEffect } from 'react';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Box, Grid, Modal, TextField, List, ListItem, ListItemText, Button } from '@material-ui/core';
import ModalHeadButton from './ModalHeadButton';
import DoneIcon from '@material-ui/icons/Done';
import { getProducts } from '../../../../api/annotationApi';

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    color: '#FFFFFF',
    backgroundColor: '#21c79a',
    '&:hover': {
      backgroundColor: '#21c79a'
    }
  }
}))(Button);

const MuiListItem = withStyles({
  root: {
    '&$selected': {
      backgroundColor: '#3187f750'
    },
    '&$selected:hover': {
      backgroundColor: '#3187f750'
    },
    '&:hover': {
      backgroundColor: '#3187f725'
    }
  },
  selected: {}
})(ListItem);

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '3px',
    padding: '0px 0px 24px 24px',
    width: '90%'
  },
  recommendBox: {
    marginTop: '16px',
    border: '1px solid hsla(0,0%,49%,.31)',
    minHeight: 'calc(100% - 40px)',
    maxHeight: '36vh',
    overflowY: 'auto',
    '&.MuiList-padding': {
      paddingTop: 'unset',
      paddingBottom: 'unset'
    }
  },
  recommendLabel: {
    width: '100%',
    lineHeight: '32px',
    padding: '8px',
    boxSizing: 'border-box',
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  recommendDetail: {
    width: '100%',
    height: '100%',
    border: '1px solid #7d7d7d75',
    padding: '1rem',
    whiteSpace: 'pre-line'
  },
  button: {
    width: '140px',
    height: '40px',
    boxShadow: '2px 2px 5px rgb(0 0 0 / 30%)',
    borderRadius: '3px',
    marginRight: '12px'
  },
  listItemSelected: {
    backgroundColor: 'red'
  }
}));

const SearchKeywordModal = (props) => {
  const { searchOpen, setSearchOpen, modalSubmit, hasAnnotations } = props;
  const classes = useStyles();

  const [products, setProducts] = useState([]);

  const [productId, setProductId] = useState('');
  const [description, setDescription] = useState('');
  const [summary, setSummary] = useState('');

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClose = () => {
    setSearchOpen(false);
    setDescription('');
  };

  const handleListItemClick = (id, summary, description, selectedIndex) => {
    setProductId(id);
    setSummary(summary);
    setDescription(description);
    setSelectedIndex(selectedIndex);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      getformData(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (productId) {
      modalSubmit(productId, summary);
      setSearchOpen(false);
      setDescription('');
    } else {
      //"追加するレコメンドを選択してください。"
    }
  };

  function getformData(productName?) {
    getProducts({
      keywords: productName
    })
      .then(function (response) {
        if (response.status === 200) {
          const summaries = response.data?.products.filter((r) => {
            let canSet = true;
            hasAnnotations.map((h) => {
              canSet = !canSet || r.summary === h ? false : true;
            });
            return canSet;
          });
          setProducts(summaries);
          setDescription('');
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    getformData();
  }, [searchOpen]); //eslint-disable-line react-hooks/exhaustive-deps

  const body = (
    <div className={classes.paper}>
      <ModalHeadButton id="customized-dialog-title" onClose={handleClose} />
      <Grid container spacing={3} xs>
        <Grid item xs={6}>
          <div>
            <TextField
              size="small"
              id="outlined-search"
              label="検索キーワードを入力しEnter"
              type="search"
              variant="outlined"
              placeholder="検索キーワードを入力しEnter"
              style={{ width: '100%' }}
              onKeyDown={(e) => keyPress(e)}
            />
          </div>
          <List className={classes.recommendBox}>
            {products?.map((product: any, i) => (
              <MuiListItem
                button
                selected={i === selectedIndex}
                className={classes.recommendLabel}
                divider={i < products.length - 1}
                key={product?.id}
                onClick={(event) => handleListItemClick(product?.id, product?.summary, product?.description, i)}
              >
                <ListItemText primary={product?.summary} />
              </MuiListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.recommendDetail}>{description}</Box>
        </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="flex-start">
          <ColorButton variant="contained" className={classes.button} startIcon={<DoneIcon />} onClick={handleSubmit}>
            追加
          </ColorButton>
        </Grid>
      </Grid>
    </div>
  );
  return (
    <div>
      <Modal className={classes.modal} open={searchOpen} onClose={handleClose} disableAutoFocus>
        {body}
      </Modal>
    </div>
  );
};

export default SearchKeywordModal;
