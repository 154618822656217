import React from 'react';
import { pdfjs } from 'react-pdf';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import Modal from '@material-ui/core/Modal';
import PrePDF from './PrePDF';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: '80%',
      height: '80%',
      backgroundColor: theme.palette.background.paper,
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      paddingTop: 25,
      paddingLeft: 25,
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`
    }
  })
);

const PreviewPdf = (props) => {
  const classes = useStyles();
  const showNums = props.url?.description?.split(';')[0]
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.paper}>
          <span style={{ position: 'absolute', right: 5, top: 2, cursor: 'pointer' }} onClick={handleClose}>
            X
          </span>
          <PrePDF pre={showNums} name="pre" file={props.url?.url} handleOpen={() => { }}></PrePDF>
        </div>
      </Modal>
      <PrePDF pre={showNums} name="pre" file={props.url?.url} handleOpen={() => handleOpen} ></PrePDF>
    </>
  );
};

export default React.memo(PreviewPdf);
