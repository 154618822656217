import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { createStyles, makeStyles, styled, Theme } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  InputLabel,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Typography,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  Switch,
  Button
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  searchConversation,
  getProductContractsList,
  updateIspublic,
  deleteItem,
  getBranchsByAgentCode,
  getImplementer,
  exportScvFile,
  getRecruitersByAgentcode
} from '../../api/history';
import { getAgentsList } from '../../api/readyBefore';
import TablePaginationActions from '../../components/TablePaginationActions';
import NativeSelectWrap from '../../components/NativeSelectWrap';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../src/store';
import WechatSvg from './images/wechat.svg';
import { getTrainsDetail } from '../../api/annotationApi';
import { setTrainId } from '../../features/annotation/annotationSlice';
import ConfirmDialog from '../../components/management/ConfirmDialog';
import AnnotatedModal from '../management/annotationManagement/components/AnnotatedModal';
import { getTrainsStatus } from '../../api/annotationApi';
import { openCsvExportString } from '../../utils/ExportUtil';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectCont: {
      maxWidth: 'inherit'
    },
    inputLabel: {
      textAlign: 'left',
      marginLeft: '9px',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '16px',
      minHeight: '32px',
      whiteSpace: 'pre'
    },
    underline: {
      content: ''
    },
    selectStyle: {
      width: '90%',
      border: '1px solid #ccc',
      borderRadius: '5px'
    },
    tableWrap: {
      alignItems: 'center',
      marginTop: '30px',
      overflowX: 'scroll'
    },
    table: {
      width: '100%'
    },
    tableRow: {
      lineHeight: '18px'
    },
    startBtn: {
      backgroundColor: '#00a5d9'
    },
    circleMark: {
      margin: 'auto',
      width: '15px',
      height: '15px',
      borderRadius: '20px',
      background: 'black'
    },
    asCodeTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    rowSelect: {
      cursor: 'pointer',
      marginTop: '-20px',
      marginRight: '5px',
      fontSize: '10px'
    },
    rowSelectSpan: {
      border: '2px solid #00a5d9',
      padding: '1px 10px',
      backgroundColor: '#00a5d9',
      color: '#fff'
    },
    rowSelected: {
      border: '2px solid #00a5d9',
      backgroundColor: '#fff',
      color: '#00a5d9'
    },
    dFlex: {
      // display: `flex !important`,
    },
    mr2: {
      marginRight: '15px'
    },
    wechatImg: {
      width: '24px',
      cursor: 'pointer'
    },
    labelBreak: {
      textAlign: 'left',
      marginLeft: '9px',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '16px',
      minHeight: '32px'
      // whiteSpace: 'pre'
    }
  })
);

const HeadCell = styled(TableCell)({
  border: '1px solid #bbb',
  backgroundColor: '#eee',
  padding: '8px',
  fontWeight: 600,
  minWidth: '60px'
});

const EmptyCell = styled(TableCell)({
  border: '0px solid #fff',
  padding: '8px'
});

const RowCell = styled(TableCell)({
  border: '1px solid #ddd',
  padding: '8px'
});

const History = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const curUserInfo = useSelector((state: RootState) => state.user.userInfo);
  const isAdmin = curUserInfo['role'] === 'AF_Admin';
  // cur select
  const [selectedAS, setSelectedAS] = useState(location.state ? location.state['selectedAS'] : '');
  const [selectedName, setSelectedName] = useState(location.state ? location.state['selectedName'] : '');
  const [selectedAge, setSelectedAge] = useState(location.state ? location.state['selectedAge'] : '');
  const [selectedContent, setSelectedContent] = useState(location.state ? location.state['selectedContent'] : '');
  const [selectedResult, setSelectedResult] = useState(location.state ? location.state['selectedResult'] : '');
  const [selImplementStatus, setSelImplementStatus] = useState(
    location.state ? location.state['selImplementStatus'] : ''
  );
  const [selAppraiseStatus, setSelAppraiseStatus] = useState(location.state ? location.state['selAppraiseStatus'] : '');

  const [implementStatus, setImplementStatus] = useState('未');
  const [appraiseStatus, setAppraiseStatus] = useState('未');
  // cur select list
  const [selectedNameList, setSelectedNameList] = useState([]);
  const [selectedNameListAll, setSelectedNameListAll] = useState([]);

  const [tableData, setTableData] = useState([]);
  // cur page
  const [page, setPage] = React.useState(location.state ? location.state['hisPage'] : 0);
  //
  const [rowsPerPage, setRowsPerPage] = React.useState(location.state ? location.state['hisPageSize'] : 10);
  // total size
  const [totalSize, setTotdalSize] = React.useState(0);
  // Contracts list
  const [contractsList, setContractsList] = React.useState([]);
  // other list
  const [othersList, setOthersList] = React.useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //
  const [id, setId] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [delState, setDelState] = React.useState(false);
  //
  const [sevenCheck, setSevenCheck] = useState(location.state ? location.state['sevenCheck'] : true);

  // mock data
  // const response = [{ "name": "アイビージェイ", "code": 2610441, "branches": [{ "id": 5, "code": "003", "name": "北海道支社" }] }, { "name": "トータルライフサービス", "code": 1905054, "branches": [{ "id": 6, "code": "024", "name": "コンサルティング営業部第一課" }] }, { "name": "井田ライフ保険", "code": 1400753, "branches": [{ "id": 7, "code": "001", "name": "本店" }, { "id": 9, "code": "003", "name": "前橋支店" }] }, { "name": "テストアソシエイツ１", "code": 9011910, "branches": [{ "id": 8, "code": "001", "name": "本店" }] }]
  // const comMap = response.map(item => { return { id: item['code'], name: item['code'] + '/' + item['name'], branches: item['branches'] } })

  const [companyCodeList, setCompanyCodeList] = useState<any>([]);
  const [branchCodeList, setBranchCodeList] = useState<any>(location.state ? location.state['branchCodeList'] : []);
  const [checkBoxAll, setCheckBoxAll] = useState(false);
  const [companyCode, setCompanyCode] = useState<any>(location.state ? location.state['companyCode'] : '');
  const [branchCode, setBranchCode] = useState<any>(location.state ? location.state['branchCode'] : '');
  const [companySelectedList, setCompanySelectedList] = React.useState<string[]>(
    location.state ? location.state['companySelectedList'] : []
  );
  const [companySelectedListAll, setCompanySelectedListAll] = React.useState<string[]>([]); // for seven ten code change

  const [annotatOpen, setAnnotatOpen] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [product, setProduct] = useState('');

  const [implementerList, setImplementerList] = useState([]);
  const [implementerListAll, setImplementerListAll] = useState([]);
  const [selImplementAccount, setSelImplementAccount] = useState(
    location.state ? location.state['selImplementAccount'] : ''
  );
  const [backStatus, setBackStatus] = useState(location.state ? location.state['backStatus'] : false);

  const [selectedAgentCd, setSelectedAgentCd] = React.useState<string[]>(
    location.state ? location.state['selectedAgentCd'] : []
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  window.onbeforeunload = function () {
    window.history.replaceState({}, document.title);
  };

  function updateStateFromDetail(agentCodeListLe) {
    if (location.state) {
      const ls = location.state;
      const agentCodeList = ls['sevenCheck'] ? ls['companySelectedList'] : ls['selectedAS'];
      setCompanySelectedList(agentCodeList);
      getTraineeForSelect(agentCodeList, 'init');
      getImplementers(agentCodeList, 'init');
      if (agentCodeListLe !== ls['companySelectedList'].length && ls['sevenCheck']) {
        setCheckBoxAll(false);
      }
    }
  }

  useEffect(() => {
    const agentCode = curUserInfo['agentCode'];
    const curRole = curUserInfo['role'];
    const roleList = ['AF_Admin', 'AF_I3NBA'];
    if (agentCode === '2000002') {
      if (roleList.indexOf(curRole) > -1) {
        getAgentsList()
          .then(function (response) {
            if (response.status === 200) {
              const agentCodeList = response.data?.map((item) => {
                return item.code;
              });
              let agentCodeListLe = agentCodeList.length;
              if (agentCodeList.indexOf('2000002') > -1) {
                setCompanyCodeList(
                  response.data?.map((item) => {
                    return { code: item.code, name: item.agentName };
                  })
                );
                setCompanySelectedList(agentCodeList);
                setCompanySelectedListAll(agentCodeList);
                getTraineeForSelect(agentCodeList, 'init');
                getImplementers(agentCodeList, 'init');
              } else {
                setCompanyCodeList(
                  //AS コード
                  [
                    {
                      code: '2000002',
                      name: 'アフラック生命保険株式会社'
                    }
                  ].concat(
                    response.data?.map((item) => {
                      return { code: item.code, name: item.agentName };
                    })
                  )
                );

                setCompanySelectedList(['2000002'].concat(agentCodeList));
                setCompanySelectedListAll(['2000002'].concat(agentCodeList));
                getTraineeForSelect(['2000002'].concat(agentCodeList), 'init');
                getImplementers(['2000002'].concat(agentCodeList), 'init');
                agentCodeListLe += 1;
              }
              setCheckBoxAll(true);
              updateStateFromDetail(agentCodeListLe);
            } else {
              console.log('code error');
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        //jira 221
        setCompanyCodeList([
          {
            code: '2000002',
            name: 'アフラック生命保険株式会社'
          }
        ]);
        setCompanySelectedList(['2000002']);
        setCompanySelectedListAll(['2000002']);
        getTraineeForSelect(['2000002'], 'init');
        getImplementers(['2000002'], 'init');
        setCheckBoxAll(true);
        updateStateFromDetail(1);
      }
    } else {
      getAgentsList()
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.length > 0) {
              setCompanyCodeList(
                //AS コード
                response.data?.map((item) => {
                  return { code: item.code, name: item.agentName };
                })
              );
              const agentCodeList = response.data?.map((item) => {
                return item.code;
              });
              setCompanySelectedList(agentCodeList); //AS コード selected
              setCompanySelectedListAll(agentCodeList);
              getTraineeForSelect(agentCodeList, 'init');
              getImplementers(agentCodeList, 'init');
              setCheckBoxAll(true);
              updateStateFromDetail(agentCodeList.length);
            } else {
              setCompanySelectedList([agentCode]); //AS コード selected
              setCompanySelectedListAll([agentCode]);
              getTraineeForSelect([agentCode], 'init');
              getImplementers([agentCode], 'init');
              setCheckBoxAll(true);
              updateStateFromDetail(1);
            }
          } else {
            console.log('code error');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    getProductContractsList()
      .then(function (response) {
        if (response.status === 200) {
          let contractsL = [] as any;
          let eContractsL = [] as any; //既契約
          response.data?.map((item: any, i) => {
            if (item?.type === '既契約商品') {
              eContractsL.push(item);
            } else {
              contractsL.push(item);
            }
          });
          setContractsList(eContractsL);
          setOthersList(contractsL);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let data = {};
    if (selectedName) {
      data['account'] = selectedName;
    }
    if (selectedAS || companySelectedList.length > 0) {
      if (selectedAS) {
        data['agentCode'] = [selectedAS];
      } else {
        if (backStatus && selectedAgentCd) {
          data['agentCode'] = selectedAgentCd;
        } else {
          data['agentCode'] = companySelectedList;
        }
      }
    }
    if (branchCode) {
      data['branchCode'] = branchCode;
    }
    if (selectedAge) {
      data['customerAgeFrom'] = Number(selectedAge.split('~')[0]);
      data['customerAgeTo'] = Number(selectedAge.split('~')[1]);
    }
    if (selectedResult) {
      data['contractResult'] = selectedResult;
    }
    if (selectedContent) {
      data['contractExist'] = selectedContent;
    }
    if (selImplementStatus) {
      data['implementStatus'] = selImplementStatus;
    }
    if (selAppraiseStatus) {
      data['appraiseStatus'] = selAppraiseStatus;
    }
    if (selImplementAccount) {
      data['implementAccount'] = selImplementAccount;
    }
    data['status'] = ['InMeeting', 'Finished'];
    // const searchFlg = !(JSON.stringify(data) === '{}');
    // searchFlg &&
    if (data['agentCode'] && data['agentCode'].length > 0) {
      searchConversation({
        pageSize: rowsPerPage,
        page: page,
        data: {
          ...data
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            setTableData(response.data?.conversations);
            setTotdalSize(response.data?.total);
          } else {
            console.log('code error');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setTableData([]);
      setPage(0);
      setRowsPerPage(10);
      setTotdalSize(0);
    }
  }, [
    companySelectedList,
    selectedAS,
    branchCode,
    selectedName,
    selectedAge,
    selectedContent,
    selectedResult,
    selImplementStatus,
    selAppraiseStatus,
    selImplementAccount,
    page,
    rowsPerPage,
    delState,
    annotatOpen
  ]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedAS) {
      const code = branchCode ? [selectedAS.toString() + branchCode.toString()] : [selectedAS.toString()];
      getTraineeForSelect(code);
      getImplementers(code);
    }
  }, [selectedAS, branchCode]); //eslint-disable-line react-hooks/exhaustive-deps

  function getTraineeForSelect(a, t = '') {
    getRecruitersByAgentcode({ agentCodeList: a })
      .then(function (response) {
        if (response.status === 200) {
          const rd = response.data?.map((item: any, i) => {
            return { code: item.account, name: item.accountName };
          });
          setSelectedNameList(rd);
          if (t === 'init') {
            setSelectedNameListAll(rd);
          }
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getImplementers(a, t = '') {
    getImplementer({ agentCodeList: a })
      .then(function (response) {
        if (response.status === 200) {
          const rd = response.data?.map((item: any, i) => {
            return { code: item.account, name: item.accountName };
          });
          setImplementerList(rd);
          if (t === 'init') {
            setImplementerListAll(rd);
          }
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const clickHandle = (id: string) => {
    // navigate('../history:' + id);
    navigate('../history:' + id, {
      state: {
        pageSize: rowsPerPage,
        page: page,
        sevenCheck: sevenCheck,
        selectedName: selectedName,
        selectedAS: selectedAS,
        companySelectedList: companySelectedList,
        companyCode: companyCode,
        branchCode: branchCode,
        selectedAge: selectedAge,
        selectedResult: selectedResult,
        selectedContent: selectedContent,
        selImplementStatus: selImplementStatus,
        selAppraiseStatus: selAppraiseStatus,
        selImplementAccount: selImplementAccount,
        branchCodeList: branchCodeList,
        historyStatus: 'historyStatus',
        backStatus: backStatus
      }
    });
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCheckBoxAll(false);

    if (checkBoxAll && event.target.value && (event.target.value as string[]).indexOf('check%Box%all') > -1) {
      // cancel all
      setCompanySelectedList([]);
      setSelectedNameList([]);
      setImplementerList([]);
    } else if (event.target.value && (event.target.value as string[]).indexOf('check%Box%all') > -1) {
      // select all
      setCompanySelectedList(
        companyCodeList.map((item) => {
          return item.code;
        })
      );
      getTraineeForSelect(
        companyCodeList.map((item) => {
          return item.code;
        })
      );
      getImplementers(
        companyCodeList.map((item) => {
          return item.code;
        })
      );
      setCheckBoxAll(true);
    } else {
      setCompanySelectedList((pre) => {
        const cur = event.target.value as string[];
        // if (pre.length < cur.length) {
        //   // add
        //   const c = cur.filter((i) => !pre.includes(i));
        //   getTraineeForSelect(cur);
        // } else {
        //   const c = pre.filter((i) => !cur.includes(i));
        //   const newS = selectedNameList?.filter((v: any, i) => {
        //     // remove
        //     if (v?.agtCde != c[0]) {
        //       return v;
        //     }
        //   });
        //   setSelectedNameList(newS);
        // }
        getTraineeForSelect(cur);
        getImplementers(cur);
        if (cur.length === companyCodeList.length) {
          setCheckBoxAll(true);
        }
        return cur;
      });
    }
    setBackStatus(false);
    setPage(0);
  };

  const handleSelectChange = (t, v) => {
    const selectedV = v.target.value;
    setPage(0); // init page jira 273
    switch (t) {
      case 'company':
        setSelectedAS(selectedV);
        setCompanyCode(selectedV);
        setSelectedName('');
        setSelectedNameList([]);
        setBranchCode('');
        setBranchCodeList([]);
        if (selectedV === '2000002') {
          setBranchCodeList([{ code: '001', name: '本社' }]);
        } else {
          getBranchById(selectedV);
        }
        break;
      case 'branch':
        setBranchCode(selectedV);
        setSelectedName('');
        break;
      case 'name':
        setSelectedName(selectedV);
        break;
      case 'age':
        setSelectedAge(selectedV);
        break;
      case 'content':
        setSelectedContent(selectedV);
        break;
      case 'result':
        setSelectedResult(selectedV);
        break;
      case 'implementStatus':
        setSelImplementAccount('');
        setSelImplementStatus(selectedV);
        break;
      case 'appraiseStatus':
        setSelAppraiseStatus(selectedV);
        break;
      case 'implementAccount':
        setSelImplementAccount(selectedV);
        break;
      default:
        break;
    }
  };

  function getBranchById(id) {
    getBranchsByAgentCode({ id: id })
      .then(function (response) {
        if (response.status === 200) {
          setBranchCodeList(
            response.data?.agstInf?.map((item) => {
              return { code: item.agstCde, name: item.agstNmeK };
            })
          );
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const getContractType = (L, t, origin) => {
    // l--list
    // t--type
    // origin--data from db
    if (!L) {
      return '';
    }

    let check = false;
    let M = new Array();
    if (L instanceof Array) {
      M = L;
    } else {
      JSON.parse(L).map((v, i) => {
        M.push(...v);
      });
    }

    M?.map((v: any, i) => {
      origin?.map((c: any, j) => {
        if (c?.products?.indexOf(v) > -1 && c.name === t) {
          check = true;
          return;
        }
      });
    });

    return check ? <div className={classes.circleMark} /> : '';
  };

  const genImplementDateTime = (data, sTime, eTime) => {
    if (!data) {
      return '';
    }
    // const re = /[0-9]{1,2}:[0-9]{1,2}/;

    let s, e;
    if (sTime) {
      s = sTime;
    } else {
      s = '00:00';
    }

    if (eTime) {
      e = eTime;
    } else {
      e = '23:59';
    }
    // return data + ' ' + s + ' ~ ' + e;
    // return formatUtcStringToYYMMDD(data + 'T' + s + ':00Z');
    return data;
  };

  const handleSwitchChange = (id, t) => {
    updateIspublic({ id: id, ispublic: t })
      .then(function (response) {
        if (response.status === 204) {
          setDelState(!delState);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onHandleDelClick = (id) => {
    setOpen(true);
    setId(id);
    // setType('Delete');
  };

  const handleDelete = () => {
    deleteItem({ id: id })
      .then(function (response) {
        if (response.status === 204) {
          setOpen(false);
          setDelState(!delState);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleRowsClick = (param) => {
    switch (param) {
      case 's':
        if (!sevenCheck) {
          setSelectedAS('');
          setCompanySelectedList([...companySelectedListAll]);
          setSelectedNameList([...selectedNameListAll]);
          setImplementerList([...implementerListAll]);
          setSevenCheck(true);
          setCheckBoxAll(true);
        }
        break;
      case 't':
        if (sevenCheck) {
          setCompanySelectedList([...companySelectedListAll]);
          setSelectedNameList([...selectedNameListAll]);
          setImplementerList([...implementerListAll]);
          setCompanyCode('');
          setBranchCode('');
          setBranchCodeList([]);
          setSevenCheck(false);
          setCheckBoxAll(true);
        }
        break;
      default:
        setSevenCheck(true);
        break;
    }
  };
  const onOpenAnnotatedClick = (id) => {
    getTrainsDetail({
      id: id
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch(setTrainId(id));
          setDetailData(response.data);
          setAnnotatOpen(true);
          // setImplementStatus(implementStatus);
          // setAppraiseStatus(appraiseStatus);
          setProduct('');
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    getTrainsStatus({
      id: id
    })
      .then(function (response) {
        if (response.status === 200) {
          setImplementStatus(response.data.implementStatus ? response.data.implementStatus : '未');
          setAppraiseStatus(response.data.appraiseStatus ? response.data.appraiseStatus : '未');
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleExport = () => {
    exportScvFile()
      .then(function (response) {
        openCsvExportString(`対応履歴.csv`, response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const useMenuItemStyles = makeStyles((theme) => ({
    menuItem: {
      minWidth: '500px'
    }
  }));

  const MuiMenuItem = (props) => {
    const classes = useMenuItemStyles(props);
    return <MenuItem className={classes.menuItem} {...props} />;
  };
  return (
    <Box className="root-1">
      <Grid container item spacing={2} xs={12} className={classes.selectCont}>
        {(isAdmin || curUserInfo['role'] === 'AF_I3NBA') && (
          <Grid item xs={6} className="select-root">
            <Box className="select-root-b">
              <Box className={classes.asCodeTitle}>
                <InputLabel className={classes.inputLabel}>AS コード</InputLabel>
                <Typography className={classes.rowSelect}>
                  <span
                    className={[!sevenCheck ? classes.rowSelected : '', classes.rowSelectSpan].join(' ')}
                    onClick={() => handleRowsClick('s')}
                  >
                    7桁
                  </span>
                  <span
                    className={[sevenCheck ? classes.rowSelected : '', classes.rowSelectSpan].join(' ')}
                    onClick={() => handleRowsClick('t')}
                  >
                    10桁
                  </span>
                </Typography>
              </Box>
              <Grid className={classes.dFlex} container spacing={2}>
                <Grid item xs={sevenCheck ? 12 : 6}>
                  {sevenCheck ? (
                    <Select
                      className={classes.selectStyle}
                      multiple
                      value={companySelectedList}
                      onChange={handleChange}
                      input={<NativeSelectWrap />}
                      renderValue={(selected: any) =>
                        selected?.length !== companyCodeList.length
                          ? `${selected?.length === 0 ? '' : selected?.length + '項目選択'}`
                          : `全AS`
                      }
                      MenuProps={{
                        getContentAnchorEl: null,
                        style: {
                          maxHeight: 300,
                          // width: '15%',
                          marginTop: 33
                        },
                        autoFocus: false
                      }}
                      displayEmpty={true}
                    >
                      <MuiMenuItem key={'-1'} value={'check%Box%all'}>
                        <Checkbox checked={checkBoxAll} />
                        <ListItemText primary={'全AS'} />
                      </MuiMenuItem>
                      {companyCodeList?.map((v: any, i) => (
                        <MuiMenuItem key={i} value={v?.code} style={{ paddingLeft: '50px' }}>
                          <Checkbox checked={companySelectedList?.indexOf(v?.code) > -1} />
                          <ListItemText primary={v?.code + '/' + v?.name} title={v?.code + '/' + v?.name} />
                        </MuiMenuItem>
                      ))}
                    </Select>
                  ) : (
                    <NativeSelectWrap
                      onChange={(v) => handleSelectChange('company', v)}
                      className={classes.selectStyle}
                      value={companyCode}
                    >
                      <option value="">全て</option>
                      {companyCodeList.map((v: any, i) => {
                        return (
                          <option value={v?.code} key={i}>
                            {v?.code}/{v?.name}
                          </option>
                        );
                      })}
                    </NativeSelectWrap>
                  )}
                </Grid>

                {!sevenCheck && (
                  <Grid item xs={6}>
                    <NativeSelectWrap
                      onChange={(v) => handleSelectChange('branch', v)}
                      className={classes.selectStyle}
                      value={branchCode}
                    >
                      <option value="">全て</option>
                      {branchCodeList?.map((v: any, i) => {
                        return (
                          <option value={v?.code} key={i}>
                            {v?.code}/{v?.name}
                          </option>
                        );
                      })}
                    </NativeSelectWrap>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        )}
        {/* <Grid item xs={sevenCheck ? 6 : 3} className="select-root" /> */}
        {isAdmin && (
          <>
            <Grid item xs className="select-root">
              <Box className="select-root-b">
                <InputLabel htmlFor="visit-name" className={classes.labelBreak}>
                  アノテーション実施
                </InputLabel>
                <NativeSelectWrap
                  onChange={(v) => handleSelectChange('implementStatus', v)}
                  className={classes.selectStyle}
                  value={selImplementStatus}
                >
                  <option value="">すべて</option>
                  <option value={'未'}>未</option>
                  <option value={'済'}>済</option>
                  <option value={'対象外'}>対象外</option>
                </NativeSelectWrap>
              </Box>
            </Grid>
            <Grid item xs className="select-root">
              <Box className="select-root-b">
                <InputLabel htmlFor="visit-name" className={classes.labelBreak}>
                  評価用データ
                </InputLabel>
                <NativeSelectWrap
                  onChange={(v) => handleSelectChange('appraiseStatus', v)}
                  className={classes.selectStyle}
                  value={selAppraiseStatus}
                >
                  <option value="">すべて</option>
                  <option value={'未'}>未</option>
                  <option value={'評価用'}>評価用</option>
                </NativeSelectWrap>
              </Box>
            </Grid>
            {selImplementStatus === '済' && (
              <Grid item xs className="select-root">
                <Box className="select-root-b">
                  <InputLabel htmlFor="visit-time" className={classes.labelBreak}>
                    アノテーション実施者
                  </InputLabel>
                  <NativeSelectWrap
                    onChange={(v) => handleSelectChange('implementAccount', v)}
                    className={classes.selectStyle}
                    value={selImplementAccount}
                  >
                    <option value="">すべて</option>
                    {implementerList?.map((v: any, i) => {
                      return (
                        <option value={v?.code} key={i}>
                          {v?.name}
                        </option>
                      );
                    })}
                  </NativeSelectWrap>
                </Box>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Grid container item spacing={2} xs={12} className={classes.selectCont}>
        <Grid item xs className="select-root">
          <Box className="select-root-b">
            <InputLabel htmlFor="visit-time" className={classes.inputLabel}>
              募集人名
            </InputLabel>
            <NativeSelectWrap
              onChange={(v) => handleSelectChange('name', v)}
              className={classes.selectStyle}
              value={selectedName}
            >
              <option value="">すべて</option>
              {selectedNameList?.map((v: any, i) => {
                return (
                  <option value={v?.code} key={i}>
                    {v?.name}
                  </option>
                );
              })}
            </NativeSelectWrap>
          </Box>
        </Grid>
        <Grid item xs className="select-root">
          <Box className="select-root-b">
            <InputLabel htmlFor="visit-name" className={classes.inputLabel}>
              お客様情報{'\n'}(年齢)
            </InputLabel>
            <NativeSelectWrap
              onChange={(v) => handleSelectChange('age', v)}
              className={classes.selectStyle}
              value={selectedAge}
            >
              <option value="">すべて</option>
              <option value={'0~9'}>0~9歳</option>
              <option value={'10~19'}>10~19歳</option>
              <option value={'20~29'}>20~29歳</option>
              <option value={'30~39'}>30~39歳</option>
              <option value={'40~49'}>40~49歳</option>
              <option value={'50~59'}>50~59歳</option>
              <option value={'60~69'}>60~69歳</option>
              <option value={'70~79'}>70~79歳</option>
              <option value={'80~89'}>80~89歳</option>
              <option value={'90~100'}>90~100歳</option>
            </NativeSelectWrap>
          </Box>
        </Grid>
        <Grid item xs className="select-root">
          <Box className="select-root-b">
            <InputLabel htmlFor="receptionist" className={classes.inputLabel}>
              お客様情報​{'\n'}(既契約内容)
            </InputLabel>
            <NativeSelectWrap
              onChange={(v) => handleSelectChange('content', v)}
              className={classes.selectStyle}
              value={selectedContent}
            >
              <option value="">すべて</option>
              <option value={'がん'}>がん</option>
              <option value={'医療'}>医療</option>
              <option value={'その他'}>その他</option>
            </NativeSelectWrap>
          </Box>
        </Grid>
        <Grid item xs className="select-root">
          <Box className="select-root-b">
            <InputLabel htmlFor="receptionist" className={classes.inputLabel}>
              対応履歴​{'\n'}(結果)
            </InputLabel>
            <NativeSelectWrap
              onChange={(v) => handleSelectChange('result', v)}
              className={classes.selectStyle}
              value={selectedResult}
            >
              <option value="">すべて</option>
              <option value={'成約'}>成約</option>
              <option value={'NG'}>NG</option>
              <option value={'継続'}>継続</option>
            </NativeSelectWrap>
          </Box>
        </Grid>
      </Grid>
      <TableContainer className={classes.tableWrap}>
        <Table className={classes.table} aria-label="visitor detail">
          <TableHead>
            <TableRow className={classes.tableRow}>
              <EmptyCell colSpan={4} />
              <HeadCell align="center" colSpan={6}>
                お客様情報
              </HeadCell>
              <HeadCell align="center" colSpan={4}>
                対応履歴
              </HeadCell>
              <EmptyCell colSpan={2} />
              <TableCell align="right">
                {isAdmin && (
                  <Button variant="contained" color="primary" onClick={handleExport}>
                    csv出力
                  </Button>
                )}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <HeadCell align="center" rowSpan={2}>
                No
              </HeadCell>
              <HeadCell align="center" rowSpan={2}>
                実施日
              </HeadCell>
              <HeadCell align="center" rowSpan={2}>
                募集人名
              </HeadCell>
              <HeadCell align="center" rowSpan={2}>
                目的
              </HeadCell>
              <HeadCell align="center" rowSpan={2}>
                お客様氏名
              </HeadCell>
              <HeadCell align="center" rowSpan={2}>
                性別
              </HeadCell>
              <HeadCell align="center" rowSpan={2}>
                年齢
              </HeadCell>
              <HeadCell align="center" colSpan={3}>
                既契約内容
              </HeadCell>
              <HeadCell align="center" rowSpan={2}>
                結果
              </HeadCell>
              <HeadCell align="center" colSpan={3}>
                成約情報
              </HeadCell>
              <HeadCell align="center" rowSpan={2}>
                削除
              </HeadCell>
              <HeadCell align="center" rowSpan={2}>
                公開
              </HeadCell>
              <HeadCell align="center" rowSpan={2}>
                アノテーション
              </HeadCell>
            </TableRow>
            <TableRow className={classes.tableRow}>
              <HeadCell align="center">がん</HeadCell>
              <HeadCell align="center">医療</HeadCell>
              <HeadCell align="center">その他</HeadCell>
              <HeadCell align="center">がん</HeadCell>
              <HeadCell align="center">医療</HeadCell>
              <HeadCell align="center">その他</HeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.map((row: any, i) => (
              <TableRow key={row.id}>
                <HeadCell align="center">
                  <Link style={{ cursor: 'pointer' }} onClick={() => clickHandle(row.id)}>
                    {rowsPerPage * page + i + 1}
                  </Link>
                </HeadCell>
                <RowCell align="center" style={{ minWidth: '120px' }}>
                  {genImplementDateTime(row.implementDate, row?.timeStart, row?.timeEnd)}
                </RowCell>
                <RowCell align="left" style={{ minWidth: '120px' }}>
                  {row.accountName}
                </RowCell>
                <RowCell align="center">{row.pattern}</RowCell>
                <RowCell align="center" style={{ minWidth: '100px' }}>
                  {row.customerName}
                </RowCell>
                <RowCell align="center">{row.customerGender}</RowCell>
                <RowCell align="center">{row.customerAge}歳</RowCell>
                <RowCell align="center">{getContractType(row.contractExist, 'がん', contractsList)}</RowCell>
                <RowCell align="center">{getContractType(row.contractExist, '医療', contractsList)}</RowCell>
                <RowCell align="center">{getContractType(row.contractExist, 'その他', contractsList)}</RowCell>
                <RowCell align="center">{row.contractResult}</RowCell>
                <RowCell align="center">{getContractType(row.contractProduct, 'がん', othersList)}</RowCell>
                <RowCell align="center">{getContractType(row.contractProduct, '医療', othersList)}</RowCell>
                <RowCell align="center">{getContractType(row.contractProduct, 'その他', othersList)}</RowCell>
                <RowCell align="center">
                  {row.account === curUserInfo['account'] ? (
                    <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => onHandleDelClick(row?.id)} />
                  ) : (
                    ''
                  )}
                </RowCell>
                <RowCell align="center">
                  {(curUserInfo['role'] === 'Agent_Admin' || curUserInfo['role'] === 'Agent_User') &&
                  row.account === curUserInfo['account'] ? (
                    <Switch
                      checked={row?.isPublic ? true : false}
                      onChange={(e) => handleSwitchChange(row?.id, e.target.checked)}
                      color="primary"
                    />
                  ) : (
                    ''
                  )}
                </RowCell>
                <RowCell align="center" style={{ minWidth: '130px' }}>
                  {isAdmin && (
                    <img
                      src={WechatSvg}
                      alt=""
                      className={classes.wechatImg}
                      onClick={() => onOpenAnnotatedClick(row?.trainId)}
                    />
                  )}
                </RowCell>
              </TableRow>
            ))}
          </TableBody>
          <ConfirmDialog
            title="対応履歴を削除しますか?"
            open={open}
            setOpen={setOpen}
            onSubmit={handleDelete}
          />
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, { label: 'All', value: totalSize }]}
                count={totalSize}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                labelRowsPerPage={''}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <AnnotatedModal
        open={annotatOpen}
        setOpen={setAnnotatOpen}
        product={product}
        detailData={detailData}
        implementStatus={implementStatus}
        setImplementStatus={setImplementStatus}
        appraiseStatus={appraiseStatus}
        setAppraiseStatus={setAppraiseStatus}
      />
    </Box>
  );
};

export default History;
