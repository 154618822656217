import service from './service';

export function getAgentsList(parameter) {
  return service({
    url: `/agents`,
    method: 'get'
  });
}

export function setStep2WebInfo(parameter) {
  return service({
    url: `/conversations`,
    method: 'post',
    data: {
      ...parameter.params
    }
  });
}

export function updateById(parameter) {
  return service({
    url: `/conversations/${parameter.author.id}`,
    method: 'put',
    data: {
      ...parameter.params
    }
  });
}

export function getThemesList(parameter) {
  return service({
    url: 'users/me/themes',
    method: 'get'
  });
}

export function getContractsList(parameter) {
  return service({
    url: '/keywords/contracts/data',
    method: 'get'
  });
}

export function getUserList(parameter) {
  const url =
    parameter.code.length > 7 ? `/agents/branches/${parameter.code}/users` : `/agents/${parameter.code}/users`;
  return service({
    url: url,
    method: 'get'
  });
}

export function teamsBotRequestBeforeStep3(parameter) {
  return service({
    url: `/v1/recs`,
    method: 'post',
    requestBase: 'bot',
    data: {
      url: parameter.teamUrl,
      sectionId: '1',
      callback: `${process.env.REACT_APP_API_BOT_CALLBACK_URL}/conversations/${parameter.id}/calls/lines`
    }
  });
}

export function stopTeamBot(parameter) {
  return service({
    url: `${parameter.url}`,
    method: 'put',
    requestBase: 'bot',
  });
}

export function saveTeamsBotCallIdAndUrl(parameter) {
  return service({
    url: `/conversations/${parameter.id}/calls`,
    method: 'post',
    data: {
      teamsCallId: parameter.callId,
      teamsUrl: parameter.teamUrl
    }
  });
}

export function setResultInput(parameter) {
  return service({
    url: `/conversations/${parameter.id}/result`,
    method: 'put',
    data: {
      recommend: parameter.notes,
      appraise: parameter.appraise,
      contractResult: parameter.contractResult,
      contractProduct: parameter.contractProduct,
      isPublic: parameter.isPublic
    }
  });
}

export function getCsvPdf() {
  return service({
    url: `/fileset/search?orderby=updated_at&sort=desc`,
    method: 'post',
    data: {
      isRecordAgree: true
    }
  });
}
