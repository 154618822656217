import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination
} from '@material-ui/core';
import TablePaginationActions from '../../components/TablePaginationActions';
import { searchConversation, deleteItemInNew } from '../../api/history';
import ConfirmDialog from '../../components/management/ConfirmDialog';
import { useSelector } from 'react-redux';
import { RootState } from '../../../src/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      textAlign: 'left',
      marginLeft: '9px',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '16px',
      minHeight: '32px',
      whiteSpace: 'pre'
    },
    underline: {
      content: ''
    },
    selectStyle: {
      width: '90%',
      border: '1px solid #ccc',
      borderRadius: '5px'
    },
    selectStyleTime: {
      width: '100%',
      marginRight: '10px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      height: '32px'
      // fontSize: '20px',
      // marginBottom: '10px',
      // marginTop: '10px'
    },
    tableWrap: {
      alignItems: 'center',
      marginTop: '35px',
      boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)',
      borderRadius: '0px'
    },
    table: {
      width: '100%'
    },
    startBtn: {
      backgroundColor: '#00a5d9'
    },
    timeDateBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '15px'
    },
    asCodeTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    rowSelect: {
      cursor: 'pointer',
      // marginTop: '-20px',
      marginRight: '5px',
      fontSize: '10px'
    },
    rowSelectSpan: {
      border: '2px solid #00a5d9',
      padding: '1px 10px',
      backgroundColor: '#00a5d9',
      color: '#fff'
    },
    rowSelected: {
      border: '2px solid #00a5d9',
      backgroundColor: '#fff',
      color: '#00a5d9'
    },
    selectCont: {
      maxWidth: 'inherit'
    }
  })
);

const New = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const curUserInfo = useSelector((state: RootState) => state.user.userInfo);
  const handleStart = (cId, teamsurl) => {
    navigate('../readybefore', { state: { step: 2, conversationId: cId, teamsUrl: teamsurl } });
  };
  const handleStartStep1 = (cId, teamsurl) => {
    navigate('../readybefore', { state: { step: 0, conversationId: cId, teamsUrl: teamsurl } });
  };
  const [id, setId] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [delState, setDelState] = React.useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  // setTableData(rows)
  const [rows, setRowsFromDb] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    // const searchFlg = !(JSON.stringify(data) === '{}');
    // searchFlg &&
    let data = {};
    data['account'] = curUserInfo['account'];
    data['status'] = ['Ready'];
    searchConversation({
      pageSize: rowsPerPage,
      page: page,
      data: {
        ...data
      }
    })
      .then(function (response) {
        if (response.status === 200) {
          const { total, conversations } = response.data;
          setTotalCount(total);
          setRowsFromDb(conversations);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [page, rowsPerPage, delState]); // eslint-disable-line react-hooks/exhaustive-deps

  const onHandleDelClick = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleDelete = () => {
    deleteItemInNew({ id: id })
      .then(function (response) {
        if (response.status === 204) {
          setOpen(false);
          setDelState(!delState);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Box className="root-1">
      <TableContainer component={Paper} className={classes.tableWrap}>
        <Table className={classes.table} aria-label="visitor detail">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">目的</TableCell>
              <TableCell align="center">実施日</TableCell>
              <TableCell align="center">実施時間</TableCell>
              <TableCell align="center">お客様氏名</TableCell>
              <TableCell align="center">年齢</TableCell>
              <TableCell align="center">アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: any) => (
              <TableRow key={row?.id}>
                <TableCell component="th" scope="row">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.startBtn}
                    onClick={() => handleStart(row?.id, row?.teamsUrl)}
                  >
                    新規営業の開始
                  </Button>
                </TableCell>
                <TableCell align="center">{row.pattern}</TableCell>
                <TableCell align="center">{row?.implementDate}</TableCell>
                <TableCell align="center">{row?.timeStart}</TableCell>
                <TableCell align="center">{row.customerName}</TableCell>
                <TableCell align="center">{row.customerAge}</TableCell>
                <TableCell align="center">
                  <EditIcon
                    style={{ marginRight: '15px', cursor: 'pointer' }}
                    onClick={() => handleStartStep1(row?.id, '')}
                  />
                  <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => onHandleDelClick(row?.id)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, { label: 'All', value: totalCount }]}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                labelRowsPerPage={''}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ConfirmDialog
        title="新規営業の予定を削除しますか?"
        open={open}
        setOpen={setOpen}
        onSubmit={handleDelete}
      />
    </Box>
  );
};

export default New;
