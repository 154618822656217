/*
 * Copyright © 2020 by Pactera Technology Japan Co., Ltd.
 *
 * Code written by Atilika Inc. Contact us at hello@atilika.com.
 */

import moment from 'moment';
import store from '../store';

const NEEDS_ESCAPE = ['\r', '\n', '"', '\ufeff', ','];
const RUN_ENV = process.env.REACT_APP_RUN_ENV;
const Store = store.store;

// As per RFC 4180
function escapeCsvValue(str: string): string {
  const escapedQuoteStr = str.replace(/"/g, '""');

  const needsQuotes =
    NEEDS_ESCAPE.some((bad) => escapedQuoteStr.includes(bad)) ||
    escapedQuoteStr.startsWith(' ') ||
    escapedQuoteStr.endsWith(' ');

  return needsQuotes ? `"${escapedQuoteStr}"` : escapedQuoteStr;
}

export function openCsvExport(name: string, lines: string[][] | string) {
  const csv = typeof lines === 'string' ? lines : lines.map((line) => line.map(escapeCsvValue).join(',')).join('\n');

  openCsvExportString(name, csv);
}

export function openCsvExportString(name: string, csv: string) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, csv], { type: 'text/csv;charset=utf-8' });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
}

export function formatDate(date: moment.MomentInput) {
  return moment(date).format('YYYY-MM-DD HH:mm');
}

export function formatUTCDate(date: moment.MomentInput) {
  return moment(date).utc().format('YYYY-MM-DD HH:mm');
}

export function formatUTCDateFormat(date: moment.MomentInput) {
  return moment(date).utc().format();
}

export function blobExport(name: string, data: Blob) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const url = window.URL.createObjectURL(data);
  a.href = url;
  a.download = name;
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export function blobViewInBroswer(name: string, data: Blob) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const url = window.URL.createObjectURL(data);
  a.href = url;
  a.target='_blank';
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export function pdfHeaderForPreview(fileName){
  const { aanetUser, aanetGroup } = Store.getState().user;
  const url=process.env.REACT_APP_API_BASE_URL + `/fileset/preview/${fileName}`;
  let t = {
    url: url,
    withCredentials: true
  };
  if (RUN_ENV === 'dev') {
    t['httpHeaders'] = {
      'x-aanet-user': aanetUser,
      'x-aanet-group': aanetGroup
    };
  }

  return t
}
