import React from 'react';
import { Navigate } from 'react-router';
import New from './views/new';
import Home from './views/home';
import Login from './views/login';
import Result from './views/result';
import History from './views/history';
import MainLayout from './layouts/MainLayout';
import ReadyBefore from './views/readyBefore';
import ContractStatus from './views/contractStatus';
import HistoryDetails from './views/history/details';
import CustomerSupport from './views/customerSupport/index';
import CustomerSupportMax from './views/customerSupport/indexMax';
//management
import UserManagement from './views/management/userManagement';
import RecommendManagement from './views/management/recommendationManagement';
import ManualManagement from './views/management/manualManagement';
import Dictmanagement from './views/management/dictmanagement';
import Machinelearnreflection from './views/management/machinelearnreflection';
import AnnotationManagement from './views/management/annotationManagement';
import KeyWordsManagement from './views/management/keyWordsManagement';
//
import Error404 from './views/404';
//
import ChangeLog from './views/changeLog';
import Logout from './views/logout';

const RUN_ENV = process.env.REACT_APP_RUN_ENV
const routes = [
  {
    path: '/',
    children: [
      { path: '/', element: <Login /> },
      { path: '/login', element: <Login /> },
      { path: '/404', element: <Error404 /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: '/logout', element: <Logout /> }
    ]
  },
  {
    path: 'app',
    element: <MainLayout />,
    children: [
      { path: 'history:id', element: <HistoryDetails /> },
      { path: 'customersupport:id', element: <CustomerSupport /> },
      { path: 'translatemax/*', element: <CustomerSupportMax /> },
      { path: 'readybefore', element: <ReadyBefore /> },
      { path: 'new', element: <New /> },
      { path: 'history', element: <History /> },
      { path: 'result', element: <Result /> },
      { path: 'contractstatus', element: <ContractStatus /> },
      RUN_ENV === 'dev' ? { path: 'changelog', element: <ChangeLog /> } : {},
      { path: '/', element: <Home /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'management',
    element: <MainLayout />,
    children: [
      { path: 'usermanagement', element: <UserManagement /> },
      { path: 'recomanagement', element: <RecommendManagement /> },
      { path: 'manualmanagement', element: <ManualManagement /> },
      { path: 'dictmanagement', element: <Dictmanagement /> },
      { path: 'machinelearnreflection', element: <Machinelearnreflection /> },
      { path: 'annotationmanagement', element: <AnnotationManagement /> },
      { path: 'keywordsmanagement', element: <KeyWordsManagement /> },
      { path: '/', element: <Home /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
