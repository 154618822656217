import React, { memo,  useState } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { IconButton, TextField, Button, FormControl, Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { ProductDataChangeSet } from './ProductData';
import PreviewPdf from '../../../../components/ShowPdf';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import EventBus from '../../../../components/EventBus';

const useStyles = makeStyles((theme) => ({
  url: {
    marginTop: theme.spacing(2)
  },
  link: {
    marginBottom: theme.spacing(5)
  },
  references: {
    marginBottom: theme.spacing(2)
  },
  fields: {
    width: '60%'
  },
  removeButton: {
    width: 48,
    height: 48,
    marginLeft: 16
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120
  },
  closeButton: {
    position: 'absolute',
    left: '95%',
    top: '1px'
  },
  reviewButton: {
    marginTop: theme.spacing(2.5)
  },
  span: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  error: {
    '&.MuiFormHelperText-root.Mui-error': {
      color: 'grey'
    }
  }
}));

const ProductDataReferencesSubform = (props) => {
  const { disabled, fileSets } = props
  const classes = useStyles();
  const form = useFormikContext<ProductDataChangeSet>();
  const { touched, errors, values, handleBlur, handleChange } = form;
  const [pdfName, setPdfName] = React.useState('');
  const [pdfFileOpen, setPdfFileOpen] = useState(false);

  const separator = ' / ';

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: string) => option + fileSets.find((fileSet) => option === fileSet.name)?.description
  });

  const renderOption = (option: string) => {
    const desc = fileSets.find((fileSet) => option === fileSet.name)?.description;
    if (!desc) return option;
    return option + separator + desc;
  };

  const handlehrefClick = (name) => {
    setPdfName(name);
    if (!name) {
      EventBus.dispatch('show_snack', { message: 'ファイルを選択ください。', type: 'error' });
    } else {
      setPdfFileOpen(true);
    }
  };

  return (
    <FieldArray
      name="references"
      render={(arrayHelpers) => (
        <>
          {values.references.map((desc, index) => {
            const error = errors.references?.[index];
            const descTouched = Array.isArray(touched.references) && touched.references?.[index];

            const numPagesTouched = descTouched && descTouched.numPages;
            const numPagesError = typeof error === 'object' && error.numPages;

            const textTouched = descTouched && descTouched.description;
            const textError = typeof error === 'object' && error.description;

            const uriTouched = descTouched && descTouched.url;
            const uriError = typeof error === 'object' && error.url;
            return (
              <div key={index} className={classes.link}>
                <Box display="flex" alignItems="top" className={classes.references}>
                  <Box flexGrow={1}>
                    <TextField
                      variant="outlined"
                      label="ページ数"
                      placeholder="ページ数"
                      name={`references.${index}.numPages`}
                      value={desc.numPages}
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 0
                        }
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={disabled || desc.url === ''}
                      error={numPagesTouched && !!numPagesError}
                      helperText={numPagesTouched && numPagesError}
                    />
                  </Box>
                  <Box>
                    <IconButton disabled={disabled} onClick={() => arrayHelpers.remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>

                <TextField
                  className={classes.fields}
                  variant="outlined"
                  label="説明文"
                  placeholder="説明文"
                  name={`references.${index}.description`}
                  value={desc.description}
                  disabled={disabled}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={textTouched && !!textError}
                  helperText={textTouched && textError}
                />

                <Box display="flex" alignItems="top">
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <Autocomplete
                      disabled={disabled}
                      filterOptions={filterOptions}
                      id={`combo-url-${index}`}
                      value={desc.url}
                      getOptionSelected={(option, value) => {
                        if (!value || fileSets.map((f) => f.name).find((url) => url === value)) {
                          return option === value;
                        } else {
                          return option !== value;
                        }
                      }}
                      onChange={(_event: any, option: string | null) => {
                        if (option !== null) {
                          form.setFieldValue(`references.${index}.url`, option);
                        }
                      }}
                      renderOption={(option) => <span className={classes.span}>{renderOption(option)}</span>}
                      options={[...fileSets.map((f) => f.name).sort(), '']}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="ファイル"
                            variant="outlined"
                            name={`references.${index}.url`}
                            error={uriTouched && !!uriError}
                            disabled={disabled}
                          />
                        );
                      }}
                    />
                  </FormControl>
                  <Box>
                    <IconButton
                      disabled={desc.url === '' || disabled}
                      className={classes.reviewButton}
                      id={`btn-preview-${index}`}
                      onClick={() => handlehrefClick(desc.url)}
                    >
                      <ImageOutlinedIcon />
                    </IconButton>
                    <PreviewPdf open={pdfFileOpen} setOpen={setPdfFileOpen} pdfValue={pdfName} />
                  </Box>
                </Box>
              </div>
            );
          })}
          <div>
            <Button
              fullWidth
              onClick={() => arrayHelpers.push({ description: '', url: '' })}
              variant="contained"
              color="secondary"
              disabled={disabled}
              disableElevation
            >
              追加
            </Button>
          </div>
        </>
      )}
    />
  );
}

export default memo(ProductDataReferencesSubform);
