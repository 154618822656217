import service from './service';

export function getAiTrainList(parameter) {
  const url =
    parameter.pageSize === -1
      ? `/aimodels/search?orderby=${parameter.orderby}&sort=${parameter.sort}`
      : `/aimodels/search?pagesize=${parameter.pageSize}&page=${parameter.page}&orderby=${parameter.orderby}&sort=${parameter.sort}`;
  return service({
    url: url,
    method: 'post',
    data: {
      keyWords: parameter.words
    }
  });
}
export function createAiTrain(parameter) {
  return service({
    url: `/aimodels/train`,
    method: 'post',
    data: {}
  });
}

export function downloadZip(parameter) {
  return service({
    url: `/aimodels/${parameter.id}/download`,
    responseType: "blob",
    timeout: 1000 * 60 * 60,
    method: 'get',
  });
}

export function deploy(parameter) {
  return service({
    url: `/aimodels/${parameter.id}/deploy`,
    method: 'post',
    data: {}
  });
}

export function updateDesc(parameter) {
  return service({
    url: `/aimodels/${parameter.id}`,
    method: 'put',
    data: {
      descriptions: parameter.descriptions
    }
  });
}
