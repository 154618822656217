import service from './service';

export function getLayoutsList(parameter) {
  return service({
    url: `/layouts/1`,
    method: 'get'
  });
}

export function setLayout(parameter) {
  return service({
    url: `/layouts`,
    method: 'post',
    data: {
      account: parameter.account,
      pattern: parameter.pattern,
      data: JSON.stringify(parameter.data)
    }
  });
}

export function updateLayout(parameter) {
  return service({
    url: `/layouts/${parameter.id}`,
    method: 'put',
    data: {
      account: parameter.account,
      pattern: parameter.pattern,
      data: JSON.stringify(parameter.data)
    }
  });
}

export function searchLayout(parameter) {
  return service({
    url: `/layouts/search`,
    method: 'post',
    data: {
      account: parameter.account,
      pattern: parameter.pattern
    }
  });
}
