import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import LogoutImg from '../images/logout_icon.png';


const RUN_ENV = process.env.REACT_APP_RUN_ENV;
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '40px',
      marginTop: '12px'
    },
    outBtn: {
      background: '#FFFFFF',
      width: '150px',
      minHeight: '45px',
      border: '2px solid #85bdda',
      borderRadius: '10px',
      color: '#333333',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textAlign: 'center',
      '& span': {
        color: '#5186bb'
      }
    },
    btnImg: {
      marginRight: '7px'
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: '1.6'
    }
  })
);

const HomeTitle: React.FC<HomeTitleProps> = (props: HomeTitleProps): JSX.Element => {
  const { data } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  // const { aanetUser, aanetGroup } = useSelector((state: RootState) => state.user);
  const handleLogoOut = () => {
    // if (RUN_ENV === 'stage') {
    //   window.open('https://st-aflac.platformerfuji.com/apigw/va2nba/', '_self');
    // } else if (RUN_ENV === 'prod') {
    //   window.open('https://aflac.platformerfuji.com/apigw/va2nba/', '_self');
    // } else {
    // navigate('../logout', { state: { aanetUser: aanetUser, aanetGroup: aanetGroup } });
    navigate('../logout');
    // }
  };
  const disLayout = ['結果の入力', 'お客様対応画面'];
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>{data}</h3>
      {disLayout.indexOf(data) === -1 ? (
        <Button className={classes.outBtn} onClick={() => handleLogoOut()}>
          <img className={classes.btnImg} aria-hidden alt="Logout Image" src={LogoutImg} />
          <Typography>ログアウト</Typography>
        </Button>
      ) : (
        ''
      )}
    </div>
  );
};

interface HomeTitleProps {
  data: string;
}

export default HomeTitle;
