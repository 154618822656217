import React, { useState } from 'react';
import SwipeableViews from "react-swipeable-views";
import { Box, Divider, Grid, Typography, Tabs, Tab, TextField } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Title from './DetailTitle';
import ExpressBtn from './ExpressBtn'
import NewWindow from 'react-new-window'
import { TrainRounded } from '@material-ui/icons';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      border: '1px solid #ccc',
      borderRadius: '10px',
      backgroundColor: '#FFFFFF',
      padding: '5px 25px'
      // paddingTop:'-15px'
    },
    translateBox: {
      height: '100%',
      marginBottom: '10px',
      width: '100%',
      position: 'relative',
      paddingBottom: '30px'
    },
    contant: {
      height: '100%',
      overflowY: 'scroll',
      position: 'relative',
      padding: '15px'
    },
    translateBoxTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
      // height: '30px'
    },
    textBox: {
      padding: '5px 15px'
    },
    thumbIcon: {
      border: '1px solid #ccc',
      borderRadius: '4px',
      padding: '2px 10px',
      display: 'inline-block',
      alignItems: 'center',
      cursor: 'pointer',

    },
    fullScreen: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      zIndex: 6666,
      borderRadius: 0
    },
  })
);

const Evaluation: React.FC<EvaluationProps> = (props: EvaluationProps): JSX.Element => {

  const { set, setMax, header } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [connect, setConnect] = useState(true);  // set on/off default true
  const [popTranslate, setPopTranslate] = useState(false);
  const [stopData, setStopData] = useState<any[]>([]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  // const handleChangeIndex = (index: number) => {
  //   setValue(index);
  // };

  // const handleGoodClick = () => {
  //   const nGood = good + 1;
  //   setGood(nGood);
  // };

  // const handleBadClick = () => {
  //   const nBad = bad + 1;
  //   setBad(nBad);
  // };


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}

      >
        {value === index && (
          <Box style={{ overflowY: 'auto', height: '100%', position: 'relative', wordWrap: 'break-word', wordBreak: 'normal' }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`
    };
  }
  const handleEvaMax = () => {
    // const openBaseUrl = curBaseUrl(location) + "/#"
    // const curCom = `/app/translatemax?type=eval`
    // const w = window.innerWidth / 3;
    // const h = window.innerHeight;
    // const wFocus = window.open(openBaseUrl + curCom, '', `height=${h}, width=${w}, top=0, left=${w}`);
    // wFocus?.focus()
    setPopTranslate(true)
  };

  return (
    !set ?
      <>
        <Box className={classes.translateBox}>
          <Title text={!header ? 'レコメンデーション' : ''} Max={!setMax} FnMax={() => handleEvaMax()} connect={connect} setCon={false} />
          <Box className={classes.contant}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            // variant="fullWidth"
            >
              {props?.data?.data.map((v: any, i) => {
                return <Tab label={v?.summary?.slice(0, 9)} {...a11yProps(i)} />
              })}
            </Tabs>
            <SwipeableViews
              index={value}

            >
              {props?.data?.data.map((t: any, i) => {
                return <TabPanel value={value} index={i} >
                  <TextField
                    fullWidth
                    multiline
                    value={t?.summary}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                    }}
                    style={{ backgroundColor: '#fff' }}
                  />
                </TabPanel>
              })}
            </SwipeableViews>

          </Box>
        </Box>
        {popTranslate && <NewWindow
          title={'レコメンデーション'}
          center={'screen'}
          copyStyles={true}
          onUnload={() => {
            setPopTranslate(false)
          }}
        >
          <Box className={setMax ? [classes.root, classes.translateBox, classes.fullScreen].join(" ") : ""}>
            <Title text={!header ? 'レコメンデーション' : ''} Max={!setMax} FnMax={() => handleEvaMax()} />
            <Box className={classes.contant}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              // variant="fullWidth"
              >
                {connect ?
                  props?.data?.data?.map((v: any, i) => {
                    return <Tab label={v?.summary?.slice(0, 9)} {...a11yProps(i)} />
                  }) :
                  stopData?.map((v: any, i) => {
                    return <Tab label={v?.summary?.slice(0, 9)} {...a11yProps(i)} />
                  })
                }
              </Tabs>
              <SwipeableViews
                index={value}

              >
                {connect ?
                  props?.data?.data?.map((t: any, i) => {
                    return <TabPanel value={value} index={i} >
                      <TextField
                        fullWidth
                        multiline
                        value={t?.summary}
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true,
                        }}
                        style={{ backgroundColor: '#fff' }}
                      />
                    </TabPanel>
                  }) :
                  stopData?.map((t: any, i) => {
                    return <TabPanel value={value} index={i} >
                      <TextField
                        fullWidth
                        multiline
                        value={t?.summary}
                        InputProps={{
                          disableUnderline: true,
                          readOnly: true,
                        }}
                        style={{ backgroundColor: '#fff' }}
                      />
                    </TabPanel>
                  })
                }
              </SwipeableViews>
            </Box>
          </Box>
        </NewWindow>}
      </>
      :
      <>
        <span className='remove'>レコメンデーション</span>
        <Divider />
        <Grid container item xs={12} spacing={2} style={{ padding: '15px' }}>
          <Grid item xs={6}>
            <Typography>ヘッダー表示</Typography>
            <ExpressBtn express={!props?.header} type="dis" comIndex={props?.settingIndex} layouts={props?.layouts} layoutFun={props?.layoutFun} />
          </Grid>
          <Grid item xs={6}>
            <Typography>最大化</Typography>
            <ExpressBtn express={!props?.setMax} type="max" comIndex={props?.settingIndex} layouts={props?.layouts} layoutFun={props?.layoutFun} />
          </Grid>
        </Grid>
      </>
  );
};

interface EvaluationProps {
  Max?: boolean;
  data?: any;
  set?: any;
  setMax?: boolean;
  header?: boolean;
  settingIndex?: any;
  layouts?: any;
  layoutFun?: any;
  token?: any,
  id?: any,
}

export default Evaluation;

