import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'annotations',
  initialState: {
    trainId: null
  },

  reducers: {
    setTrainId: (state, action) => {
      state.trainId = action.payload;
    }
  }
});

export const { setTrainId } = userSlice.actions;

export default userSlice.reducer;
