import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/GetApp';
import TitleWithSearch from '../../../components/management/TitleWithSerach';
import AddBtn from '../../../components/management/AddBtn';
import DialogOnly from '../../../components/management/DialogOnly';
import TablePaginationActions from '../../../components/TablePaginationActions';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  getFileList,
  uploadFile,
  getFileById,
  modFileById,
  deleteFileById,
  downloadFileById,
  exportFile
} from '../../../api/manualManageApi';
import { formatDate, blobExport, openCsvExportString } from '../../../utils/ExportUtil';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import DownloadDialog from '../../../components/management/DownloadDialog';
import DeleteDialog from '../../../components/management/DeleteDialog';
import EventBus from '../../../components/EventBus';

const useStyles = makeStyles(() =>
  createStyles({
    titleSearch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    titleText: {
      fontWeight: 800,
      fontSize: '20px'
    },
    inputDel: {
      cursor: 'pointer',
      marginRight: '5px',
      opacity: '0.3',
      '&:hover': {
        opacity: '0.5'
      }
    },
    tableWrap: {
      alignItems: 'center',
      marginTop: '30px',
      boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)',
      borderRadius: '0px'
    },
    table: {
      width: '100%'
    },
    tableCellAction: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    tableRow: {
      backgroundColor: '#e98300',
      '& .MuiTableCell-body': {
        color: '#fff'
      }
    }
  })
);

const ManualManagement = (props) => {
  const classes = useStyles();
  // const navigate = useNavigate()
  // for search
  const [searchData, setSearchData] = useState(null);
  const title = 'データ集を管理';

  const [rows, setRows] = useState([]);
  // cur page
  const [page, setPage] = React.useState(0);
  const [cPageChange, setcPageChange] = React.useState(false);
  const [totalSize, setTotalSize] = React.useState(0);
  //
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orderby, setOrderby] = React.useState('');
  const [sort, setSort] = React.useState('');

  // dialog upload
  const [uploadF, setUploadF] = React.useState('');
  const [uploadFName, setUploadFName] = React.useState('');
  // dialog description
  const [description, setDescription] = React.useState('');
  // edit dialog
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  // current id
  const [currentId, setCurrentId] = React.useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [delOpen, setDelOpen] = React.useState(false);

  const [id, setId] = React.useState(0);

  const [isRecordAgree, setIsRecordAgree] = React.useState(false);

  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  useEffect(() => {
    if (searchData && page) {
      //
      if (cPageChange) {
        initTable(page, rowsPerPage, orderby, sort, searchData);
      }
    } else {
      initTable(page, rowsPerPage, orderby, sort, searchData);
    }
  }, [page, rowsPerPage, orderby, sort, searchData, cPageChange]); // eslint-disable-line react-hooks/exhaustive-deps

  // get data from db
  function initTable(page, rowsPerPage, orderby, sort, searchData) {
    getFileList({
      pagesize: rowsPerPage,
      page: page,
      orderby: orderby,
      sort: sort,
      keyWords: searchData
    })
      .then(function (response) {
        if (response.status === 200) {
          setRows(response.data?.fileSets);
          setTotalSize(response.data?.total);
          setcPageChange(false);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setcPageChange(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setcPageChange(true);
  };

  const onHandleEditClick = (id) => {
    getFileById({ id: id })
      .then(function (response) {
        if (response.status === 200) {
          const { id, name, description, isRecordAgree } = response.data;
          setCurrentId(id);
          setUploadFName(name);
          setDescription(description);
          setIsRecordAgree(isRecordAgree);
          setEditDialogOpen(true);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onHandleDelClick = (id) => {
    setDelOpen(true);
    setId(id);
  };

  const handleDelete = () => {
    deleteFileById({ id: id })
      .then(function (response) {
        if (response.status === 204) {
          initTable(page, rowsPerPage, orderby, sort, searchData);
          setDelOpen(false);
          // console.log(response.data);
        } else {
          console.log('code error:', response);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onDownloadClick = (id, name) => {
    downloadFileById({ id: id })
      .then(function (response) {
        if (response.status === 200) {
          if (response?.data) {
            blobExport(name, response?.data);
          }
        } else {
          console.log('code error:', response);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const addSubmit = () => {
    uploadFile({
      name: uploadFName,
      description: descriptionRef.current?.value,
      content: uploadF,
      isRecordAgree: voiceConsentRef.current?.checked
    })
      .then(function (response) {
        if (response.status === 200) {
          initTable(page, rowsPerPage, orderby, sort, searchData);
          setAddDialogOpen(false)
        } else if (response.status === 409) {
          console.log('code error');
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response?.status === 409) {
          EventBus.dispatch('show_snack', {
            message: '既に同名ファイルが保存されています。同名ファイルを削除後に保存してください。',
            type: 'error'
          });
        }
      });
  };

  const modSubmit = () => {
    modFileById({
      id: currentId,
      name: uploadFName,
      description: descriptionRef.current?.value,
      content: uploadF,
      isRecordAgree: voiceConsentRef.current?.checked
    })
      .then(function (response) {
        if (response.status === 200) {
          initTable(page, rowsPerPage, orderby, sort, searchData);
          setEditDialogOpen(false);
        } else if (response.status === 409) {
          console.log(response);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response?.status === 409) {
          EventBus.dispatch('show_snack', {
            message: '既に同名ファイルが保存されています。同名ファイルを削除後に保存してください。',
            type: 'error'
          });
        }
      });
  };

  const filetoString = (file: File) => {
    const readerDataURL = new FileReader();
    readerDataURL.readAsDataURL(file);
    readerDataURL.onloadend = () => setUploadF(readerDataURL.result?.toString() || '');
  };

  const descriptionRef = React.useRef<HTMLInputElement>(null);
  const voiceConsentRef = React.useRef<HTMLInputElement>(null);
  const [initFlag, setInitFlag] = useState(false);

  const initAddDialog = () => {
    setUploadFName('');
    setDescription('');
    setUploadF('');
    setIsRecordAgree(false);
    setInitFlag(!initFlag);
  };

  const AddDialog = {
    title: 'データ集を追加',
    data: [
      {
        type: 'upload',
        fieldLabel: 'ファイル',
        fieldName: 'name',
        value: uploadFName,
        acceptedFiles: ['.pdf', 'application/pdf'],
        handleBlur: () => {
          console.log('handleBlur');
        },
        handleChange: () => {
          console.log('handleChange');
        },
        onSave: (v) => {
          setUploadFName(v?.name);
          filetoString(v);
        }
      },
      {
        type: 'input',
        name: 'description',
        label: '説明',
        placeholder: '説明',
        value: descriptionRef.current?.value,
        uref: descriptionRef,
        required: false,
        handleChange: setDescription
      },
      {
        type: 'checkBox',
        name: 'voiceConsent',
        label: '音声録音同意',
        value: voiceConsentRef.current?.checked,
        uref: voiceConsentRef,
        handleChange: setIsRecordAgree
      },
      {
        type: 'pdfPreview',
        label: 'プレビュー',
        pdfValue: uploadFName,
        pdfContent: uploadF
      }
    ],
    handleSubmit: () => addSubmit(),
    init: () => initAddDialog()
  };

  let EditDialog = {
    title: 'データ集を編集',
    data: [
      {
        type: 'upload',
        fieldLabel: 'ファイル',
        fieldName: 'name',
        value: uploadFName,
        handleBlur: () => {
          console.log('handleBlur');
        },
        handleChange: () => {
          console.log('handleChange');
        },
        onSave: (v) => {
          setUploadFName(v?.name);
          filetoString(v);
        }
      },
      {
        type: 'input',
        name: 'description',
        label: '説明',
        placeholder: '説明',
        value: descriptionRef.current?.value || description,
        uref: descriptionRef,
        required: false,
        handleChange: setDescription
      },
      {
        type: 'checkBox',
        name: 'voiceConsent',
        label: '音声録音同意',
        value: voiceConsentRef.current?.checked || isRecordAgree,
        uref: voiceConsentRef,
        handleChange: setIsRecordAgree
      },
      {
        type: 'pdfPreview',
        label: 'プレビュー',
        pdfValue: uploadFName,
        pdfContent: uploadF
      }
    ],
    handleSubmit: () => {
      modSubmit();
    }
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };
  //  table header begin
  const headCellsDef = [
    {
      id: 'name', // db column name
      disablePadding: false,
      label: 'ファイル名', // table header column name
      active: false, // mouseout display is none except current is cliked
      order: 'asc', // default sort
      sorted: true // this column sort or not
    },
    {
      id: 'description',
      disablePadding: false,
      label: '説明',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'created_at',
      disablePadding: false,
      label: '作成',
      active: false,
      order: 'desc',
      sorted: true
    },
    {
      id: 'updated_at',
      disablePadding: false,
      label: '更新',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'アクション',
      active: false,
      order: 'asc',
      sorted: false,
      style: {
        minWidth: '135px'
      }
    }
  ];

  const [headCells, setHeadCells] = React.useState(headCellsDef);

  const handleSort = (property) => {
    const changeSort = headCells?.map((v: any, i) => {
      if (v?.id === property) {
        if (v.order === 'asc') {
          v.order = 'desc';
        } else {
          v.order = 'asc';
        }
        v.active = true;
        setOrderby(v.id);
        setSort(v.order);
      } else {
        v.order = 'asc';
        v.active = false;
      }
      return v;
    });
    setHeadCells([...changeSort]);
  };
  //  table header end
  const onHandleDownload = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleDownload = () => {
    exportFile()
      .then(function (response) {
        openCsvExportString(`manual_management.csv`, response.data);
        setAnchorEl(null);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //  table header end
  const onHandleSearch = (searchData) => {
    setSearchData(searchData);
    setPage(0);
  };

  return (
    <Box className="root-m">
      <TitleWithSearch title={title} setSearchFn={onHandleSearch} onclick={onHandleDownload} />
      <TableContainer component={Paper} className={classes.tableWrap}>
        <Table className={classes.table} aria-label="visitor detail">
          <EnhancedTableHead handleSort={handleSort} headCells={headCells} />
          <TableBody>
            {rows?.map((row: any) => (
              <TableRow key={row?.id} className={row.isRecordAgree && classes.tableRow}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
                <TableCell align="left">{formatDate(row.createdAt)}</TableCell>
                <TableCell align="left">{formatDate(row.updatedAt)}</TableCell>
                <TableCell align="center">
                  <EditIcon
                    style={{ marginRight: '15px', cursor: 'pointer' }}
                    onClick={() => onHandleEditClick(row?.id)}
                  />
                  <DownloadIcon
                    style={{ marginRight: '15px', cursor: 'pointer' }}
                    onClick={() => onDownloadClick(row?.id, row?.name)}
                  />
                  <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => onHandleDelClick(row?.id)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, { label: 'All', value: totalSize }]}
                count={totalSize}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                labelRowsPerPage={''}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <AddBtn
        title={AddDialog.title}
        data={AddDialog.data}
        handleSubmit={AddDialog.handleSubmit}
        init={AddDialog.init}
        open={addDialogOpen}
        setOpen={setAddDialogOpen}
      />

      <DialogOnly
        title={EditDialog.title}
        data={EditDialog.data}
        handleSubmit={EditDialog.handleSubmit}
        open={editDialogOpen}
        setOpen={handleEditDialogClose}
      />
      <DownloadDialog anchorEl={anchorEl} setAnchorEl={setAnchorEl} downloadFile={handleDownload} />
      <DeleteDialog open={delOpen} setOpen={setDelOpen} handleDelete={handleDelete} modalName="マニュアル" />
    </Box>
  );
};

export default ManualManagement;
