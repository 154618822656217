import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  FormControl,
  Checkbox
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DropzoneDialog } from 'material-ui-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import RadioButtonCheckedSharpIcon from '@material-ui/icons/RadioButtonCheckedSharp';
import PreviewPdf from '../ShowPdf';
import PrePDF from '../PrePDF';
import EventBus from '../../components/EventBus';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '56px',
      height: '56px',
      border: '2px',
      borderRadius: '50%',
      fontSize: '30px',
      bottom: '0px',
      right: '0px',
      margin: '50px',
      position: 'fixed',
      backgroundColor: '#303f9f',
      color: '#fff',
      cursor: 'pointer',
      padding: '6px',
      textAlign: 'center'
    },
    email: {
      marginBottom: theme.spacing(3)
    },
    role: {
      width: '100%'
    },
    input: {
      marginBottom: theme.spacing(2)
    },
    button: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    fieldset: {
      borderColor: theme.palette.divider,
      borderBlockEndStyle: 'solid',
      padding: `${theme.spacing(2)}px 5px`
    },
    radio: {
      width: '50%'
    },
    url: {
      marginTop: theme.spacing(2)
    },
    link: {
      marginBottom: theme.spacing(5)
    },
    references: {
      marginBottom: theme.spacing(2)
    },
    fields: {
      width: '60%'
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120
    },
    span: {
      whiteSpace: 'nowrap',
      display: 'inline-block',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    legend: {
      padding: '5px',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '1',
      letterSpacing: '0.00938em'
    }
  })
);

const AddBtn = (props) => {
  const { open, setOpen, data, title, handleSubmit, init } = props;
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  const [openDropZone, setOpenDropZone] = React.useState(false);
  const [uploadLocal, setUploadLocal] = React.useState(false);

  const handleClickOpen = () => {
    init();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // cur input text
  const [curInput, setCurInput] = React.useState('');


  const TextInput = (props) => {
    const {
      name,
      label,
      required,
      disabled = false,
      errors = false,
      helperText = '',
      placeholder,
      uref,
      value
    } = props;
    const [value1, setValue] = React.useState(value);
    const handleChange1 = (params) => {
      setValue(params);
    };
    return (
      <TextField
        id={`${name}+'_id'`}
        className={classes.email}
        variant="outlined"
        // autoFocus={curInput ? (curInput === name ? true : false) : false}
        fullWidth
        required={required}
        name={name}
        label={label}
        value={value1}
        disabled={disabled}
        inputRef={uref}
        // onBlur={handleBlur}
        onChange={(e) => handleChange1(e.target.value)}
        error={errors}
        helperText={helperText}
        placeholder={placeholder}
      />
    );
  };

  const SelectInput = (props) => {
    const { label, name, value, handleChange = '', option = [], handleBlur = '' } = props;
    return (
      <Box className={classes.role}>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          labelId={label}
          name={name}
          label={label}
          value={value}
          disabled={false}
          onBlur={handleBlur}
          onChange={(v) => {
            handleChange(v.target.value);
            setCurInput('');
          }}
          fullWidth
          variant="outlined"
        >
          {option?.map((v: any, i) => {
            return (
              <MenuItem value={v?.value} key={i}>
                {v?.text}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
    );
  };

  const UploadInput = (props) => {
    const { fieldLabel, fieldName, value, onSave } = props;
    return (
      <div>
        <Box display="flex" alignItems="top">
          <Box flexGrow={1}>
            <TextField
              autoFocus={true}
              fullWidth
              variant="outlined"
              label={fieldLabel}
              placeholder={fieldLabel}
              name={fieldName}
              value={value}
              disabled={true}
              // onBlur={handleBlur}
              // onChange={handleChange}
              onClick={() => {
                setOpenDropZone(true);
              }}
              error={false}
              helperText={''}
              className={classes.input}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDropZone(true)}
              className={classes.button}
              disabled={false}
            >
              選択
            </Button>
          </Box>
        </Box>

        <DropzoneDialog
          acceptedFiles={['.pdf', 'application/pdf']}
          dialogTitle="アップロード"
          dropzoneText="ここにファイルをドラッグアンドドロップするか、クリックします。"
          previewText="プレビュー:"
          getFileLimitExceedMessage={() => 'ファイルの追加数が上限を超えてます。(1つまで許可)'}
          getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
            if (!acceptedFiles.includes(rejectedFile.type)) {
              return 'Pdfファイルをアップロードしてください。(ファイル形式不正)';
            }

            if (rejectedFile.size > maxFileSize) {
              return `${rejectedFile.name}のアップロードサイズ上限を超過しています。10MByte以下に設定してください。`;
            }

            return 'ファイルのアップロードに失敗しました。';
          }}
          showAlerts={['error']}
          filesLimit={1}
          cancelButtonText={'キャンセル'}
          submitButtonText={'アップロード'}
          maxFileSize={10240000}
          open={openDropZone}
          onClose={() => {
            setOpenDropZone(false);
            setUploadLocal(false);
          }}
          onSave={(files) => {
            onSave(files[0]);
            setOpenDropZone(false);
            setUploadLocal(true);
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        />
      </div>
    );
  };

  const RadioGInput = (props) => {
    const { fieldName, label, handleChange, value } = props;
    return (
      <fieldset className={classes.fieldset}>
        <legend>
          <Typography variant="h6">{label}</Typography>
        </legend>
        <RadioGroup
          row
          value={value}
          onChange={(v) => {
            handleChange(v.target.value);
            setCurInput('');
          }}
        >
          <FormControlLabel
            name={fieldName}
            className={classes.radio}
            value={'0'}
            control={<Radio color={'primary'} icon={<RadioButtonCheckedSharpIcon />} />}
            label="ニードワード評価"
          />
          <FormControlLabel
            name={fieldName}
            value={'1'}
            control={<Radio color={'primary'} icon={<RadioButtonCheckedSharpIcon />} />}
            label="AI学習"
          />
        </RadioGroup>
      </fieldset>
    );
  };
  const PdfPreview = (props) => {
    const { pdfValue, label, pdfContent } = props;
    return (
      pdfValue && (
        <fieldset className={classes.fieldset}>
          <legend>
            <Typography className={classes.legend}>{label}</Typography>
          </legend>
          <PrePDF
            pre={0}
            name="all"
            local={true}
            file={uploadLocal ? pdfContent : pdfValue}
            handleOpen={() => { }}
          ></PrePDF>
        </fieldset>
      )
    );
  };

  const RefBox = (props) => {
    const [pdfName, setPdfName] = React.useState('');
    const [pdfFileOpen, setPdfFileOpen] = useState(false);
    const { references, fileSets, handleChange, pdfContent } = props;
    const separator = ' / ';
    let [refs, setRefs] = useState(
      references.map((v, i) => {
        return { num: v.description?.split(';')[0], description: v.description?.split(';')[1], url: v.url };
      })
    );

    const filterOptions = createFilterOptions({
      matchFrom: 'any',
      stringify: (option: string) => option + fileSets.find((fileSet) => option === fileSet.name)?.description
    });

    const renderOption = (option: string) => {
      const desc = fileSets.find((fileSet) => option === fileSet.name)?.description;
      if (!desc) return option;
      return option + separator + desc;
    };

    const onChange = (k, v, i) => {
      let ref = [...refs];
      ref[i][k] = v;
      handleChange(
        ref.map((v, i) => {
          return { description: v?.num + ';' + v?.description, url: v?.url };
        })
      );
    };
    const handlehrefClick = (name) => {
      setPdfName(name);
      if (!name) {
        EventBus.dispatch('show_snack', { message: 'ファイルを選択ください。', type: 'error' });
      } else {
        setPdfFileOpen(true);
      }
    };
    return (
      <fieldset className={classes.fieldset}>
        <legend>
          <Typography variant="h6">参考フリップ</Typography>
        </legend>
        <>
          {refs?.map((desc, index) => {
            return (
              <div key={index} className={classes.link}>
                <Box display="flex" alignItems="top" className={classes.references}>
                  <Box flexGrow={1}>
                    <TextField
                      variant="outlined"
                      label="ページ数"
                      placeholder="ページ数"
                      name={`references.${index}.numPages`}
                      value={desc?.num}
                      type="number"
                      disabled={desc?.url === ''}
                      InputProps={{
                        inputProps: {
                          min: 0
                        }
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      autoFocus={curInput ? (curInput === `references.${index}.numPages` ? true : false) : false}
                      onChange={(e) => {
                        onChange('num', e.target.value, index);
                        setCurInput(`references.${index}.numPages`);
                      }}
                    />
                  </Box>
                  <Box>
                    <IconButton
                      disabled={false}
                      onClick={() => {
                        refs.splice(index, 1);
                        handleChange(
                          refs.map((v, i) => {
                            return { description: v.num + ';' + v.description, url: v.url };
                          })
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>

                <TextField
                  className={classes.fields}
                  variant="outlined"
                  label="説明文"
                  placeholder="説明文"
                  name={`references.${index}.description`}
                  value={desc.description}
                  disabled={false}
                  autoFocus={curInput ? (curInput === `references.${index}.description` ? true : false) : false}
                  onChange={(e) => {
                    onChange('description', e.target.value, index);
                    setCurInput(`references.${index}.description`);
                  }}
                />

                <Box display="flex" alignItems="top">
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    <Autocomplete
                      disabled={false}
                      filterOptions={filterOptions}
                      id={`combo-url-${index}`}
                      value={desc.url}
                      getOptionSelected={(option, value) => {
                        if (!value || fileSets.map((f) => f.name).find((url) => url === value)) {
                          return option === value;
                        } else {
                          return option !== value;
                        }
                      }}
                      onChange={(_event: any, option: string | null) => {
                        onChange('url', option, index);
                      }}
                      renderOption={(option) => <span className={classes.span}>{renderOption(option)}</span>}
                      options={[...fileSets?.map((f) => f.name).sort(), '']}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="ファイル"
                            variant="outlined"
                            name={`references.${index}.url`}
                            // error={(uriTouched && !!uriError)}
                            disabled={false}
                          />
                        );
                      }}
                    />
                  </FormControl>
                  <Box style={{ marginTop: '20px' }}>
                    <IconButton disabled={false} onClick={() => handlehrefClick(desc.url)}>
                      <ImageOutlinedIcon />
                    </IconButton>
                  </Box>
                </Box>
              </div>
            );
          })}
          <div>
            <Button
              fullWidth
              onClick={() => {
                refs.push({ description: '', url: '' });
                setRefs([...refs]);
              }}
              variant="contained"
              color="secondary"
              disabled={false}
              disableElevation
            >
              追加
            </Button>
          </div>
          <PreviewPdf
            open={pdfFileOpen}
            setOpen={setPdfFileOpen}
            local={true}
            pdfValue={uploadLocal ? pdfContent : pdfName}
          />
        </>
      </fieldset>
    );
  };

  const CheckBoxComponent = (props) => {
    const { label, value, uref } = props;
    const [curChecked, setCurChecked] = React.useState(value);
    return (
      <Box>
        <FormControlLabel
          control={<Checkbox color="primary" checked={curChecked} onClick={() => setCurChecked(!curChecked)} />}
          label={label}
          inputRef={uref}
        />
      </Box>
    );
  };

  return (
    <>
      <Box className={classes.root} onClick={handleClickOpen}>
        +
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {data.map((v: any, i) => {
            if (v.type === 'input') {
              return <TextInput key={i} {...v} />;
            } else if (v.type === 'select') {
              return <SelectInput key={i} {...v} />;
            } else if (v.type === 'upload') {
              return <UploadInput key={i} {...v} />;
            } else if (v.type === 'radio') {
              return <RadioGInput key={i} {...v} />;
            } else if (v.type === 'ref') {
              return <RefBox key={i} {...v} />;
            } else if (v.type === 'checkBox') {
              return <CheckBoxComponent key={i} {...v} />;
            } else if (v.type === 'pdfPreview') {
              return <PdfPreview key={i} {...v} />;
            } else {
              console.log('invalid type', v.type);
            }
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            color="primary"
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddBtn;
