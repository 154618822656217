import service from './service';

export function getKeyWordsList(parameter) {
  const url =
    parameter.pageSize === -1
      ? `/keywords/search?orderby=${parameter.orderby}&sort=${parameter.sort}`
      : `/keywords/search?pagesize=${parameter.pageSize}&page=${parameter.page}&orderby=${parameter.orderby}&sort=${parameter.sort}`;
  return service({
    url: url,
    method: 'post',
    data: {
      keyWords: parameter.words
    }
  });
}

export function addKeyWords(parameter) {
  return service({
    url: `/keywords`,
    method: 'post',
    data: {
      type: parameter.category,
      label: parameter.label,
      phrases: parameter.words,
      sortOrder: parameter.sortOrder,
      condition: parameter.condition,
      descriptions: ''
    }
  });
}

export function editKeyWords(parameter) {
  return service({
    url: `/keywords/${parameter.id}`,
    method: 'put',
    data: {
      type: parameter.category,
      label: parameter.label,
      phrases: parameter.words,
      sortOrder: parameter.sortOrder,
      condition: parameter.condition,
      descriptions: ''
    }
  });
}

export function deleteKeyWords(parameter) {
  return service({
    url: `/keywords/${parameter.id}`,
    method: 'delete'
  });
}

export function exportFile(parameter) {
  return service({
    url: `/keywords/data/export`,
    method: 'get'
  });
}
