import { NativeSelect, InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledNativeSelect = withStyles({
  select: {
    cursor: 'pointer',
    minWidth: '16px',
    userSelect: 'none',
    borderRadius: 0,
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    paddingLeft: '20px',
  },
})(NativeSelect);


const NativeSelectWrap = (props) => {
  return (
    <StyledNativeSelect {...props} input={<InputBase />} />
  )
}

export default NativeSelectWrap
