import React, { useState } from 'react';
import { createStyles, makeStyles, Theme, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import InputDelIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    dateRoot: {
      width: '100%',
      marginTop: '8px',
      minWidth: '150px'
    },
    timeRoot: {
      width: '230px'
    },
    dateTimeRoot: {
      width: '230px'
    },
    inputDel: {
      cursor: 'pointer',
      marginRight: '-10px',
      opacity: '0.3',
      '&:hover': {
        opacity: '0.5'
      }
    }
  })
);

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        border: 0
      }
    }
  }
});

const DatePickerDef = (props) => {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(null);
  const [selectedTime, handleTimeChange] = useState(null);
  const [selectedDateTime, handleDateTimeChange] = useState(null);
  let defaultValue = props?.defaultValue ? props?.defaultValue : null;
  // if (props?.defaultValue && !selectedDate) {
  //   props?.setDate(props?.defaultValue);
  // }
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {props.type === 'date' && (
          <KeyboardDatePicker
            disableToolbar
            format="yyyy/MM/dd"
            margin="normal"
            value={selectedDate ? selectedDate : defaultValue}
            onChange={(newDate: any) => {
              handleDateChange(newDate);
              props?.setDate(newDate);
            }}
            inputVariant="outlined"
            variant="inline"
            InputProps={{
              className: props?.style,
              readOnly: true
            }}
            className={classes.dateRoot}
            KeyboardButtonProps={{
              'aria-label': 'change date',
              style: { padding: 'unset' }
            }}
            invalidDateMessage={"日時形式正しくありません"}
          />
        )}

        {props.type === 'time' && (
          <TextField
            type="time"
            value={selectedTime ? selectedTime : defaultValue}
            defaultValue={props?.defaultValue}
            onChange={(newDate: any) => {
              handleTimeChange(newDate.target.value);
              props?.setDate(newDate.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              className: props?.style
            }}
            variant="outlined"
            className={classes.timeRoot}

          />
        )}
        {props.type === 'datetime' && (
          <DateTimePicker
            value={selectedDateTime}
            onChange={(newDate: any) => {
              handleDateTimeChange(newDate);
              props?.setDate(newDate);
            }}
            inputVariant="outlined"
            variant="inline"
            format="yyyy/MM/dd HH:mm"
            InputProps={{
              endAdornment: (
                <InputDelIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDateTimeChange(null);
                    props?.setDate(null);
                  }}
                  className={classes.inputDel}
                />
              ),
              className: props?.style
            }}
            className={classes.dateTimeRoot}
          />
        )}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DatePickerDef;
