import React, { useState, useEffect } from 'react';
import { Chart, Legend, LineAdvance, Tooltip, Axis, Slider } from 'bizcharts';
import withWrap from '../../withWrap';
import { changeMinutesFormat } from '../../../../../utils/General'

const TalkListenChart: React.FC<TalkListenChartProps> = (props): JSX.Element => {
  const [data, setData] = useState<any[]>([]);
  const [maX, setMaX] = useState(0)
  useEffect(() => {
    const { talk, listen } = props?.data;
    let formattedData = new Array();
    const getFormatListenData = () => {
      let finalizeArray = new Array();
      let startMinute = 0;
      finalizeArray.push({
        id: "Listen",
        color: "#00A5D9",
        x: 0,
        y: -0.2
      })
      listen?.map((item, i) => {
        let minutesFormatArr = changeMinutesFormat(startMinute, item.interval, item.data.length);
        item.data.map((subItem, index) => {
          finalizeArray.push({
            id: "Listen",
            color: "#00A5D9",
            x: minutesFormatArr[index],
            y: -(subItem + 0.2)
          })
          if (listen.length - 1 === i && item.data.length - 1 === index) {
            setMaX(pre => pre >= minutesFormatArr[index] ? pre : minutesFormatArr[index])
          }
        })
        startMinute += 2;
      })
      return finalizeArray;
    }
    const getFormatTalkData = () => {
      let finalizeArray = new Array();
      let startMinute = 0;
      finalizeArray.push({
        id: "Talk",
        color: "#D77900",
        x: 0,
        y: 0.2
      })
      talk?.map((item, i) => {
        let minutesFormatArr = changeMinutesFormat(startMinute, item.interval, item.data.length);
        item.data.map((subItem, index) => {
          finalizeArray.push({
            id: "Talk",
            color: "#D77900",
            x: minutesFormatArr[index],
            y: (subItem + 0.2)
          })
          if (talk.length - 1 === i && item.data.length - 1 === index) {
            setMaX(pre => pre >= minutesFormatArr[index] ? pre : minutesFormatArr[index])
          }
        })
        startMinute += 2;
      })
      // setMaX(pre => pre >= startMinute ? pre : startMinute)
      return finalizeArray;
    }

    formattedData = getFormatTalkData().concat(getFormatListenData())
    setData([...formattedData])
  }, [props?.data]);

  const cols = {
    y: {
      min: -1.5,
      max: 1.5,
    },
    x: {
      min: 0,
      max: 18,
      tickInterval: 2,
    }
  };
  const genXshow = (t) => {
    const _t = t ? parseInt((Number(t) * 60).toString()) : 0
    const h = parseInt((_t / 3600).toString())
    const yh = _t % 3600
    const m = parseInt((yh / 60).toString())
    const ym = yh % 60
    return h ? `${h}時${m}分${ym}秒` : m ? ym !== 0 ? `${m}分${ym}秒` : `${m}分` : `${ym}秒`
  }

  return (
    <Chart autoFit padding={[50, 30, 60, 25]} height={300} data={data}
      scale={maX >= 15 ?
        {
          y: {
            min: -1.5,
            max: 1.5,
          },
          x: {
            min: 0,
            tickInterval: 2,
          }
        } :
        cols}>
      <Legend position="top-left" marker={{ symbol: 'diamond' }} />
      <LineAdvance
        shape="smooth"
        point
        area
        position="x*y"
        color={['id', (val) => (val === 'Talk' ? '#00A5D9' : '#e98300')]}
      />
      <Tooltip shared showMarkers showCrosshairs title={(title: string) => genXshow(title)} />
      <Axis
        name="x"
        label={{ formatter: (label: string) => genXshow(label) }}
        grid={{
          alignTick: true,
          line: { type: 'line', style: { lineWidth: 1, lineDash: [4, 4], stroke: '#D9D9D9FF' } }
        }}
      />
      <Axis
        name="y"
        label={null}
      />
      <Slider start={0} end={1} padding={[0, 0, 0, 0]} formatter={(val: string) => genXshow(val)} />
    </Chart>
  );
};

interface TalkListenChartProps {
  data?: any;
}

export default withWrap<TalkListenChartProps>({
  styled: true
})(TalkListenChart);
