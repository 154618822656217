import React, { useState } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Title from './DetailTitle';
import ExpressBtn from './ExpressBtn';
import Carousel from './Carousel';
import NewWindow from 'react-new-window'
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      border: '1px solid #ccc',
      borderRadius: '10px',
      backgroundColor: '#FFFFFF',
      padding: '5px 25px'
      // paddingTop:'-15px'
    },
    translateBox: {
      height: '100%',
      marginBottom: '10px',
      width: '100%',
      position: 'relative',
      paddingBottom: '30px'
    },
    contant: {
      height: '100%',
      overflowY: 'scroll',
      position: 'relative',
      padding: '15px'
    },
    fullScreen: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      zIndex: 6666,
      borderRadius: 0
    },
    img: {
      height: 255,
      display: 'block',
      maxWidth: 400,
      overflow: 'hidden',
      width: '100%'
    }
  })
);

const DataCollection: React.FC<DataCollectionProps> = (props: DataCollectionProps): JSX.Element => {
  const { set, setMax, header } = props;
  const classes = useStyles();
  const [popTranslate, setPopTranslate] = useState(false);
  const [connect, setConnect] = useState(false);
  const [stopData, setStopData] = useState<any[]>([]);
  const handleCollectMax = () => {
    // const openBaseUrl = curBaseUrl(location) + "/#";
    // const curCom = `/app/translatemax?type=collection`;
    // const w = window.innerWidth / 3;
    // const h = window.innerHeight;
    // const wFocus = window.open(openBaseUrl + curCom, '', `height=${h}, width=${w}, top=0, left=${w}`);
    // wFocus?.focus();
    setPopTranslate(true)
  };

  const handleSwitchChange = (c) => {
    setConnect(c);
    if (!c) {
      setStopData(props?.data)
    }
  }

  return (
    !set ?
      <>
        <Box className={classes.translateBox}>
          <Title text={!header ? 'データ集' : ''} Max={!setMax} FnMax={() => handleCollectMax()} connect={connect} setCon={handleSwitchChange} />
          <Box className={classes.contant}>
            <Carousel data={props?.data} />
          </Box>
        </Box>
        {
          popTranslate && <NewWindow
            title={'データ集'}
            center={'screen'}
            copyStyles={true}
            onUnload={() => {
              setPopTranslate(false)
            }}
          >
            <div className={setMax ? [classes.root, classes.translateBox, classes.fullScreen].join(' ') : ''}>
              <Title text={!header ? 'データ集' : ''} Max={!setMax} FnMax={() => { }} />
              <Box className={classes.contant}>
                <Carousel data={connect ? props?.data : stopData} />
              </Box>
            </div>
          </NewWindow>
        }
      </> :
      <>
        <span className="remove">データ集</span>
        <Divider />
        <Grid container xs={12} spacing={2} style={{ padding: '15px' }}>
          <Grid item xs={6}>
            <Typography>ヘッダー表示</Typography>
            <ExpressBtn
              express={!props?.header}
              type="dis"
              comIndex={props?.settingIndex}
              layouts={props?.layouts}
              layoutFun={props?.layoutFun}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography>最大化</Typography>
            <ExpressBtn
              express={!props?.setMax}
              type="max"
              comIndex={props?.settingIndex}
              layouts={props?.layouts}
              layoutFun={props?.layoutFun}
            />
          </Grid>
        </Grid>
      </>
  );
};

interface DataCollectionProps {
  Max?: boolean;
  data?: any;
  set?: any;
  setMax?: boolean;
  header?: boolean;
  settingIndex?: any;
  layouts?: any;
  layoutFun?: any;
}

export default DataCollection;
