import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: '5px 24px'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(9),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface HeadProps extends WithStyles<typeof styles> {
  id: string;
  onClose: () => void;
}

const ModalHeadButton = withStyles(styles)((props: HeadProps) => {
  const { onClose } = props;
  return (
    <Grid container direction="row" justify="flex-end" alignItems="flex-start">
      <IconButton aria-label="close" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Grid>
  );
});

export default ModalHeadButton;
