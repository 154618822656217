import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TablePaginationActions from '../../../components/TablePaginationActions';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteSynonymDialog from '../../../components/management/DeleteDialog';
import DownloadDialog from '../../../components/management/DownloadDialog';
import { getSynonymList, addSynonym, deleteSynonym, editSynonym, exportFile } from '../../../api/synonymsApi';
import AddSynonymBtn from '../../../components/management/AddSynonymBtn';
import TitleWithSearch from '../../../components/management/TitleWithSerach';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import { formatDate, openCsvExportString } from '../../../utils/ExportUtil';
import SynonymSetDialog from './components/SynonymSetDialog';

interface SynonymChangeSet {
  words: string[];
}

//  table header begin
const headCellsDef = [
  {
    id: 'words', // db column name
    disablePadding: false,
    label: '単語', // table header column name
    active: false, // mouseout display is none except current is cliked
    order: 'asc', // default sort
    sorted: true // this column sort or not
  },
  {
    id: 'updated_by',
    disablePadding: false,
    label: '編集者',
    active: false,
    order: 'asc',
    sorted: true
  },
  {
    id: 'created_at',
    disablePadding: false,
    label: '作成',
    active: false,
    order: 'asc',
    sorted: true
  },
  {
    id: 'updated_at',
    disablePadding: false,
    label: '更新',
    active: false,
    order: 'asc',
    sorted: true
  },
  {
    id: 'action',
    disablePadding: false,
    label: 'アクション',
    active: false,
    order: 'asc',
    sorted: false,
    style: {
      minWidth: '100px'
    }
  }
];

const useStyles = makeStyles(() =>
  createStyles({
    titleSearch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    titleText: {
      fontWeight: 800,
      fontSize: '20px'
    },
    inputDel: {
      cursor: 'pointer',
      marginRight: '5px',
      opacity: '0.3',
      '&:hover': {
        opacity: '0.5'
      }
    },
    tableWrap: {
      alignItems: 'center',
      marginTop: '30px',
      boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)',
      borderRadius: '0px'
    },
    table: {
      width: '100%'
    },
    tableCellAction: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  })
);

const Dictmanagement = (props) => {
  const classes = useStyles();
  // const navigate = useNavigate();

  const [rows, setRows] = useState([]);

  // cur page
  const [page, setPage] = React.useState(0);
  const [cPageChange, setcPageChange] = React.useState(false);
  //
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [totalCount, setTotalCount] = React.useState(0);

  const [orderby, setOrderby] = useState('');

  const [sort, setSort] = useState('');

  const [open, setOpen] = React.useState(false);

  const [id, setId] = React.useState(0);

  const [type, setType] = React.useState('');

  const [editData, setEditData] = React.useState([]);

  const [headCells, setHeadCells] = React.useState(headCellsDef);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [searchData, setSearchData] = useState(null);

  const handleSort = (property) => {
    const changeSort = headCells?.map((v: any, i) => {
      if (v?.id === property) {
        if (v.order === 'asc') {
          v.order = 'desc';
        } else {
          v.order = 'asc';
        }
        setOrderby(property);
        setSort(v.order);
        v.active = true;
      } else {
        v.active = false;
      }
      return v;
    });
    setHeadCells([...changeSort]);
  };

  // // get data from db
  function getformData(orderby, sort, page, rowsPerPage, searchData) {
    getSynonymList({
      type: 'product',
      orderby: orderby,
      sort: sort,
      words: searchData,
      pageSize: rowsPerPage,
      page: page
    })
      .then(function (response) {
        if (response.status === 200) {
          setRows(response.data.synonyms);
          setTotalCount(response.data.total);
          setcPageChange(false)
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (searchData && page) {
      //
      if (cPageChange) {
        getformData(orderby, sort, page, rowsPerPage, searchData);
      }
    } else {
      getformData(orderby, sort, page, rowsPerPage, searchData);
    }
  }, [orderby, sort, open, page, rowsPerPage, searchData, cPageChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setcPageChange(true)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setcPageChange(true)
  };

  const onHandleEditClick = (words, id) => {
    setOpen(true);
    setType('Edit');
    setId(id);
    setEditData(words);
  };

  const onHandleDelClick = (params) => {
    setOpen(true);
    setId(params);
    setType('Delete');
  };

  const onHandleAddClick = () => {
    setOpen(true);
    setType('Add');
    setEditData([]);
  };

  const onHandleSearch = (searchData) => {
    setSearchData(searchData);
    setPage(0);
  };

  const onHandleDownload = (e) => {
    setAnchorEl(e.currentTarget);
    setType('Download');
  };

  const handleDelete = () => {
    deleteSynonym({ type: 'product', id: id })
      .then(function (response) {
        if (response.status === 204) {
          setOpen(false);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSave = (changeSet: SynonymChangeSet) => {
    if (type === 'Add') {
      addSynonym({ type: 'product', words: changeSet.words })
        .then(function (response) {
          if (response.status === 200) {
            setOpen(false);
          } else {
            console.log('code error');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      editSynonym({
        type: 'product',
        words: changeSet.words,
        id: id
      })
        .then(function (response) {
          if (response.status === 200) {
            setOpen(false);
          } else {
            console.log('code error');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleDownload = () => {
    const csvName = type === 'contactcenter' ? 'acc' : 'products';
    exportFile({
      type: 'product'
    })
      .then(function (response) {
        openCsvExportString(`synonym_management.csv`, response.data);
        setAnchorEl(null);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <Box className="root-m">
      <TitleWithSearch title="同意語" setSearchFn={onHandleSearch} onclick={onHandleDownload} />
      <TableContainer component={Paper} className={classes.tableWrap}>
        <Table className={classes.table} aria-label="visitor detail">
          <EnhancedTableHead handleSort={handleSort} headCells={headCells} />
          <TableBody>
            {rows?.map((row: any) => (
              <TableRow key={row?.id}>
                <TableCell align="left">
                  {row.words.map((word, index) => (
                    <span key={word}>
                      {word}
                      {index < row.words.length - 1 ? <span>&nbsp; </span> : null}
                    </span>
                  ))}
                </TableCell>
                <TableCell align="left">{row.updatedBy}</TableCell>
                <TableCell align="left">{formatDate(row.createdAt)}</TableCell>
                <TableCell align="left">{formatDate(row.updatedAt)}</TableCell>
                <TableCell align="center">
                  <EditIcon
                    style={{ marginRight: '15px', cursor: 'pointer' }}
                    onClick={() => onHandleEditClick(row?.words, row?.id)}
                  />
                  <DeleteIcon style={{ cursor: 'pointer' }} onClick={() => onHandleDelClick(row?.id)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, { label: 'All', value: totalCount }]}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                labelRowsPerPage={''}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {type === 'Delete' && (
        <DeleteSynonymDialog open={open} setOpen={setOpen} handleDelete={handleDelete} modalName="辞書" />
      )}
      {(type === 'Add' || type === 'Edit') && (
        <SynonymSetDialog open={open} setOpen={setOpen} onSave={handleSave} type={type} synonymSetData={editData} />
      )}
      {type === 'Download' && (
        <DownloadDialog anchorEl={anchorEl} setAnchorEl={setAnchorEl} downloadFile={handleDownload} />
      )}
      <AddSynonymBtn onClick={onHandleAddClick} />
    </Box>
  );
};

export default Dictmanagement;
