import { memo } from 'react';
import { Button, Popover } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      textTransform: 'none',
      paddingTop: '9px',
      paddingBottom: '9px'
    }
  })
);

function DownloadDialog(props) {
  const classes = useStyles();
  const { anchorEl, setAnchorEl, downloadFile } = props;

  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
    >
      <Button onClick={downloadFile} disabled={false} disableRipple className={classes.button}>
        Export as CSV
      </Button>
    </Popover>
  );
}

export default memo(DownloadDialog);
