import service from './service';
export function getSynonymList(parameter) {
  const url = parameter.pageSize === -1 ?
    `/synonyms/${parameter.type}/search?orderby=${parameter.orderby}&sort=${parameter.sort}` :
    `/synonyms/${parameter.type}/search?pagesize=${parameter.pageSize}&page=${parameter.page}&orderby=${parameter.orderby}&sort=${parameter.sort}`

  return service({
    url: url,
    method: 'post',
    data: {
      keyWords: parameter.words
    }
  });
}

export function addSynonym(parameter) {
  return service({
    url: `/synonyms/${parameter.type}`,
    method: 'post',
    data: {
      words: parameter.words
    }
  });
}

export function editSynonym(parameter) {
  return service({
    url: `/synonyms/${parameter.type}/${parameter.id}`,
    method: 'put',
    data: {
      words: parameter.words
    }
  });
}

export function deleteSynonym(parameter) {
  return service({
    url: `/synonyms/${parameter.type}/${parameter.id}`,
    method: 'delete'
  });
}

export function exportFile(parameter) {
  return service({
    url: `/synonyms/${parameter.type}/data/export`,
    method: 'get'
  });
}

