import Moment from 'moment';
import moment from 'moment';

export function formatStrToData(str) {
  return Moment(str, 'YYYY-MM-DD HH:mm:ss')
}

export function formatDateToYYMMDD(date) {
  return date ? Moment(date).format('YYYY-MM-DD') : ''
}

export function formatTimeToHHmm(date) {
  return date ? Moment(date).format('HH:mm:ss') : ''
}

export function formatUtcStringToLocal(str) {
  const gmtDateTime = str ? moment(str).local().format('YYYY-MM-DD HH:mm') : null
  return gmtDateTime
}

export function formatUtcStringToLocalString(str) {
  const localData = formatUtcStringToLocal(str)
  return localData ? Moment(localData).format('YYYY-MM-DD HH:mm') : ''
}

export function formatUtcStringToYYMMDD(str) {
  const date = formatUtcStringToLocal(str)
  const YYMMDD = formatDateToYYMMDD(date)
  return YYMMDD
}

export function formatUtcStringToHHmm(str) {
  const date = formatUtcStringToLocal(str)
  const HHmm = formatTimeToHHmm(date)
  return HHmm
}

export function changeDateformat(date) {
  return date.replaceAll('-', '/')
}

export function getLastString(pdf, syntax = '/') {
  let pos = pdf.lastIndexOf(syntax);
  return pdf.substring(pos + 1, pdf.length)

}

export function geFileExtension(filename) {
  return filename.split('.').pop();
}

export function padLeadingZeros(num, size) {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

export function changeLeadingZeroInTime(data) {
  let pos = data.lastIndexOf(':');
  let min = data.substring(0, pos - 1)
  let sec = data.substring(pos + 1)
  min = padLeadingZeros(min, 2)
  sec = padLeadingZeros(sec, 2)
  return min + ":" + sec;



}
export function timeFormat(mmss) {
  //no need this transformation in UI going forward
  return mmss;
}

export function stringFormat(str) {
  var split_string = str && str.split('(');
  var result = split_string && split_string.map((value, key) => <span key={key}>{key !== 0 ? "(" : ""}{value}<br /></span>)
  return result;
}

export function split(url, syntax = ',') {
  return url?.split(syntax)
}

export function longestString(arr) {
  var longest = arr && arr.reduce(function (a, b) { return a.name.length > b.name.length ? a : b; }).name.length;
  return longest;
}

export function max(arr) {
  var max = arr && arr.reduce(function (a, b) { return parseInt(a.name) > parseInt(b.name) ? a : b; }).name;
  return parseInt(max);
}

export function defaultCriteriaOption(value) {
  switch (value) {
    case 1:
      return [{ id: 1, name: "ー" }, { id: 2, name: "x" }, { id: 4, name: "〇" }];
    case 2:
      return [{ id: 2, name: "x" }, { id: 4, name: "〇" }];
    case 3:
      return [{ id: 2, name: "x" }, { id: 3, name: "△" }, { id: 4, name: "〇" }];
    case 4:
      return [{ id: 3, name: "△" }, { id: 4, name: "〇" }];
    default:
      break;
  }
}

export function getASCodeCompany(ascode) {
  if (ascode !== undefined) {
    var split_company_name = ascode.split('/');
    return split_company_name;
  } else {
    return ['', ''];
  }

}

export function formatYearMonth(data) {
  if (data !== null && data !== 0) {
    data = data.toString();
    var year = parseInt(data.slice(0, 4));
    var month = parseInt(data.slice(4));
    return { year: year, month: month }
  } else {
    return { year: 0, month: 0 };
  }

}
export function format(data) {
  if (data !== null) {
    data = data.toString();
    var year = parseInt(data.slice(0, 4));
    var month = parseInt(data.slice(4));
    return { year: year, month: month }
  } else {
    return { year: 0, month: 0 };
  }

}
export function joinDateFormatYearMonth(data) {
  if (data !== null) {
    data = data.split('-');
    var year = parseInt(data[0]);
    var month = parseInt(data[1]);
    return { year: year, month: month }
  } else {
    return { year: 0, month: 0 };
  }

}
export function joinDateFomat(data) {
  if (data !== null) {
    data = data.split('-');
    var year = parseInt(data[0]);
    var month = padLeadingZeros(parseInt(data[1]), 2);
    return year + '/' + month
  } else {
    return null;
  }
}

export function trainingPlanYearMonthFormat(data) {
  if (data !== null && data !== 0) {
    data = data.toString();
    var year = data.slice(0, 4);
    var month = data.slice(4);
    return year + '/' + month
  } else {
    return null;
  }

}

export function minOrMax(arr, flag = true) {
  var data = 0;
  if (flag) {
    data = arr && arr.reduce(function (a, b) { return parseInt(a.sortingOrder) > parseInt(b.sortingOrder) ? a : b; }).sortingOrder
  } else {
    data = arr && arr.reduce(function (a, b) { return parseInt(a.sortingOrder) < parseInt(b.sortingOrder) ? a : b; }).sortingOrder
  }
  return data;
}

export function curBaseUrl(location) {
  const { pathname } = location;
  let pathList = pathname.split('/');
  pathList.pop();
  pathList.pop();
  return pathList.join('/');
}

export function jsonRemoveNull(j) {
  let filterP = {}
  for (let k in j) {
    if (j[k]) {
      filterP[k] = j[k]
    }
  }
  return filterP
}

export function deleteCookie(name) {
  document.cookie = name + "=; path=/apigw/va2nba/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  document.cookie = name + "=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}


export function changeMinutesFormat(startMinute, interval, dataLength) {
  let data = [];
  let startInterval = 10;
  for (let index = 0; index < dataLength; index++) {
    let minute = 0;
    if (startInterval <= interval) {
      minute = startInterval / 60;
    } else {
      minute = interval / 60;
    }
    data.push(startMinute + minute);
    startInterval += 10;
  }
  return data;
}
