import { memo, useState } from 'react';
import clsx from 'clsx';
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Typography,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  FormControl,
  InputLabel,
  Button
} from '@material-ui/core';
import SearchKeywordModal from './SearchKeywordModal';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import {
  saveTrains,
  updateTrains,
  deleteLinesAnnotation,
  annotationNoFeedback,
  annotationHasFeedback
} from '../../../../api/annotationApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faThumbsDown } from '@fortawesome/free-regular-svg-icons';
import AnnotatedDeleteDialog from './AnnotatedConfirmDialog';

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    color: '#FFFFFF',
    backgroundColor: '#21c79a',
    '&:hover': {
      backgroundColor: '#21c79a'
    }
  }
}))(Button);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: '100%',
      position: 'relative',
      padding: 0,
      textAlign: 'left',
      flexShrink: 50,
      flexGrow: 20,
      fontSize: '16px',
      color: '#222'
    },
    tableRoot: {
      height: '100%',
      maxHeight: '50vh',
      overflowY: 'auto'
    },
    tHead: {
      padding: '8px 10px',
      background: '#f1f1f1',
      textAlign: 'center',
      height: '100%',
      borderRight: '1px solid #eee',
      zIndex: 10,
      color: '#222',
      fontWeight: 'bold',
      wordWrap: 'break-word'
    },
    cell: {
      borderRight: '1px solid #eee',
      position: 'relative',
      padding: '10px 20px',
      verticalAlign: 'top',
      borderBottom: 'none'
    },
    editorBox: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '10px',
      //   position: 'relative',
      padding: '10px 20px',
      verticalAlign: 'top',
      borderBottom: 'none',
      webkitBoxSizing: 'border-box',
      boxSizing: 'border-box'
    },
    taskOperator: {
      color: '#7d7d7d',
      fontWeight: 700,
      borderLeft: '3px solid #7d7d7d',
      width: '90%'
    },
    taskCustomer: {
      color: '#00a5d9',
      fontWeight: 700,
      borderLeft: '3px solid #00a5d9',
      width: '90%'
    },
    recommendBox: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    recommendButton: {
      width: '50px',
      height: '40px',
      background: '#fbfbfb',
      display: 'flex',
      boxSizing: 'border-box',
      padding: '4px',
      marginRight: '0px',
      marginBottom: '8px',
      border: '1px dotted #ccc',
      borderRadius: '3px',
      justifyContent: 'center',
      alignItems: 'center'
    },
    summary: {
      '& .MuiInputBase-input': {
        display: 'inline-block',
        overflow: 'hidden',
        width: '150px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        flexGrow: '2'
      },
      display: 'flex',
      boxSizing: 'border-box',
      width: '220px',
      height: '40px',
      // padding: '10px',
      cursor: 'pointer',
      borderRadius: '3px',
      flexFlow: 'row',
      position: 'relative',
      marginRight: '8px',
      marginBottom: '8px'
    },
    label: {
      background: 'white',
      paddingRight: theme.spacing(0.5),
      color: '#1e90ff',
      '&.Mui-focused': {
        color: 'red'
      }
    },
    tooltipWidth: {
      maxWidth: 200
    },
    button: {
      width: '94px',
      height: '40px',
      boxShadow: '2px 2px 5px rgb(0 0 0 / 30%)',
      borderRadius: '3px',
      marginRight: '12px'
    },
    goodBad: {
      width: '60px',
      display: 'flex',
      justifyContent: 'space-around'
    },
    good: {
      color: 'green',
      fontSize: 16,
      '&:hover': {
        transform: 'scale(1.4)'
      },
      marginRight: '10px'
    },
    bad: {
      color: 'red',
      fontSize: 16,
      '&:hover': {
        transform: 'scale(1.4)'
      }
    },
    nonSelect: {
      display: 'none'
    },
    thumbUpSelected: {
      position: 'relative',
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '20px',
        height: '20px',
        top: '-3px',
        left: '-6px',
        border: '4px double #003cff',
        borderRadius: '50%'
      },
      '&:hover': {
        '&::before': {},
        transform: 'scale(1.2)'
      }
    },
    thumbDownSelected: {
      position: 'relative',
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '20px',
        height: '20px',
        top: '-5px',
        left: '-7px',
        border: '4px double #003cff',
        borderRadius: '50%'
      },
      '&:hover': {
        '&::before': {},
        transform: 'scale(1.2)'
      }
    }
  })
);

const useOutlinedInputStyles1 = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #ccc'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #ccc'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid red',
      background: 'rgba(255,0,0,.08)'
    }
  }
}));

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1e90ff'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1e90ff'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid red',
      background: 'rgba(255,0,0,.08)'
    }
  }
}));

const Annotations = (props: any) => {
  const {
    setAction,
    taData,
    seTaData,
    taIndex,
    annotations,
    curActive,
    setCurActive,
    actionsUpdate
  } = props;
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const outlinedInputClasses1 = useOutlinedInputStyles1();

  const trainId = useSelector((state: RootState) => state.annotation.trainId);
  const [delOpen, setDelOpen] = useState(false);
  const [delIndex, setDelIndex] = useState(-1);

  // annotations.map((v, i) => {
  //   if (v.isSystemAnnotation) {
  //     annotations.unshift(annotations.splice(i, 1)[0]);
  //     return;
  //   }
  // });

  const handleAnnotation = (annotation: any, tbIndex = -1, index = -1) => {
    setAction({
      tbIndex: tbIndex,
      index: index,
      annotation: annotation
    });
  };
  const handleAnnotationDel = (annotation, index) => {
    if (annotation.new) {
      let temTab = taData;
      temTab[taIndex]?.annotations?.splice(index, 1);

      if (parseInt((curActive / 10).toString()) === taIndex) {
        // cur row have active item
        if (curActive > taIndex * 10 + index) {
          const newActive = curActive - 1;
          setCurActive(newActive);
        } else if (curActive === taIndex * 10 + index) {
          setCurActive(-1);
          setAction({
            tbIndex: -1,
            index: -1,
            annotation: {}
          });
        } else {
        }
      }
      actionsUpdate.splice(actionsUpdate.indexOf(taIndex),1)
      seTaData([...temTab]);
    } else {
      deleteLinesAnnotation({
        trainid: trainId,
        lineid: annotation.trainLineId,
        annotationid: annotation.id
      })
        .then(function (response) {
          if (response.status === 204) {
            let temTab = taData;
            temTab[taIndex]?.annotations?.splice(index, 1);
            seTaData([...temTab]);
            setAction({
              tbIndex: -1,
              index: -1,
              annotation: {}
            });
          } else {
            console.log('code error');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    setDelOpen(false);
  };
  // Good/Bad
  const handleSelectedFeedback = (annotation, index, good) => {
    const annotationId = annotation.id ? annotation.id : null;
    if (!annotationId) {
      console.error('[Feedback Error]The annotaion has not id');
      return;
    }
    const feedBackId = annotation.feedback?.id ? annotation.feedback?.id : null;
    const data = {
      productDataId: annotation.productData.id,
      context: annotation.productData.summary,
      good: good
    };
    if (feedBackId) {
      annotationHasFeedback({
        feedBackId: feedBackId,
        data: {
          ...data
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            let temTab = taData;
            temTab[taIndex].annotations[index].feedback = response.data;
            seTaData([...temTab]);
          } else {
            console.log('code error');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      annotationNoFeedback({
        trainid: trainId,
        lineid: annotation.trainLineId,
        annotationid: annotation.id,
        data: {
          ...data
        }
      })
        .then(function (response) {
          if (response.status === 200) {
            let temTab = taData;
            temTab[taIndex].annotations[index].feedback = response.data.feedback;
            seTaData([...temTab]);
          } else {
            console.log('code error');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <>
      {annotations?.map((annotation, index) => (
        <div>
          <Tooltip
            title={annotation?.productData?.summary}
            placement="right"
            arrow
            classes={{ tooltip: classes.tooltipWidth }}
          >
            {annotation?.isSystemAnnotation ? (
              <FormControl variant="outlined" focused={taIndex * 10 + index === curActive ? true : false}>
                <OutlinedInput
                  classes={outlinedInputClasses1}
                  className={classes.summary}
                  value={annotation?.productData?.summary}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      className={clsx(classes.goodBad, {
                        // [classes.nonSelect]: annotation?.feedback?.good === undefined
                      })}
                    >
                      <span
                        className={clsx(classes.good, {
                          [classes.thumbUpSelected]: annotation?.feedback?.good === true
                        })}
                      >
                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          onClick={(e) => {
                            handleSelectedFeedback(annotation, index, true);
                            e.stopPropagation();
                          }}
                        />
                      </span>
                      <span
                        className={clsx(classes.bad, {
                          [classes.thumbDownSelected]: annotation?.feedback?.good === false
                        })}
                      >
                        <FontAwesomeIcon
                          icon={faThumbsDown}
                          onClick={(e) => {
                            handleSelectedFeedback(annotation, index, false);
                            e.stopPropagation();
                          }}
                        />
                      </span>
                    </InputAdornment>
                  }
                  onClick={() => {
                    if (taIndex * 10 + index === curActive) {
                      setCurActive(-1);
                      handleAnnotation({});
                    } else {
                      setCurActive(taIndex * 10 + index);
                      handleAnnotation(annotation, taIndex, index);
                    }
                  }}
                  labelWidth={0}
                  readOnly
                />
              </FormControl>
            ) : (
              <FormControl variant="outlined" focused={taIndex * 10 + index === curActive ? true : false}>
                <InputLabel className={classes.label}> new</InputLabel>
                <OutlinedInput
                  classes={outlinedInputClasses}
                  className={classes.summary}
                  value={annotation?.productData?.summary}
                  endAdornment={
                    <InputAdornment position="end">
                      <CancelIcon
                        onClick={(e) => {
                          setDelOpen(true);
                          setDelIndex(index);
                          e.stopPropagation();
                        }}
                      />
                    </InputAdornment>
                  }
                  labelWidth={40}
                  onClick={() => {
                    if (taIndex * 10 + index === curActive) {
                      setCurActive(-1);
                      handleAnnotation({});
                    } else {
                      setCurActive(taIndex * 10 + index);
                      handleAnnotation(annotation, taIndex, index);
                    }
                  }}
                  readOnly
                />
              </FormControl>
            )}
          </Tooltip>
          <AnnotatedDeleteDialog
            open={delOpen}
            setOpen={setDelOpen}
            handleDelete={(e) => {
              handleAnnotationDel(annotation, delIndex);
              e.stopPropagation();
            }}
            dialogTitle="レコメンドの削除"
            dialogContent="アノテーションしたレコメンドを削除してよろしいですか？"
          />
        </div>
      ))}
    </>
  );
};

const AnnotatedDialogContent = (props) => {
  const classes = useStyles();
  const { product, setAction, actions } = props;

  const trainId = useSelector((state: RootState) => state.annotation.trainId);

  const [searchOpen, setSearchOpen] = useState(false);
  const [tableData, setTableData] = useState(props?.tableData);
  const [hasAnnotations, setHasAnnotations] = useState([]) as any;

  const [lineId, setLineId] = useState(0);
  const [curActive, setCurActive] = useState(-1);
  const [actionsUpdate, setActionsUpdate] = useState<any>([]);

  if (actions?.tbIndex !== -1) {
    const { tbIndex, annotation, active } = actions;
    if (active) {
      if (actionsUpdate.indexOf(tbIndex) > -1) {
      } else {
        let a = actionsUpdate;
        a.push(tbIndex);
        setActionsUpdate(a);
      }
    }
    annotation?.needGroups?.map((v, i) => {
      if (v?.cNewIndex) {
        if (actionsUpdate.indexOf(tbIndex) > -1) {
        } else {
          let a = actionsUpdate;
          a.push(tbIndex);
          setActionsUpdate(a);
        }
      }
    });
  }

  const handleSave = (conversation, itemIndex) => {
    let data = {};
    conversation.annotations.map((annotation, index) => {
      const productDt = annotation.productData ? annotation.productData : {};
      const paramNeeds = annotation?.needGroups?.map((need) => {
        return {
          parentPhrase: need.parentPhrase,
          childPhrases: need.childPhrases
        };
      });
      data['productDataId'] = productDt?.id;
      data['isSystemAnnotation'] = annotation.isSystemAnnotation ? annotation.isSystemAnnotation : false;
      data['needGroups'] = paramNeeds ? paramNeeds : [];
      if (annotation.new) {
        saveTrains({
          trainid: trainId,
          lineid: conversation.id,
          data: {
            ...data
          }
        })
          .then(function (response) {
            if (response.status === 200) {
              setAction({
                tbIndex: -1,
                index: -1,
                annotation: {}
              });
              setActionsUpdate([]);
              annotation.new = false;
              annotation.id = response.data.id
              setCurActive(-1);
            } else {
              console.log('code error');
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        updateTrains({
          trainid: trainId,
          lineid: conversation.id,
          annotationid: annotation.id,
          data: {
            ...data
          }
        })
          .then(function (response) {
            if (response.status === 200) {
              setAction({
                tbIndex: -1,
                index: -1,
                annotation: {}
              });
              setActionsUpdate([]);
              setCurActive(-1);
            } else {
              console.log('code error');
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  };

  const onSearchModalClick = (lineId) => {
    setSearchOpen(true);
    setLineId(lineId);
  };

  const modalSubmit = (productId, summary) => {
    tableData.map((v: any, Iindex) => {
      if (v?.id === lineId) {
        const id = uuidv4();
        const newAn = {
          id: id,
          new: true,
          isSystemAnnotation: false,
          productData: {
            summary: summary,
            id: productId,
            product: product
          },
          needGroups: [],
          trainLineId: lineId
        };
        v.annotations.push(newAn);
        const lei = v.annotations.length;
        setCurActive(Iindex * 10 + lei - 1);
        setAction({
          tbIndex: Iindex,
          index: lei - 1,
          annotation: newAn
        });
        // btn show flag
        if (actionsUpdate.indexOf(Iindex) > -1) {
          let a = actionsUpdate;
          a.push(Iindex);
          setActionsUpdate(a);
        } else {
          let a = actionsUpdate;
          a.push(Iindex);
          setActionsUpdate(a);
        }
        const hasAnnotations1 = [] as any;
        hasAnnotations1.push(summary);
        setHasAnnotations(hasAnnotations1);
      }
    });
  };

  return (
    <Box className={classes.content}>
      <TableContainer className={classes.tableRoot}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableCell className={classes.tHead} style={{ width: '45%' }}>
              通話履歴
            </TableCell>
            <TableCell className={classes.tHead} style={{ width: '45%' }}>
              表示レコメンド
            </TableCell>
            <TableCell className={classes.tHead} style={{ width: '10%' }}>
              Action
            </TableCell>
          </TableHead>
          <TableBody style={{ height: '40vh' }}>
            {tableData?.map((item, index) => (
              <TableRow>
                <TableCell className={classes.cell} style={{ width: '40%' }}>
                  <Box className={classes.editorBox}>
                    <Typography
                      className={item.speaker === '0' ? classes.taskOperator : classes.taskCustomer}
                      style={{ paddingLeft: '10px' }}
                    >
                      {item.recogText}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell className={classes.cell} style={{ width: '40%' }}>
                  <Box className={classes.recommendBox}>
                    {item.annotations.length !== 0 && (
                      <Annotations
                        annotations={item.annotations}
                        setAction={setAction}
                        taData={tableData}
                        seTaData={setTableData}
                        taIndex={index}
                        curActive={curActive}
                        setCurActive={setCurActive}
                        actionsUpdate={actionsUpdate}
                      />
                    )}
                  </Box>
                  {item.annotations.filter((i) => !i.isSystemAnnotation).length < 3 && (
                    <Box className={classes.recommendBox}>
                      <Typography className={classes.recommendButton} onClick={() => onSearchModalClick(item.id)}>
                        +
                      </Typography>
                    </Box>
                  )}
                </TableCell>
                <TableCell className={classes.cell} style={{ width: '20%' }}>
                  {actionsUpdate.indexOf(index) > -1 && (
                    <ColorButton
                      variant="contained"
                      className={classes.button}
                      startIcon={<DoneIcon />}
                      onClick={() => handleSave(item, index)}
                    >
                      保存
                    </ColorButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SearchKeywordModal
        searchOpen={searchOpen}
        setSearchOpen={setSearchOpen}
        modalSubmit={modalSubmit}
        product={product}
        hasAnnotations={hasAnnotations}
      />
    </Box>
  );
};

export default memo(AnnotatedDialogContent);
