import React from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '56px',
      height: '56px',
      border: '2px',
      borderRadius: '50%',
      fontSize: '30px',
      bottom: '0px',
      right: '0px',
      margin: '50px',
      position: 'fixed',
      backgroundColor: '#303f9f',
      color: '#fff',
      cursor: 'pointer',
      padding: '6px',
      textAlign: 'center'
    }
  })
);

const AddBtn = (props) => {
  const { onClick } = props;
  const classes = useStyles();
  // const navigate = useNavigate();

  return (
    <Box className={classes.root} onClick={onClick}>
      +
    </Box>
  );
};

export default AddBtn;
