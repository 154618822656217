import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TablePaginationActions from '../../../components/TablePaginationActions';
import TitleWithSearch from '../../../components/management/TitleWithSerach';
import DialogOnly from '../../../components/management/DialogOnly';
import EditIcon from '@material-ui/icons/Edit';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import { getUsers, updateUserById, exportFile } from '../../../api/accountApi';
import { formatDate, openCsvExportString } from '../../../utils/ExportUtil';
import DownloadDialog from '../../../components/management/DownloadDialog';

const useStyles = makeStyles(() =>
  createStyles({
    titleSearch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    titleText: {
      fontWeight: 800,
      fontSize: '20px'
    },
    inputDel: {
      cursor: 'pointer',
      marginRight: '5px',
      opacity: '0.3',
      '&:hover': {
        opacity: '0.5'
      }
    },
    tableWrap: {
      alignItems: 'center',
      marginTop: '30px',
      boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)',
      borderRadius: '0px'
    },
    table: {
      width: '100%'
    },
    tableCellAction: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  })
);

const UserManagement = (props) => {
  const classes = useStyles();
  const title = 'ユーザー';

  // table datas
  const [rows, setRows] = useState([]);

  // cur page
  const [page, setPage] = React.useState(0);
  const [cPageChange, setcPageChange] = React.useState(false);
  //
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [totalCount, setTotalCount] = React.useState(0);

  const [orderby, setOrderby] = useState('');

  const [sort, setSort] = useState('');
  // dialog input
  const [emailInput, setEmailInput] = React.useState('');

  const [principleInput, setPrincipleInput] = React.useState('');

  // edit dialog
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  // current id
  const [currentAccount, setCurrentAccount] = React.useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchData, setSearchData] = React.useState('');

  function getformData(orderby, sort, page, rowsPerPage, searchData) {
    getUsers({
      orderby: orderby,
      sort: sort,
      words: searchData,
      pageSize: rowsPerPage,
      page: page
    })
      .then(function (response) {
        if (response.status === 200) {
          setRows(response.data.accounts);
          setTotalCount(response.data.total);
          setcPageChange(false);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (searchData && page) {
      //
      if (cPageChange) {
        getformData(orderby, sort, page, rowsPerPage, searchData);
      }
    } else {
      getformData(orderby, sort, page, rowsPerPage, searchData);
    }
  }, [orderby, sort, editDialogOpen, page, rowsPerPage, searchData, cPageChange]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setcPageChange(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setcPageChange(true);
  };

  const onHandleEditClick = (account, email, principle) => {
    setCurrentAccount(account);
    setEmailInput(email);
    setPrincipleInput(principle);
    // setRoleSelect('Admin');
    setEditDialogOpen(true);
    // updateUserById({ id: id, email:,principle:principleInput,account: userName, group: role, token: props.token })
    //   .then(function (response) {
    //     if (response.status === 200) {
    //       const { id, name, description } = response.data;
    //       setCurrentId(id);
    //       setEditDialogOpen(true);
    //     } else {
    //       console.log('code error');
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };

  const editSubmit = () => {
    updateUserById({
      account: currentAccount,
      email: emailRef.current?.value,
      principle: principleRef.current?.value
    })
      .then(function (response) {
        if (response.status === 200) {
          setEditDialogOpen(false);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const emailRef = React.useRef<HTMLInputElement>();
  const principleRef = React.useRef<HTMLInputElement>();

  const EditDialog = {
    title: 'Edit User',
    data: [
      {
        type: 'input',
        name: 'email',
        label: 'email',
        placeholder: 'email',
        value: emailInput,
        uref: emailRef,
        required: false,
        handleChange: setEmailInput
      },
      {
        type: 'input',
        name: 'teams principle',
        label: 'teams principle',
        placeholder: 'teams principle',
        value: principleInput,
        uref: principleRef,
        required: false,
        handleChange: setPrincipleInput
      }
    ],
    handleSubmit: editSubmit
  };

  //  table header begin
  const headCellsDef = [
    {
      id: 'account', // db column name
      disablePadding: false,
      label: 'アカウント', // table header column name
      active: false, // mouseout display is none except current is cliked
      order: 'asc', // default sort
      sorted: true // this column sort or not
    },
    {
      id: 'name', // db column name
      disablePadding: false,
      label: '名前', // table header column name
      active: false, // mouseout display is none except current is cliked
      order: 'asc', // default sort
      sorted: true // this column sort or not
    },
    {
      id: 'email', // db column name
      disablePadding: false,
      label: 'Email', // table header column name
      active: false, // mouseout display is none except current is cliked
      order: 'asc', // default sort
      sorted: true // this column sort or not
    },
    {
      id: 'principle', // db column name
      disablePadding: false,
      label: 'teams principle', // table header column name
      active: false, // mouseout display is none except current is cliked
      order: 'asc', // default sort
      sorted: true // this column sort or not
    },
    {
      id: 'group_list',
      disablePadding: false,
      label: 'ロール',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'created_at',
      disablePadding: false,
      label: '作成',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'updated_at',
      disablePadding: false,
      label: '更新',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'action',
      disablePadding: false,
      label: 'アクション',
      active: false,
      order: 'asc',
      sorted: false,
      style: {
        minWidth: '100px'
      }
    }
  ];

  const [headCells, setHeadCells] = React.useState(headCellsDef);

  const handleSort = (property) => {
    const changeSort = headCells?.map((v: any, i) => {
      if (v?.id === property) {
        if (v.order === 'asc') {
          v.order = 'desc';
        } else {
          v.order = 'asc';
        }
        setOrderby(property);
        setSort(v.order);
        v.active = true;
      } else {
        v.active = false;
      }
      return v;
    });
    setHeadCells([...changeSort]);
  };
  //  table header end
  const onHandleSearch = (searchData) => {
    setSearchData(searchData);
    setPage(0);
  };
  const onHandleDownload = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleDownload = () => {
    exportFile()
      .then(function (response) {
        openCsvExportString(`user_management.csv`, response.data);
        setAnchorEl(null);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <Box className="root-m">
      <TitleWithSearch title={title} setSearchFn={onHandleSearch} onclick={onHandleDownload} />
      <TableContainer component={Paper} className={classes.tableWrap}>
        <Table className={classes.table} aria-label="visitor detail">
          <EnhancedTableHead handleSort={handleSort} headCells={headCells} />
          <TableBody>
            {rows?.map((row: any) => (
              <TableRow key={row?.id}>
                <TableCell align="left">{row.account}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left" style={{maxWidth:'200px',whiteSpace: 'pre-wrap',wordWrap: 'break-word'}}>{row.email}</TableCell>
                <TableCell align="left" style={{maxWidth:'200px',whiteSpace: 'pre-wrap',wordWrap: 'break-word'}}>{row.principle}</TableCell>
                <TableCell align="left" style={{maxWidth:'140px',whiteSpace: 'pre-wrap',wordWrap: 'break-word'}}>{row.groupList}</TableCell>
                <TableCell align="left">{formatDate(row.createdAt)}</TableCell>
                <TableCell align="left">{formatDate(row.updatedAt)}</TableCell>
                <TableCell align="center">
                  <EditIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => onHandleEditClick(row?.account, row?.email, row?.principle)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, { label: 'All', value: totalCount }]}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                labelRowsPerPage={''}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      {/* <AddBtn title={addDialog.title} data={addDialog.data} handleSubmit={addDialog.handleSubmit} init={addDialog.init} /> */}

      <DialogOnly
        title={EditDialog.title}
        data={EditDialog.data}
        handleSubmit={EditDialog.handleSubmit}
        open={editDialogOpen}
        setOpen={handleEditDialogClose}
      />
      <DownloadDialog anchorEl={anchorEl} setAnchorEl={setAnchorEl} downloadFile={handleDownload} />
    </Box>
  );
};

export default UserManagement;
