import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Box, Grid, Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Chart from './components/Chart';
import Process from './components/Process';
import TableGrid from './components/TableGrid';
import Chat from './components/Chat';
import CheckGrid from './components/CheckGrid';
import DetailsGrid from './components/DetailsGrid';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../src/store';
import {
  getConversationSummary,
  getConversationById,
  getConversationCalls,
  getKeywords
} from '../../../api/history';
import { useNavigate, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      width: '100%',
      margin: '0px',
      padding: '32px',
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      marginBottom: '20px',
      borderRadius: '10px',
      backgroundColor: '#FAFAFAFF',
      border: '1px solid #E6E6E6FF'
    },
    space: {
      marginBottom: '2rem'
    }
  });
});

const HistoryDetails: React.FC<HistoryDetailsProps> = (props): JSX.Element => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const curUserInfo = useSelector((state: RootState) => state.user.userInfo);
  const [detailsGridData, setDetailsGridData] = useState([
    { title: '結果', data: ['成約', 'NG', '継続'], selected: {}, editBtn: false }
  ]);

  const [bizData, setBizData] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [chatData, setChatData] = useState<any[]>([]);
  // from db for gen data
  const [process, setProcess] = useState<any[]>([]);
  const [domain, setDomain] = useState<any[]>([]);
  const [processChecked, setProcessChecked] = useState<any[]>([]);
  const [domainChecked, setDomainChecked] = useState<any[]>([]);
  // for show
  const [processData, setProcessData] = useState<any[]>([]);
  const [domainData, setDomainData] = useState<any[]>([]);
  const [mouthHabitData, setMouthHabitData] = useState<any[]>([]);
  const [NGWordData, setNGWordData] = useState<any[]>([]);
  const [VocAbout, setVocAbout] = useState({});

  function convertResultData(result, products, account) {
    let temp = detailsGridData;
    temp[0].selected = {
      colored: true,
      value: [result]
    };
    temp[0].editBtn = account === curUserInfo['account'];
    if (products) {
      JSON.parse(products).map((v, i) => {
        let temv = {
          title: '成約商品',
          data: v,
          selected: {},
          editBtn: false
        };
        temp.push(temv);
      });
    }

    setDetailsGridData([...temp]);
  }

  useEffect(() => {
    const Id = id.substr(1);
    getConversationSummary({ id: Id })
      .then(function (response) {
        if (response.status === 200) {
          const { listenTalkChart, listenTalkSummary, wordSummary } = response.data;
          setBizData(listenTalkChart);
          setSummaryData(listenTalkSummary);
          setProcessChecked(wordSummary['process']);
          setDomainChecked(wordSummary['domain']);
          const dbVocAbout = {
            cushion: wordSummary['cushion'].length > 0 ? 'OK' : 'NG',
            doubt: wordSummary['doubt'][0]['total'],
            gutter: wordSummary['gutter'],
            ngWord: wordSummary['ngWord'],
            resonance: wordSummary['resonance'].length > 0 ? wordSummary['resonance'][0]['total'] : '0'
          };
          setVocAbout(dbVocAbout);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    getConversationById({ id: Id })
      .then(function (response) {
        if (response.status === 200) {
          const { contractResult, contractProduct, account } = response.data;
          convertResultData(contractResult, contractProduct, account);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    getConversationCalls({ id: Id })
      .then(function (response) {
        if (response.status === 200) {
          setChatData(response.data);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    getKeywords()
      .then(function (response) {
        if (response.status === 200) {
          response.data?.map((item, i) => {
            if (item.type === '提案プロセス') {
              setProcess(item.keywords);
            }
            if (item.type === '提案領域') {
              setDomain(item.keywords);
            }
            if (item.type === '口グセ') {
              setMouthHabitData(item.keywords);
            }
            if (item.type === 'NGワード') {
              setNGWordData(item.keywords);
            }
          });
          // console.log(response.data)
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // getKeywordsChecked({ id: Id })
    //   .then(function (response) {
    //     if (response.status === 200) {
    //       const { data } = response
    //       setProcessChecked(data['process']);
    //       setDomainChecked(data['domain']);
    //       const dbVocAbout = {
    //         cushion: data['cushion'].length > 0 ? 'OK' : 'NG',
    //         doubt: data['doubt'][0]['total'],
    //         gutter: data['gutter'],
    //         ngWord: data['ngWord'],
    //         resonance: data['resonance'].length > 0 ? data['resonance'][0]['total'] : '0',
    //       }
    //       setVocAbout(dbVocAbout)
    //     } else {
    //       console.log('code error');
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }, [id]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const genPro = process?.map((v: any, i) => {
      return {
        id: v?.id,
        label: v?.label,
        checked: processChecked.indexOf(v?.label) > -1 ? true : false
      };
    });
    setProcessData(genPro);
  }, [process, processChecked]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const genDomain = domain?.map((v: any, i) => {
      return {
        id: v?.id,
        label: v?.label,
        checked: domainChecked.indexOf(v?.label) > -1 ? true : false
      };
    });
    setDomainData(genDomain);
  }, [domain, domainChecked]); //eslint-disable-line react-hooks/exhaustive-deps

  const historyStatus = location.state ? location.state['historyStatus'] : '';
  const hisPageSize = location.state ? location.state['pageSize'] : '';
  const hisPage = location.state ? location.state['page'] : '';
  const sevenCheck = location.state ? location.state['sevenCheck'] : '';
  const selectedName = location.state ? location.state['selectedName'] : '';
  const selectedAS = location.state ? location.state['selectedAS'] : '';
  const companySelectedList = location.state ? location.state['companySelectedList'] : '';
  const branchCode = location.state ? location.state['branchCode'] : '';
  const companyCode = location.state ? location.state['companyCode'] : '';
  const selectedAge = location.state ? location.state['selectedAge'] : '';
  const selectedResult = location.state ? location.state['selectedResult'] : '';
  const selectedContent = location.state ? location.state['selectedContent'] : '';
  const selImplementStatus = location.state ? location.state['selImplementStatus'] : '';
  const selAppraiseStatus = location.state ? location.state['selAppraiseStatus'] : '';
  const selImplementAccount = location.state ? location.state['selImplementAccount'] : '';
  const branchCodeList = location.state ? location.state['branchCodeList'] : '';
  const backStatus = location.state ? location.state['backStatus'] : '';
  const selectedAgentCd = location.state ? location.state['companySelectedList'] : '';

  const backClick = () => {
    if (historyStatus) {
      navigate('../history', {
        state: {
          hisPageSize: hisPageSize,
          hisPage: hisPage,
          sevenCheck: sevenCheck,
          selectedName: selectedName,
          selectedAS: selectedAS,
          companySelectedList: companySelectedList,
          companyCode: companyCode,
          branchCode: branchCode,
          selectedAge: selectedAge,
          selectedResult: selectedResult,
          selectedContent: selectedContent,
          selImplementStatus: selImplementStatus,
          selAppraiseStatus: selAppraiseStatus,
          selImplementAccount: selImplementAccount,
          branchCodeList: branchCodeList,
          historyStatus: historyStatus,
          backStatus: true,
          selectedAgentCd:selectedAgentCd
        }
      });
    } else {
      navigate('../history');
    }
  };

  const editClick = () => {
    navigate('../result', {
      state: {
        id: id.substr(1),
        backstatus: true,
        hisPageSize: hisPageSize,
        hisPage: hisPage,
        sevenCheck: sevenCheck,
        selectedName: selectedName,
        selectedAS: selectedAS,
        companySelectedList: companySelectedList,
        companyCode: companyCode,
        branchCode: branchCode,
        selectedAge: selectedAge,
        selectedResult: selectedResult,
        selectedContent: selectedContent,
        selImplementStatus: selImplementStatus,
        selAppraiseStatus: selAppraiseStatus,
        selImplementAccount: selImplementAccount,
        branchCodeList: branchCodeList,
        historyStatus: historyStatus
      }
    });
  };
  return (
    <>
      <Box className={classes.root}>
        <Grid container item xs={12}>
          <Grid item className={classes.space} xs={12}>
            <Button variant="contained" style={{ float: 'left' }} onClick={backClick}>
              戻る
            </Button>
          </Grid>
          <Grid item className={classes.space} xs={12}>
            <DetailsGrid data={detailsGridData} editClick={editClick} />
          </Grid>
          <Grid container item className={classes.space} xs={12}>
            <Grid item xs={6} style={{ paddingRight: '1rem' }}>
              <Process steps={processData} conversionId={id.substr(1)} />
            </Grid>
            <Grid />
            <Grid item xs={6} style={{ paddingLeft: '1rem' }}>
              <CheckGrid data={domainData} />
            </Grid>
          </Grid>
          <Grid item className={classes.space} xs={12}>
            <Chart data={bizData} />
          </Grid>
          <Grid container item className={classes.space} xs={12}>
            <TableGrid
              data={summaryData}
              mouthHabitData={mouthHabitData}
              NGWordData={NGWordData}
              CheckedNGWordData={VocAbout}
            />
          </Grid>
          <Grid item xs={12}>
            <Chat data={chatData} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

interface HistoryDetailsProps {
  token?: any;
}

export default HistoryDetails;
