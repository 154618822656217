import React from 'react';
import { Button, Box, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from "react-swipeable-views";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PreviewPdf from './PdfPreview'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%'
    },
    img: {
      // height: 255,
      display: "block",
      // maxWidth: 400,
      overflow: "hidden",
      width: "100%"
    },
    arrowWrap: {
      width: '90%',
      position: 'fixed',
      top: '20%',
      display: 'flex',
      justifyContent: 'space-between',
      // paddingRight: '50px'
    },
    bottomWrap: {
      marginTop: '15px'
    },
    bottomWrapItem: {
      textAlign: 'center',
      wordWrap: 'break-word',
      border: 'solid 5px #fff',
      cursor: 'pointer',
    },
    bottomWrapItemBar: {
      backgroundColor: '#ccc',
      width: '100%',
      height: '5px'
    },
    bottomWrapItemBarActive: {
      backgroundColor: '#00a5d9',
    },
  }));

const Carousel: React.FC<CarouselProps> = (props: CarouselProps): JSX.Element => {
  const theme = useTheme();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const maxSteps = props?.data?.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const genTitleBar = (xs, curIndex, text) => {
    return <Grid item xs={xs} className={classes.bottomWrapItem} onClick={() => setActiveStep(curIndex)}>
      <Typography className={[classes.bottomWrapItemBar, curIndex === activeStep ? classes.bottomWrapItemBarActive : ''].join(' ')}></Typography>
      <Typography>{text.split(';')[1]}</Typography>
    </Grid>
  }


  return (
    <div className={classes.root}>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {props?.data?.map((v: any, i) => {
          return <PreviewPdf url={v} i={i} />
        })}
      </SwipeableViews>
      {props?.data?.length > 1 && <Box className={classes.arrowWrap}>
        <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === "rtl" ? (
            < ArrowForwardIosIcon />
          ) : (
            <ArrowBackIosIcon />
          )}
        </Button>
        <Button
          size="large"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
        >
          {theme.direction === "rtl" ? (
            <  ArrowBackIosIcon />
          ) : (
            < ArrowForwardIosIcon />
          )}
        </Button>
      </Box>}
      <Grid container xs={12} className={classes.bottomWrap}>
        {props?.data?.map((v: any, i) => {
          return genTitleBar(parseInt((12 / maxSteps).toString()), i, v.description)
        })}
      </Grid>
    </div >
  );
};

interface CarouselProps {
  data: any;
  token?: any;
}

export default Carousel;
