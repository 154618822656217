import { colors, createMuiTheme } from '@material-ui/core';
import shadows from './shadows';

const theme = createMuiTheme({
  palette: {
    background: {
      dark: '#FFFFFF',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#009bd6'
    },
    secondary: {
      main: colors.indigo[500]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography: {
    fontFamily: 'Meiryo, sans-serif'
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(255, 255, 255,0.8)'
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: '#E9F9FF',
        borderColor: '#40ADDC',
        borderWidth: '1px',
        borderStyle: 'solid',
        color: '#40ADDC',
        boxShadow: 'unset',
        '&:hover': {
          backgroundColor: '#CCF2FF',
          boxShadow: 'unset'
        }
      },
      '&$disabled': {
        border: 'unset',
        color: '#FFFFFF'
      }
    },
    MuiRadio: {
      colorPrimary: {
        color: '#B2B2B2',
        '&$checked': {
          color: '#40ADDC'
        }
      }
    }
  }
});

export default theme;
