import { memo, useState, useEffect } from 'react';
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography, TextField } from '@material-ui/core';
import AddPharaseModal from './AddPharaseModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AnnotatedDeleteDialog from './AnnotatedConfirmDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editor: {
      position: 'relative',
      width: '100%',
      overflowY: 'auto',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      maxHeight: '25vh',
      padding: '24px 16px 16px 16px'
    },
    contents: {
      width: '50%',
      display: 'flex',
      position: 'relative',
      flexDirection: 'column'
    },
    bottomTitle: {
      position: 'relative',
      fontWeight: 700,
      letterSpacing: '.25rem',
      color: '#222'
    },
    addPharase: {
      marginRight: '16px',
      position: 'absolute',
      right: '0',
      textDecoration: 'underline',
      color: '#003cff',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    answer: {
      width: '100%',
      padding: '1rem',
      flexGrow: 2,
      whiteSpace: 'pre-line'
    },
    phraseTags: {
      height: '100%',
      width: '100%',
      padding: '8px',
      boxSizing: 'border-box',
      // display: 'flex',
      flexWrap: 'wrap',
      placeContent: 'flex-start'
    },
    phraseChildren: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    phraseChildrenTag: {
      padding: '4px 8px',
      border: 'dotted 1px',
      borderRadius: '4px',
      margin: '4px 16px 4px 0',
      display: 'flex',
      alignItems: 'center'
    },
    phraseChildrenSvg: {
      color: '#000000',
      borderRadius: '3px',
      background: 'unset'
    },
    tag: {
      cursor: 'pointer',
      padding: '4px 8px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      border: '1px dotted',
      borderRadius: '4px',
      marginRight: '16px',
      marginBottom: '16px',
      position: 'relative'
    },
    svg: {
      color: '#000000',
      borderRadius: '3px',
      background: 'unset'
    },
    fieldset: {
      border: '1px dotted',
      borderRadius: '4px',
      padding: `10px`,
      // paddingBottom: '10px',
      cursor: 'pointer',
      background: '#fff',
      margin: '10px',
      lineHeight: '0px',
      float: 'left'
    },
    filedsetNew: {
      border: '1px solid #1e90ff'
    },
    fieldsetActive: {
      border: '1px solid red',
      background: `rgba(255,0,0,.08)`
    },
    label: {
      padding: '0px 3px',
      fontSize: '12px',
      fontWeight: 400,
      // lineHeight: 1,
      background: '#fff',
      color: '#1e90ff'
    },
    labelActive: {
      color: 'red'
    }
  })
);
const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderBottomColor: 'rgba(0, 0, 0, 0.23)'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderRadius: 'unset'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      },
      '&.Mui-focused fieldset': {
        border: '1px solid hsla(0,0%,49%,.46)'
      }
    }
  }
})(TextField);

const AnnotatedDialogActions = (props) => {
  const classes = useStyles();
  const [pharaseOpen, setPharaseOpen] = useState(false);
  const [parentPhrase, setParentPhrase] = useState('');
  const [curActive, setCurActive] = useState(-1);
  const { actions, setAction } = props;
  const [delQueOpen, setDelQueOpen] = useState(false);
  const [delParam, setDelParam] = useState('');
  const [pIndex, setPIndex] = useState(-1);
  const [chIndex, setChIndex] = useState(-1);

  useEffect(() => {
    setCurActive(-1);
    setParentPhrase('');
  }, [actions?.index, actions?.tbIndex]);//eslint-disable-line react-hooks/exhaustive-deps

  const addPharase = () => {
    setPharaseOpen(true);
  };
  const handleDelete = (index, cindex) => {
    setDelQueOpen(true);
    if (cindex === -1) {
      setDelParam(actions?.annotation.needGroups[index].parentPhrase);
    } else {
      const parentPhrase = actions?.annotation.needGroups[index].parentPhrase;
      const childPhrases = actions?.annotation.needGroups[index].childPhrases[cindex];
      setDelParam(`${parentPhrase} - ${childPhrases}`);
    }
    setPIndex(index);
    setChIndex(cindex);
  };
  const removePharase = (index, cindex) => {
    let temA = actions;
    // temA.annotation.isSystemAnnotation = false
    if (cindex === -1) {
      temA?.annotation.needGroups.splice(index, 1);
      temA['active'] = true;
    } else {
      if (temA?.annotation.needGroups[index].cNewIndex) {
        const ci = temA?.annotation.needGroups[index].cNewIndex.indexOf(cindex);
        if (ci > -1) {
          temA?.annotation.needGroups[index].cNewIndex.splice(ci, 1);
          const newCI1 = temA?.annotation.needGroups[index].cNewIndex.map((v, i) => {
            if (v > cindex) {
              return v - 1;
            } else {
              return v;
            }
          });
          temA.annotation.needGroups[index].cNewIndex = newCI1;
        } else {
          const newCI = temA?.annotation.needGroups[index].cNewIndex.map((v, i) => {
            return v - 1;
          });
          temA.annotation.needGroups[index].cNewIndex = newCI;
        }
      }
      temA?.annotation.needGroups[index].childPhrases.splice(cindex, 1);
      temA.annotation.needGroups[index]['cNewIndex'] = [actions?.tbIndex];
    }
    setAction({ ...temA });
    setDelQueOpen(false);
  };

  const pharaseSubmit = (phraseCode) => {
    let temA = actions;
    if (!parentPhrase) {
      if (temA?.annotation.needGroups) {
        temA?.annotation.needGroups.push({
          parentPhrase: phraseCode,
          childPhrases: [],
          cNewIndex: [],
          new: true
        });
      } else {
        temA.annotation['needGroups'] = [
          {
            parentPhrase: phraseCode,
            childPhrases: [],
            cNewIndex: [],
            new: true
          }
        ];
      }
    } else {
      temA?.annotation.needGroups?.map((v: any, i) => {
        if (v.parentPhrase === parentPhrase && curActive === i) {
          v.childPhrases.push(phraseCode);
          const cle = v.childPhrases.length;
          if (v.cNewIndex) {
            v.cNewIndex.push(cle - 1);
          } else {
            v.cNewIndex = [cle - 1];
          }
        }
      });
    }
    setAction({ ...temA });
  };

  const genItems = (params, index, cindex = -1) => {
    const { parentPhrase, childPhrases } = params;
    return (
      <fieldset
        className={[
          classes.fieldset,
          params.new ? classes.filedsetNew : '',
          curActive === index ? classes.fieldsetActive : ''
        ].join(' ')}
        onClick={() => {
          if (curActive === index) {
            setCurActive(-1);
            setParentPhrase('');
          } else {
            setCurActive(index);
            setParentPhrase(parentPhrase);
          }
        }}
      >
        {params?.new && (
          <legend className={[classes.label, curActive === index ? classes.labelActive : ''].join(' ')}>new</legend>
        )}
        <>
          <Box style={{ display: 'flex', lineHeight: childPhrases.length > 0 ? '40px' : '20px', alignItems: 'center' }}>
            <Box style={{ alignItems: 'center' }}>{parentPhrase}</Box>
            <Box style={{ margin: '-10px', marginLeft: '10px' }}>
              {childPhrases?.map((cParams, cindex) => {
                const newParams = {
                  parentPhrase: cParams,
                  childPhrases: [],
                  new: params?.cNewIndex ? params?.cNewIndex.indexOf(cindex) > -1 : false
                };
                return genItems(newParams, index, cindex);
              })}
            </Box>
            <Box style={{ marginLeft: '10px' }}>
              <FontAwesomeIcon icon={faTimes} style={{ fontSize: 18 }} onClick={() => handleDelete(index, cindex)} />
            </Box>
          </Box>
          <AnnotatedDeleteDialog
            open={delQueOpen}
            setOpen={setDelQueOpen}
            handleDelete={(e) => {
              removePharase(pIndex, chIndex);
              e.stopPropagation();
            }}
            dialogTitle="レコメンド フレーズ削除"
            dialogContent={`フレーズ「${delParam}」を削除します。よろしいですか？`}
          />
        </>
      </fieldset>
    );
  };

  return (
    <Box className={classes.editor} style={{ minHeight: '20vh' }}>
      <Box className={classes.contents} style={{ minWidth: '560px' }}>
        <Box className={classes.bottomTitle}>
          ●レコメンドが必要と判断したフレーズ(Question)
          {Object.keys(actions?.annotation).length > 0 && (
            <a className={classes.addPharase} onClick={addPharase}>
              + add
            </a>
          )}
        </Box>
        <div className={classes.phraseTags}>
          {actions?.annotation.needGroups?.map((phrase, index) => {
            return genItems(phrase, index);
          })}
        </div>
      </Box>
      <Box className={classes.contents} style={{ width: '50%' }}>
        <Typography className={classes.bottomTitle}>●レコメンド(Answer)​</Typography>
        <CssTextField
          defaultValue=""
          value={actions?.annotation.productData ? actions?.annotation.productData?.summary : ''}
          variant="outlined"
          InputProps={{
            readOnly: true
          }}
          multiline={true}
          className={classes.answer}
        />
      </Box>
      <AddPharaseModal pharaseOpen={pharaseOpen} setPharaseOpen={setPharaseOpen} pharaseSubmit={pharaseSubmit} />
    </Box>
  );
};

export default memo(AnnotatedDialogActions);
