import { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Translate from '../../components/Translate';
import Evaluation from '../../components/Evaluation';
import ChatContent from '../../components/ChatContent';
import DataCollection from '../../components/DataCollection';
import DataCollection2 from '../../components/DataCollection2';
import { useLocation } from 'react-router-dom';
import NewWindow from 'react-new-window'
// const [transMax, setTransMax] = React.useState(false);
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      // width: '50%'
      // border: '1px solid #ccc',
      // borderRadius: '10px',
      // backgroundColor: '#FFFFFF',
      // padding: '5px 15px',
    }
  })
);

const CustomerSupportMax = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const getSearchParams = () => {
    const s = location?.search;
    let param = {};
    if (s) {
      s.slice(1)
        .split('&')
        .map((v, i) => {
          const kv = v.split('=');
          param[kv[0]] = kv[1];
        });
      return param;
    } else {
      return param;
    }
  };
  const dataDb = getSearchParams();
  // const ColEval = useSelector((state: RootState) => state.global.ColEval);
  const [ColEval, setColEval] = useState({ Evaluation: [], dataCollection: [], dataCollection2: [], ChatContent: {} })
  useEffect(() => {

    if (!window.localStorage) {
      alert("Browser does not support localstorage, cannot data synchronization.");
    } else {
      const storage = window.localStorage;
      const timerId = setInterval(() => {
        let ret = storage.getItem("ColEval")
        if (ret) {
          const ColEval = JSON.parse(ret)
          setColEval(ColEval)
        }
      }, 2000);
      // clear interval after com out
      return () => { clearInterval(timerId); storage.removeItem("ColEval") };
    }
    window.resizeTo(400, 600)
  }, [])


  const FullScreenCom = (d) => {
    const { type } = d;
    switch (type) {
      case 'tran':
        return <Translate data={dataDb} Max={true} setMax={true} setData={() => { }} />;
      case 'eval':
        return <Evaluation data={ColEval?.Evaluation} Max={true} setMax={true} />;
      case 'content':
        return <ChatContent data={ColEval?.ChatContent} Max={true} setMax={true} />;
      case 'collection':
        return <DataCollection data={ColEval?.dataCollection} Max={true} setMax={true} />;
      case 'collection2':
        return <DataCollection2 data={ColEval?.dataCollection2} Max={true} setMax={true} />;
      default:
        return <Translate data={dataDb} Max={true} setMax={true} setData={() => { }} />;
    }
  };

  return (
    <NewWindow>
      <Box className={classes.root}>
        <Grid container xs={12} spacing={2}>
          <Grid item xs={12}>
            {FullScreenCom(dataDb)}
            {/* <Translate data={dataDb} Max={true} setMax={true} /> */}
          </Grid>
        </Grid>
      </Box>
    </NewWindow>
  );
};

export default CustomerSupportMax;
