import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Input, InputAdornment } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputDelIcon from '@material-ui/icons/HighlightOff';
import DownloadIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    titleSearch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    titleText: {
      fontWeight: 800,
      fontSize: '20px'
    },
    inputDel: {
      cursor: 'pointer',
      marginRight: '5px',
      opacity: '0.3',
      '&:hover': {
        opacity: '0.5'
      }
    }
  })
);

const TitleWithSearch = (props) => {
  const classes = useStyles();
  // const navigate = useNavigate();
  // for search
  const [searchData, setSearchData] = useState('');
  const [oldSearchData, setOldSearchData] = useState('');
  // const handleLogoOut = () => {
  //   navigate('../login');
  // };

  const updateSearch = useCallback(() => {
    if (oldSearchData === searchData || !props?.setSearchFn) {
      return;
    }
    props?.setSearchFn(searchData);
    setOldSearchData(searchData);
  }, [searchData, oldSearchData, props?.setSearchFn]);//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timer: any = undefined;
    if (searchData.length === 0) {
      updateSearch();
    } else {
      timer = setTimeout(() => {
        updateSearch();
      }, 1000);
    }    
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [searchData, updateSearch]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className={classes.titleSearch}>
      <Typography className={classes.titleText}>{props?.title}</Typography>
      <Box>
        <Input
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment
              position="end"
              className={classes.inputDel}
              onClick={() => {
                setSearchData('');
              }}
            >
              <InputDelIcon />
            </InputAdornment>
          }
          placeholder="検索"
          onChange={(v) => {
            setSearchData(v.target.value);
          }}
          value={searchData}
        />
        <DownloadIcon
          style={{ marginLeft: '10px', cursor: 'pointer' }}
          onClick={(e) => {
            props?.onclick(e);
          }}
        />
      </Box>
    </Box>
  );
};

export default TitleWithSearch;
