import { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import OkSvg from './images/OK.svg';
import SettingIcon from './images/setting.svg';
import AddSvg from './images/add.svg';
import SaveSvg from './images/save.svg';
import CloseSvg from './images/close.svg';
import LayOutSetting from '../../components/LayoutSetting';
import { setLayout, searchLayout, updateLayout } from '../../api/layoutApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { getProductExtData } from '../../api/customerSupportApi';
import { stopTeamBot } from '../../api/readyBefore'
import { getKeywords, getKeywordsChecked } from '../../api/history'
import { getProductsRefisShow } from '../../api/annotationApi';
import Message from '../../components/Message';
import EventBus from '../../components/EventBus';
import ConfirmDialog from '../../components/management/ConfirmDialog';
import { sessionTimeoutReset } from '../../components/AutoLagoutTimer';
import { getConversionList } from '../../api/customerSupportApi'

// const [transMax, setTransMax] = React.useState(false);
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // display: 'flex',
      alignItems: 'center',
      // maxWidth: document.body.offsetWidth / 2 + 'px',
      // border: '1px solid #ccc',
      // borderRadius: '10px',
      // backgroundColor: '#FFFFFF',
      // padding: '5px 15px',
      position: 'relative',
      paddingBottom: '70px',
      overflowX: 'scroll'
    },
    supportRoot: {
      width: '100%',
      border: '1px solid #ccc',
      borderRadius: '10px',
      backgroundColor: '#FFFFFF',
      padding: '5px 15px'
    },
    supportDetail: {
      width: '100%',
      border: '1px solid #ccc',
      borderRadius: '10px',
      backgroundColor: 'rgba(214,244,255,0.56)',
      padding: '5px 15px'
    },
    supportTitle: {
      textAlign: 'center',
      margin: '5px'
    },

    settingIconBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '50%',
      paddingLeft: '10px'
    },
    settingIcon: {
      width: '30px',
      cursor: 'pointer'
    },
    settingScreen: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      zIndex: 6666,
      borderRadius: 0,
      backgroundColor: '#FFF',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: `0 !important`,
        height: `0 !important`
      },
      padding: '10px 64px'
    },
    settingScreenB: {
      padding: '10px 64px'
      // padding:'0 auto',
      // margin:'0 auto',
    },
    settingTool: {
      position: 'fixed',
      height: '94%',
      top: '20px',
      right: '0px',
      zIndex: 10,
      border: '1px solid #ccc',
      borderRadius: '8px',
      backgroundColor: '#fff',
      padding: '5px 15px'
    },
    settingToolB: {
      marginTop: '30px',
      overflowY: 'auto'
    },
    settingToolImg: {
      width: '33px',
      cursor: 'pointer'
    },
    settingToolAddBox: {
      position: 'fixed',
      border: '1px solid #ccc',
      borderRadius: '8px',
      zIndex: 9,
      padding: '15px',
      right: '60px',
      top: '100px',
      listStyleType: 'none',
      backgroundColor: '#fff',
    },
    addBoxLi: {
      border: '1px solid #ccc',
      borderRadius: '4px',
      padding: '10px',
      marginBottom: '10px',
      marginTop: '5px',
      cursor: 'pointer',
      width: '260px'
    },
    bottomBar: {
      // left: 0,
      // bottom: 0,
      // position: 'fixed',
      height: '70px',
      width: '100%',
      backgroundColor: '#fff',
      borderTop: '1px solid #ccc',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    bottomBarBtn: {
      // left: '45%',
      // bottom: '30px',
      // position: 'fixed',
      padding: '10px 30px',
      border: '2px solid #ad7506',
      borderRadius: '5px',
      backgroundColor: '#faad14',
      textAlign: 'center',
      cursor: 'pointer',
      fontSize: '20px',
      fontWeight: 700
    }
  })
);

const CustomerSupport = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const userName = useSelector((state: RootState) => state.user.userInfo)['account'];
  const [isLayout, setIsLayout] = useState(false);
  // message
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgType, setMsgType] = useState('err');
  const [msgText, setMsgText] = useState('');
  // const [isFirstSet, setIsFirstSet] = useState(true);
  const [saveOpen, setSaveOpen] = useState(false);

  const EL = [
    {
      i: 'データ集',
      x: 0,
      y: 0,
      w: 6,
      h: 2,
      add: 0,
      index: 3,
      static: isLayout,
      component: 'DataCollection',
      max: false,
      set: isLayout,
      setMax: false,
      header: false,
      bgColor: '#d1d4ff',

    },
    {
      i: 'データ集2',
      x: 0,
      y: 2,
      w: 6,
      h: 2,
      add: 0,
      index: 2,
      static: isLayout,
      component: 'DataCollection2',
      max: false,
      set: isLayout,
      setMax: true,
      header: false,
      bgColor: '#d1d4ff',

    },

    {
      i: 'レコメンデーション',
      x: 0,
      y: 4,
      w: 6,
      h: 3,
      add: 0,
      index: 4,
      static: isLayout,
      component: 'Evaluation',
      max: false,
      set: isLayout,
      setMax: false,
      header: false,
      bgColor: '#f1e8c3',
    },
    {
      i: '提案領域',
      x: 0,
      y: 7,
      w: 6,
      h: 2,
      add: 0,
      index: 1,
      static: isLayout,
      component: 'ChatContent',
      max: false,
      set: isLayout,
      setMax: true,
      header: false,
      bgColor: '#fbd2e6',
    },

    {
      i: '会話内容',
      x: 0,
      y: 9,
      w: 6,
      h: 5,
      add: 0,
      index: 0,
      static: isLayout,
      component: 'Translate',
      max: false,
      set: isLayout,  // layout or setting
      setMax: false,  //max icon dis
      header: false,  //header dis,
      bgColor: '#cbecc7',
    },

  ];
  const [addBoxDis, setAddBoxDis] = useState(false);
  const [layoutId, setLayoutId] = useState('');
  const [layoutData, setLayoutData] = useState<any[]>([]);
  const [preLayoutData, setPreLayoutData] = useState<any[]>([]);
  const [forPage, setForPage] = useState(true);
  const [closeState, setCloseState] = useState(true);
  const [extractText, setExtractText] = useState<any[]>([]);
  const [savingLayout, setSavingLayout] = useState(true);

  const [dataDb, setDataDb] = useState(
    {
      Translate: {
        ...location?.state
      },
      Evaluation: {
        data: [],
        productDataId: '',
      },
      ChatContent: {
        data: new Array(),
        checked: new Array(), //demo
      },
      dataCollection: new Array(),
      dataCollection2: new Array(),
    }
  );

  let storage;
  if (!window.localStorage) {
    alert("Browser does not support localstorage, cannot data synchronization.");
  } else {
    storage = window.localStorage;
  }

  useEffect(() => {
    extractText?.map((v: any, i) => {
      if (location?.state && v.teamsCallId === location?.state['teamsCallId']) {
        getProductExtData({
          state: null,
          product: '生きるためのがん保険Days1　ALL-in',
          text: v?.recogText,
        })
          .then(function (response) {
            if (response.status === 200) {
              const { recommendations } = response.data
              if (recommendations.length) {
                let Evaluation = {
                  data: [] as any,
                  productDataId: '',
                }, dataCollection = new Array();
                let tempDC2 = new Array();
                // let dataCollection2 = new Array();
                recommendations.map((v, i) => {
                  if (v.summary || v.description) {
                    Evaluation.data.push({
                      'summary': v?.summary,
                      'description': v?.description
                    })
                    Evaluation.productDataId = v.productDataId
                  }
                  if (v.references.length) {
                    dataCollection.push(...v.references)

                    v.references?.map((item, i) => {
                      // let page_name = item.url + 'ページ' + item.description?.split(';')[0]
                      let page_name = item.url
                      if (tempDC2.indexOf(page_name) === -1 && dataDb['dataCollection2'].indexOf(page_name) === -1) {
                        tempDC2.push(page_name)
                      }
                    })
                    // dataCollection2 = dataDb['dataCollection2'].concat(tempDC2)
                  }
                })
                let tem = dataDb
                tem['Evaluation'] = Evaluation
                tem['dataCollection'] = dataCollection
                tem['dataCollection2'] = dataDb['dataCollection2'].concat(tempDC2)
                // tem['ChatContent'].checked=['死亡']  //demo
                storage['ColEval'] = JSON.stringify({ ...tem })
                setDataDb({ ...tem })
              }
            } else {
            }
          })
          .catch(function (error) {
            console.log(error);
          });

        //...location?.state
        if (v.speaker === "0") {  // operatorChatText
          getKeywordsChecked({ ...location?.state, recogText: v?.recogText })
            .then(function (response) {
              if (response.status === 200) {
                const { domain } = response.data
                let tem = dataDb
                let preChecked = tem['ChatContent'].checked

                domain.map((item, j) => {
                  if (preChecked.indexOf(item) < 0) {
                    preChecked.push(item)
                  }
                })

                tem['ChatContent'].checked = [...preChecked]
                setDataDb({ ...tem })
              }
            })
            .catch(function (error) {

            })
        }
      }
    })

    return () => { storage.removeItem("ColEval") };
  }, [extractText])//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    searchLayout({
      account: userName,
      pattern: 'default',
    })
      .then(function (response) {
        if (response.status === 200 && response.data.length > 0) {
          setLayoutId(response.data[0]?.id);
          setLayoutData(JSON.parse(response.data[0]?.data));
          setPreLayoutData(JSON.parse(response.data[0]?.data));
        } else {
          setLayoutData(EL);
          setPreLayoutData(EL);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    getKeywords()
      .then(function (response) {
        if (response.status === 200) {
          response.data?.map((item, i) => {
            if (item.type === '提案領域') {
              let tempdDataDb = dataDb
              tempdDataDb.ChatContent.data = item.keywords
              setDataDb({ ...tempdDataDb })
            }
          })
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    getProductsRefisShow()
      .then(function (response) {
        if (response.status === 200) {
          const { products } = response.data
          let dataCollection2 = new Array();
          let tempDC2 = new Array();
          products.map((v, i) => {
            if (v.references.length) {
              v.references?.map((item, i) => {
                // let page_name = item.url + 'ページ' + item.description?.split(';')[0]
                let page_name = item.url
                if (tempDC2.indexOf(page_name) === -1 && dataDb['dataCollection2'].indexOf(page_name) === -1) {
                  tempDC2.push(page_name)
                }
              })
              dataCollection2 = dataDb['dataCollection2'].concat(tempDC2)
            }
          })
          let tem = dataDb
          tem['dataCollection2'] = dataCollection2
          storage['ColEval'] = JSON.stringify({ ...tem })
          setDataDb({ ...tem })
        }
      })
      .catch(function (error) {

      })
    // While speaking, session does not time out 
    const id = location?.state ? location.state['id'] : '';
    let lastConversionTime = ''
    const timerId = setInterval(() => {
      getConversionList({
        id: id,
        lastTime: lastConversionTime
      })
        .then(function (response) {
          if (response.status === 200 && response.data.length > 0) {
            const le = response.data.length;
            if (le) {
              sessionTimeoutReset();
              lastConversionTime = response.data[le - 1]['createdAt']
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }, 10000);
    // clear interval after com out
    return () => {
      clearInterval(timerId)
    };
  }, []);//eslint-disable-line react-hooks/exhaustive-deps

  const hangleSettingClick = () => {
    // setIsFirstSet(false);
    setIsLayout(true);
    setForPage(false);
  };

  const handleSettingToolClose = () => {
    // setIsFirstSet(true);
    setIsLayout(false);
    setForPage(true);
    setCloseState(true);
    setLayoutData([...preLayoutData]);
  };
  const handleSettingToolOK = () => {
    // setIsFirstSet(false);
    setIsLayout(false);
    setForPage(true);
    setCloseState(false);
    setPreLayoutData([...layoutData]);
  };

  const handleSettingToolSave = () => {
    setSavingLayout(false);
    setLayoutToDb();
    setIsLayout(false);
    setCloseState(false);
    setPreLayoutData([...layoutData]);
  };

  function setLayoutToDb() {
    if (layoutId) {
      updateLayout({
        account: userName,
        pattern: 'default',
        data: layoutData,
        id: layoutId
      })
        .then(function (response) {
          if (response.status === 200) {
            setSavingLayout(true);
            setLayoutId(response.data?.id);
            setLayoutData(JSON.parse(response.data?.data));
            setPreLayoutData(JSON.parse(response.data?.data));
          } else {
            console.log('code error');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setLayout({
        account: userName,
        pattern: 'default',
        data: layoutData
      })
        .then(function (response) {
          if (response.status === 200) {
            // setToCodeList(response.data)
            setSavingLayout(true);
            setLayoutId(response.data?.id);
          } else {
            console.log('code error');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  // const onLayoutChange = (layout) => {
  //   // this.setState({ layout: layout });
  // };
  const addLayoutItem = (index) => {
    let item;
    let i;
    let curItemExist = false;
    EL.map((v: any, j) => {
      if (v.index === index) {
        item = v
        i = v.i
        return
      }
    })
    layoutData.map((v: any, n) => {
      if (v.i === i) {
        curItemExist = true
      }
    })
    if (curItemExist) {
      EventBus.dispatch('show_snack', { message: `プロジェクト【${i}】はすでに存在します,繰り返し追加することはできません.`, type: 'error' });
    } else {
      setLayoutData([...layoutData, item])
    }
  }

  // const toResultPage = () => {

  // }
  const onSave = () => {
    stopTeamBot({ url: location?.state ? location?.state['finishedUrl'] : '' })
      .then(function (response) {
        if (response?.status === 200) {
          navigate('../result', { state: { ...location?.state } })
        }
      })
      .catch(function (error) {
        // if (error.response.status === 404) {
        navigate('../result', { state: { ...location?.state } })
        // }
      })
  };

  return (
    <>
      <Box className={classes.root}>

        <Box className={classes.settingIconBox}>
          <button className={classes.bottomBarBtn} onClick={() => setSaveOpen(true)}>画面終了</button>
          <img src={SettingIcon} alt="Setting Icon" className={classes.settingIcon} onClick={hangleSettingClick} />
        </Box>

        {!forPage && <Box className={classes.settingScreen}>
          <Message open={msgOpen} closeFun={setMsgOpen} msg={msgText} type={msgType} />
          <LayOutSetting el_layout={layoutData} el_layoutFun={setLayoutData} forPage={forPage} data={dataDb} />
          <Box className={classes.settingTool}>
            <Box className={classes.settingToolB}>
              <img
                src={CloseSvg}
                alt='Setting Close Img'
                className={classes.settingToolImg}
                onClick={handleSettingToolClose}
              />
            </Box>
            <Box className={classes.settingToolB}>
              <img src={AddSvg} alt='Setting Add Img' className={classes.settingToolImg} onClick={() => setAddBoxDis(true)} />
              <Box className={classes.settingToolAddBox} display={addBoxDis ? 'block' : 'none'}>
                <div style={{ textAlign: 'right' }}>
                  <img
                    src={CloseSvg}
                    alt='Setting Close Img'
                    className={classes.settingToolImg}
                    style={{ width: '20px' }}
                    onClick={() => setAddBoxDis(false)}
                  />
                </div>

                {EL.map((v: any, index) => {
                  return <li className={classes.addBoxLi} style={{ backgroundColor: v?.bgColor }} onClick={() => addLayoutItem(v?.index)}>{v?.i}</li>
                })}

              </Box>
            </Box>
            <Box className={classes.settingToolB}>
              <img src={OkSvg} alt="Setting Ok Img" className={classes.settingToolImg} onClick={handleSettingToolOK} />
            </Box>
            <Box className={classes.settingToolB}>
              <img
                src={SaveSvg}
                alt="Setting Save Img"
                className={classes.settingToolImg}
                onClick={savingLayout ? handleSettingToolSave : () => { }}
              />
            </Box>
          </Box>
        </Box>}
        {forPage && <LayOutSetting el_layout={closeState ? preLayoutData : layoutData} el_layoutFun={() => { }} forPage={forPage} data={dataDb} setExtractText={setExtractText} />}
      </Box>
      <ConfirmDialog
        title="お客様対応画面を終了しますか？"
        open={saveOpen}
        setOpen={setSaveOpen}
        onSubmit={onSave}
      />
    </>
  );
};

export default CustomerSupport;
