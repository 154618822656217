import { Typography, Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ChangeLogJson from '../../config/changLog.json';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '40px',
      marginTop: '12px'
    },
    outBtn: {
      background: '#FFFFFF',
      width: '150px',
      minHeight: '45px',
      border: '2px solid #85bdda',
      borderRadius: '10px',
      color: '#333333',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textAlign: 'center',
      '& span': {
        color: '#5186bb'
      }
    },
    btnImg: {
      marginRight: '7px'
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: '1.6'
    }
  }));

const ChangeLog = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box>
        {
          ChangeLogJson?.map((v: any, i) => {
            return <Typography style={{ margin: '10px' }}>
              <span>{i}.</span>
              <span style={{ paddingLeft: '8px' }}>{v.comment}</span>
              <span style={{ paddingLeft: '8px' }}>Updated by 【 {v.user} 】</span>
              <span style={{ paddingLeft: '8px' }}>At {v.date}</span>
              <a style={{ paddingLeft: '8px' }} href={v.url} target="_blank">{v.url}</a>
            </Typography>
          })
        }
      </Box>
    </div>
  );
};

export default ChangeLog;
