import service from './service';

export function getRecomandList(parameter) {
  const url =
    parameter.pageSize === -1
      ? `/products/data/search?orderby=${parameter.orderby}&sort=${parameter.sort}`
      : `/products/data/search?pagesize=${parameter.pageSize}&page=${parameter.page}&orderby=${parameter.orderby}&sort=${parameter.sort}`;
  return service({
    url: url,
    method: 'post',
    data: {
      keyWords: parameter.words
    }
  });
}

export function deleteRecomand(parameter) {
  return service({
    url: `/products/data/${parameter.id}`,
    method: 'delete'
  });
}

export function getRecomand(parameter) {
  return service({
    url: `/products/data/${parameter.id}`,
    method: 'get'
  });
}

export function getFileSet(parameter) {
  return service({
    url: `/fileset`,
    method: 'get'
  });
}

export function updateRecomand(parameter) {
  return service({
    url: `/products/data/${parameter.id}`,
    method: 'put',
    data: {
      ...parameter.data
    }
  });
}

export function addRecomand(parameter) {
  return service({
    url: `/products/data/`,
    method: 'post',
    // data: {
    //   product: parameter.product,
    //   need: parameter.need,
    //   summary: parameter.summary,
    //   description: parameter.description,
    //   keywords: [],
    //   references: parameter.references
    // }
    data: {
      ...parameter.data
    }
  });
}

export function exportFile(parameter) {
  return service({
    url: `/products/data/data/export`,
    method: 'get'
  });
}
