import React from 'react';
import withWrap from '../../withWrap';
import ChatIcon from './chat_icon.png';
import BotPerson from './bot_person.png';
import styles from './index.module.css';

const Chat: React.FC<ChatProps> = (props: ChatProps): JSX.Element => {

  const { data } = props;

  const classNames = (classes: (string | false)[]): string => classes.join(' ');

  return (
    <>
      <div className={styles.chat}>
        <div className={styles.titleWrap}>
          <img aria-hidden className={styles.chatIcon} src={ChatIcon} alt='chat icon' />
          <span className={styles.title}>
            <b>チャット履歴</b>
          </span>
        </div>
        <div className={styles.ChatWrap}>
          <div className={styles.messageWrap}>
            {data?.map((item, index: number) => (
              <div key={index}>
                {item?.speaker === "1" && (
                  <div className={classNames([styles.message, styles.messageLeft])}>
                    <div className={styles.messageImage}>
                      <img aria-hidden className={styles.botPerson} src={BotPerson} alt='Bot Person' />
                    </div>
                    <div className={styles.messageBubble}>
                      <div className={styles.messageInfo}>
                        <div className={styles.messageInfoName}>お客様</div>
                      </div>
                      <div className={styles.messageText}>{item?.recogText}</div>
                    </div>
                  </div>
                )}
                {item?.speaker === "0" && (
                  <div className={classNames([styles.message, styles.messageRight])}>
                    <div className={styles.messageBubble}>
                      {/* <div className={styles.messageInfo}>
                        <div className={styles.messageInfoNameRight}>募集人役</div>
                      </div> */}
                      <div className={styles.messageText}>{item.recogText}</div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

interface ChatProps {
  data: any[];
}

export default withWrap<ChatProps>({
  styled: false
})(Chat);
