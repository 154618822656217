
import parseISO from "date-fns/parseISO";

export type JsonAny = any;
export interface ProductDataSet {
    id: number;
    product: string;
    guarantee: string;
    contract: string;
    need: string;
    summary: string;
    description: string;
    references: ProductDataReference[];
    isShow:string;
    created: Date;
    updated: Date;
}

export interface ProductDataReference {
    description: string;
    url: string;
    numPages?: number;
}

export interface ProductDataChangeSet {
    product: string;
    need: string;
    summary: string;
    description: string;
    references: ProductDataReference[];
    isShow:boolean;
}

const separator = ";";

export function parseProductDataSet(json: JsonAny): ProductDataSet {
    return {
        ...json,
        references: json.references.map((ref: ProductDataReference) => ({
            url: ref.url,
            description: ref.description.split(separator).slice(1).join(separator),
            numPages: Number(ref.description.split(separator)[0])
        })),
        created: parseISO(json.created),
        updated: parseISO(json.updated)
    };
}

export function serializeProductDataChangeSet(changeSet: ProductDataChangeSet): JsonAny {
    return {
        product: changeSet.product,
        need: changeSet.need,
        summary: changeSet.summary,
        description: changeSet.description,
        references: changeSet.references.map((ref: ProductDataReference) => ({
            url: ref.url,
            description: ref.numPages + separator + ref.description
        })),
        isShow:changeSet.isShow,
    };
}
