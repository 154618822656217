import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox, Divider, Grid, Typography } from '@material-ui/core';
import Title from './DetailTitle';
import ExpressBtn from './ExpressBtn'
import NewWindow from 'react-new-window'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      border: '1px solid #ccc',
      borderRadius: '10px',
      backgroundColor: '#FFFFFF',
      padding: '5px 15px',
      textAlign: 'center'
    },
    chatRootTitle: {
      // border: '1px solid #ccc',
      backgroundColor: '#FFFFFF',
      textAlign: 'center',
      padding: '5px'
    },
    chatDetail: {
      justifyContent: 'space-between',
      height: '100%',
      overflowY: 'auto',
      position: 'relative',
    },
    chatDetailB: {
      border: '1px solid #ccc',
      padding: '5px 15px',
      backgroundColor: 'rgba(214,244,255,0.56)',
      margin: '5px 15px',
      textAlign: 'center',
      height: '35px'
      // verticalAlign:'center',
      // lineHeight:'40px',
    },
    fullScreen: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      zIndex: 6666,
      borderRadius: 0
    },
    translateBox: {
      height: '100%',
      marginBottom: '10px',
      width: '100%',
      position: 'relative',
      paddingBottom: '30px'
    },
  })
);

const ChatContent: React.FC<ChatContentProps> = (props: ChatContentProps): JSX.Element => {
  const { set, setMax, header, data } = props;
  const classes = useStyles();
  const [popTranslate, setPopTranslate] = useState(false);
  const handleEvaMax = () => {
    // const openBaseUrl = curBaseUrl(location) + "/#"
    // const curCom = `/app/translatemax?type=content`
    // const w = window.innerWidth / 3;
    // const h = window.innerHeight;
    // const wFocus = window.open(openBaseUrl + curCom, '', `height=${h}, width=${w}, top=0, left=${w}`);
    // wFocus?.focus()
    setPopTranslate(true)
  };

  return (
    !set ?
      <>
        <Box className={classes.translateBox}>
          <Title text={!header ? '提案領域' : ''} Max={!setMax} FnMax={() => handleEvaMax()} />
          <Grid className={classes.chatDetail} container>
            {data?.data?.map((v, i) => {
              return (
                <Grid item key={i}>
                  <Box className={classes.chatDetailB}>{v.label}</Box>
                  <Box className={classes.chatDetailB}>
                    <Checkbox
                      color="primary"
                      style={{ marginTop: '-8px' }}
                      checked={data?.checked?.indexOf(v.label) !== -1}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        {popTranslate && <NewWindow
          title={'提案領域'}
          center={'screen'}
          copyStyles={true}
          onUnload={() => {
            setPopTranslate(false)
          }}
        >
          <div className={setMax ? [classes.root, classes.fullScreen].join(" ") : ""}>
            <Title text={!header ? '提案領域' : ''} Max={!setMax} FnMax={() => handleEvaMax()} />
            <Grid className={classes.chatDetail} container>
              {data?.data?.map((v, i) => {
                return (
                  <Grid item key={i}>
                    <Box className={classes.chatDetailB}>{v.label}</Box>
                    <Box className={classes.chatDetailB}>
                      <Checkbox
                        color="primary"
                        style={{ marginTop: '-8px' }}
                        checked={data?.checked?.indexOf(v.label) !== -1}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </NewWindow>}
      </> :
      <>
        <span className="remove">提案領域</span>
        <Divider />
        <Grid container xs={12} spacing={2} style={{ padding: '15px' }}>
          <Grid item xs={6}>
            <Typography>ヘッダー表示</Typography>
            <ExpressBtn express={!props?.header} type="dis" comIndex={props?.settingIndex} layouts={props?.layouts} layoutFun={props?.layoutFun} />
          </Grid>
        </Grid>
      </>
  );
};

interface ChatContentProps {
  Max?: boolean;
  data?: any;
  set?: any;
  setMax?: boolean;
  header?: boolean;
  settingIndex?: any;
  layouts?: any;
  layoutFun?: any;
}

export default ChatContent;
