import { memo } from 'react';
import ResponsiveDialog from '../../../../components/management/ResponsiveDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import ProductDataForm from './ProductDataForm';

const ProductDataSetDialog = (props) => {
  const { open, setOpen, onSave, type, productDataSet } = props;
  return (
    <ResponsiveDialog open={open} onClose={() => setOpen(false)} maxWidth="sm">
      <DialogTitle>{type === 'Add' ? '第三者話法を追加' : '第三者話法を編集'}</DialogTitle>
      <ProductDataForm onSave={onSave} onClose={() => setOpen(false)} initialValues={productDataSet} />
    </ResponsiveDialog>
  );
};

export default memo(ProductDataSetDialog);
