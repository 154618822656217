import TextField from '@material-ui/core/TextField';
import React, { memo } from 'react';
import {ProductDataChangeSet} from "./ProductData";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FormikProps } from 'formik';

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(2)
  }
}));


interface ProductDataInputProps {
  autoFocus?: boolean;
  disabled?: boolean;
  fieldName: keyof ProductDataChangeSet;
  form: FormikProps<ProductDataChangeSet>;
  multiline?: boolean;
  fieldLabel: string;
}

function capitalize(s: string) {
  return `${s[0].toUpperCase()}${s.slice(1)}`;
}

function ProductDataInput({ fieldLabel, fieldName, form, autoFocus, disabled, multiline }: ProductDataInputProps) {
  const classes = useStyles();
  const capitalized = capitalize(fieldLabel);
  return (
    <div className={classes.input}>
      <TextField
        autoFocus={autoFocus}
        fullWidth
        variant="outlined"
        label={capitalized}
        placeholder={capitalized}
        name={fieldName}
        value={form.values[fieldName]}
        disabled={disabled}
        onBlur={form.handleBlur}
        onChange={form.handleChange}
        error={!!form.errors[fieldName]}
        helperText={form.errors[fieldName]}
        multiline={multiline}
      />
    </div>
  );
}

export default memo(ProductDataInput);
