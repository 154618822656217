import React, { useState } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Title from './DetailTitle';
import ExpressBtn from './ExpressBtn';
import PrePDF from './PrePDF';
import NewWindow from 'react-new-window';
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      border: '1px solid #ccc',
      borderRadius: '10px',
      backgroundColor: '#FFFFFF',
      padding: '5px 25px'
      // paddingTop:'-15px'
    },
    translateBox: {
      height: '100%',
      marginBottom: '10px',
      width: '100%',
      position: 'relative',
      paddingBottom: '30px'
    },
    contant: {
      height: '100%',
      overflowY: 'scroll',
      position: 'relative',
      padding: '15px'
    },
    fullScreen: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      zIndex: 6666,
      borderRadius: 0
    },
    img: {
      height: 255,
      display: 'block',
      maxWidth: 400,
      overflow: 'hidden',
      width: '100%'
    }
  })
);

const DataCollection2: React.FC<DataCollectionProps> = (props: DataCollectionProps): JSX.Element => {
  const { set, setMax, header } = props;
  const classes = useStyles();
  const [pdfFileOpen, setPdfFileOpen] = useState(false);
  const [curPdfName, setCurPdfName] = useState('');
  const [curPdfPage, setCurPdfPage] = useState(0);
  const [popTranslate, setPopTranslate] = useState(false);
  const handleCollectMax = () => {
    setPopTranslate(true)
  };
  const handleClick = (v) => {
    const pn = v.split('ページ')
    setCurPdfPage(pn[1])
    setCurPdfName(pn[0]);
    setPdfFileOpen(true)
  }

  return (!set ?
    <>
      <Box className={classes.translateBox}>
        <Title text={!header ? 'データ集2' : ''} Max={false} FnMax={() => handleCollectMax()} />
        <Box className={classes.contant}>
          {props?.data?.map((v, i) => {
            return <Typography
              onClick={() => handleClick(v)}
            >
              <span style={{ paddingRight: '10px' }}>{i + 1}.</span>
              <span style={{
                color: '#00a5d9',
                cursor: 'pointer',
              }}>{v}</span>
            </Typography>
          })}
        </Box>
      </Box>
      {pdfFileOpen && <NewWindow
        title={'データ集2'}
        center={'screen'}
        copyStyles={true}
        onUnload={() => {
          setPdfFileOpen(false)
        }}
      >
        <div>
          <PrePDF pre={curPdfPage} name="all" file={curPdfName} handleOpen={() => { }}></PrePDF>
        </div>
      </NewWindow>}

      {/* {
        popTranslate && <NewWindow
          title={'データ集2'}
          center={'screen'}
          copyStyles={true}
          onUnload={() => {
            setPopTranslate(false)
          }}
        >
          <div className={setMax ? [classes.root, classes.translateBox, classes.fullScreen].join(' ') : ''}>
            <Title text={!header ? 'データ集2' : ''} Max={!setMax} FnMax={() => handleCollectMax()} />
            <Box className={classes.contant}>
              {props?.data?.map((v, i) => {
                return <Typography
                  onClick={() => setCurPdfName(v)}
                >
                  <span style={{ paddingRight: '10px' }}>{i + 1}.</span>
                  <span style={{
                    color: '#00a5d9',
                    cursor: 'pointer',
                  }}>{v}</span>
                </Typography>
              })}
            </Box>
            <PrePDF pre={curPdfPage} name="all" file={pdfHeaderForPreview(curPdfName)} handleOpen={() => { }}></PrePDF>
            <PreviewPdf open={pdfFileOpen} setOpen={setPdfFileOpen} pdfValue={pdfHeaderForPreview(curPdfName)} />
          </div>
        </NewWindow>
      } */}
    </> :
    <>
      <span className="remove">データ集2</span>
      <Divider />
      <Grid container xs={12} spacing={2} style={{ padding: '15px' }}>
        <Grid item xs={6}>
          <Typography>ヘッダー表示</Typography>
          <ExpressBtn
            express={!props?.header}
            type="dis"
            comIndex={props?.settingIndex}
            layouts={props?.layouts}
            layoutFun={props?.layoutFun}
          />
        </Grid>
        <Grid item xs={6}>
          {/* <Typography>最大化</Typography>
          <ExpressBtn
            express={!props?.setMax}
            type="max"
            comIndex={props?.settingIndex}
            layouts={props?.layouts}
            layoutFun={props?.layoutFun}
          /> */}
        </Grid>
      </Grid>
    </>
  );
};

interface DataCollectionProps {
  Max?: boolean;
  data?: any;
  set?: any;
  setMax?: boolean;
  header?: boolean;
  settingIndex?: any;
  layouts?: any;
  layoutFun?: any;
}

export default React.memo(DataCollection2);
