import { memo } from 'react';
import ResponsiveDialog from '../../../../components/management/ResponsiveDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import SynonymForm from './KeyWordsForm';

function AddKeywordsSetDialog(props) {
  const { open, setOpen, onSave, createStatus } = props;
  return (
    <ResponsiveDialog open={open} onClose={() => setOpen(false)} maxWidth="sm">
      <DialogTitle>キーワード作成</DialogTitle>
      <SynonymForm onSave={onSave} onClose={() => setOpen(false)} initial={{
        word: [],
        category: '会話内容',
        label: '',
        sortOrder: '1',
        condition: 'or',
      }} status={createStatus} />
    </ResponsiveDialog>
  );
}

export default memo(AddKeywordsSetDialog);
