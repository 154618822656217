import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import withWrap from '../../withWrap';

const useStyles = makeStyles(() => {
  return createStyles({
    space: {
      marginTop: '20px'
    },
    paperWrap: {
      padding: '0 20px'
    }
  });
});

const BlueCheckbox = withStyles({
  root: {
    color: '#6E6B6BFF'
  },
  checked: {
    color: '#6CB0D5FF'
  }
})((props: CheckboxProps) => <Checkbox color='default' {...props} />);

const CheckGrid: React.FC<CheckGridProps> = (props: CheckGridProps): JSX.Element => {

  const { data } = props;
  const classes = useStyles();

  return (
    <>
      <Grid container xs={12} spacing={2} style={{
        maxHeight: '350px',
        overflowY: 'auto',
      }}>
        {data?.map((area: AreaData, index: number) => {
          const { label, checked, id } = area;
          return (
            <Grid item xs={6} key={id} >
              <Paper style={{ borderRadius: '4px' }}>
                <Grid container className={classes.paperWrap} direction='row' justify='space-between' alignItems='center'>
                  <Grid item>{label}</Grid>
                  <Grid item><BlueCheckbox checked={checked} /></Grid>
                </Grid>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
  ;

interface CheckGridProps {
  data: AreaData[];
}

interface AreaData {
  id: any;
  label: string;
  checked: boolean;
}

export type { AreaData };
export default withWrap<CheckGridProps>({
  styled: true,
  title: '提案領域'
})(CheckGrid);
