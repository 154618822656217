import { memo } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ResponsiveDialog from './ResponsiveDialog';

function DeleteDialog(props) {
  const { open, setOpen, handleDelete, modalName } = props;
  return (
    <ResponsiveDialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{modalName}削除</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{modalName}のデータを削除してもよろしいですか？</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>キャンセル</Button>
        <Button color="secondary" onClick={handleDelete}>
          はい
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}

export default memo(DeleteDialog);
