import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import AflacBg from './images/logo.png';
import Bg from './images/kosta-bratsos-lBPgzz3HGIo-unsplash.jpg';
import './css/index.model.css';
import { initUserMeInfo } from '../../utils/initUser';
import { deleteCookie } from '../../utils/General';
import { logout } from '../../api/userApi';
const RUN_ENV = process.env.REACT_APP_RUN_ENV;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      position: 'relative',
      minHeight: '100%',
      height: '100%',
      // backgroundColor: var+'(--white-lilac)',
      minWidth: '100%',
      background: 'url(' + Bg + ') no-repeat top center',
      flexDirection: 'column',
      backgroundSize: 'cover',
      '&::before': {
        content: "''",
        background: 'rgba(0, 165, 217, 0.8)',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
        zIndex: 0
      }
    },
    rootLoading: {
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    loading: {
      margin: '0 auto',
      top: '50%'
    },
    main: {
      position: 'absolute',
      padding: '40px',
      textAlign: 'center',
      verticalAlign: 'center',
      border: '1px solid #ced4da',
      borderRadius: '10px',
      height: 'auto',
      width: '520px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#FFFFFF',
      maxHeight: 'calc(100vh - 50px)'
    },
    title: {
      padding: '16px 0px',
      lineHeight: '28px',
      textAlign: 'center',
      fontSize: '16px'
    },
    linkTitle: {
      alignItems: 'center',
      color: '#00a5d9',
      display: 'block',
      textDecoration: 'underline',
      marginBottom: '.5rem!important'
    }
  })
);

const Logout = (props) => {
  const location = useLocation();
  const classes = useStyles();
  const logoutStatus = location.state ? location.state['logoutStatus'] : '';
  const [aflacMemberUrl, setAflacMemberUrl] = useState('');
  const [otherurl, setOtherUrl] = useState('');

  useEffect(() => {
    let timmer;
    timmer = setInterval(
      (window.onload = function () {
        console.log("document.readyState:", document.readyState)
        if (document.readyState === 'complete') {
          clearInterval(timmer);
          if (RUN_ENV != 'dev') {
            logout().then(function () {
              deleteCookie('aanet-sess-mvp4'); // delete aanet cookie
            });
          }
        }
      }),
      1000
    );
    if (RUN_ENV === 'stage') {
      setOtherUrl('https://st-a-line.aflac.co.jp/web01/asp/ServletAspMain?contents-id=125');
      setAflacMemberUrl('https://si4ata01.aflac.aflac.co.jp/web01/asp/ServletAspMain?contents-id=125');
    } else if (RUN_ENV === 'prod') {
      setAflacMemberUrl('https://edges.aflac.aflac.co.jp/web01/asp/ServletAspMain?contents-id=125');
      setOtherUrl('https://a-line.aflac.co.jp/web01/asp/ServletAspMain?contents-id=125');
    } else {
      setOtherUrl('');
      setAflacMemberUrl('');
    }
  }, []);

  return (
    <>
      <Paper className={classes.root}>
        <Box className={classes.main}>
          <img src={AflacBg} alt="Aflac BG" style={{ width: '124px' }} />
          <h1 className={classes.title}>
            {logoutStatus ? 'セッションがタイムアウトしました。' : 'ログアウトしました​。'}
            <br />
            {logoutStatus
              ? '下記より再度ログインしてください。'
              : '再度ログインする場合は、下記よりログインしてください。'}
          </h1>
          <Box>
            <a href={otherurl === undefined ? '#' : otherurl} className={classes.linkTitle}>
              アソシエイツ
            </a>
            <a href={aflacMemberUrl === undefined ? '#' : aflacMemberUrl} className={classes.linkTitle}>
              アフラック社員
            </a>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default Logout;
