import { memo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

function DeleteDialog(props) {
  const { open, setOpen, handleDelete, dialogTitle, dialogContent } = props;
  return (
    <StyledDialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>キャンセル</Button>
        <Button color="secondary" onClick={handleDelete}>
          はい
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default memo(DeleteDialog);
