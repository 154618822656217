import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Udialog from './Udialog';

const useStyles = makeStyles(() => {
  return createStyles({
    basic: {
      height: 40,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 70%, 50% 100%, 0 70%)',
      cursor: 'pointer'
    },
    title: {
      background: '#D9D9D9FF'
    },
    step: {
      background: '#00A5D9FF'
    },
    text: {
      bottom: 1,
      fontSize: 16,
      fontWeight: 500,
      position: 'relative'
    },
    white: {
      color: '#FFFFFFFF'
    },
    black: {
      color: '#000000FF'
    }
  });
});

const Step: React.FC<StepProps> = (props: StepProps): JSX.Element => {

  const { type, text, className } = props;
  const classes = useStyles();
  const cId = props?.conversionId;
  const kId = props?.keywordsId;
  const classNames = (classes: (string | '' | undefined)[]): string => classes.join(' ');

  const [dialogOpen, setDialogOpen] = useState(false)
  return (
    <>
      <Udialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        conversionId={cId}
        keywordsId={kId}
      />
      <div className={classNames([classes.basic, type === 'title' ? classes.title : classes.step, className && className])} onClick={() => { if (kId) setDialogOpen(true) }}>
        <span className={classNames([classes.text, type === 'title' ? classes.black : classes.white])}>
          {text}
        </span>
      </div>
    </>
  );
};

interface StepProps {
  text: string;
  type?: 'title' | 'step'
  className?: string;
  conversionId?: any;
  keywordsId?: any;
}

const defaultProps = {
  type: 'title'
};

Step.defaultProps = defaultProps as StepProps;

export default Step;
