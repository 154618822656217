import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootBox: {
      width: '100%'
    },
    root: {
      // borderRadius: '8px',
      // boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)'
      padding: '0px'
    },
    backButton: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);

const getSteps = (): string[] => {
  return ['目的選択', 'お客様情報入力', 'オンライン相談開始'];
};

const HorizontalLabelPositionBelowStepper: React.FC<HorizontalLabelPositionBelowStepperProps> = (props: HorizontalLabelPositionBelowStepperProps): JSX.Element => {

  const steps = getSteps();
  const classes = useStyles();

  return (
    <div className={classes.rootBox}>
      <Stepper activeStep={props?.activeStep} alternativeLabel className={classes.root}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

interface HorizontalLabelPositionBelowStepperProps {
  activeStep?: number | undefined;
}

export default HorizontalLabelPositionBelowStepper;
