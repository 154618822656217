import service from './service';
import { jsonRemoveNull } from '../utils/General'
export function getConversation(parameter) {
  return service({
    url: '/conversations',
    method: 'get'
  });
}

export function getConversationById(parameter) {
  return service({
    url: `/conversations/${parameter.id}`,
    method: 'get'
  });
}

export function searchConversation(parameter) {
  const filterP = jsonRemoveNull(parameter.data)
  const url =
    parameter.pageSize === -1
      ? `/conversations/search?orderby=implement_date&sort=desc`
      : `/conversations/search?pagesize=${parameter.pageSize}&page=${parameter.page}&orderby=implement_date&sort=desc`;
  return service({
    url: url,
    method: 'post',
    data: { ...filterP }
  });
}

export function getProductContractsList(parameter) {
  return service({
    url: `/products/contracts`,
    method: 'get'
  });
}

export function getConversationSummary(parameter) {
  const display = 120;
  const scan = 10;
  return service({
    url: `/conversations/${parameter.id}/summary?display=${display}&scan=${scan}`,
    requestBase: 'longTimeOut',
    method: 'get'
  });
}

export function getConversationCalls(parameter) {
  return service({
    url: `/conversations/${parameter.id}/calls/lines`,
    requestBase: 'longTimeOut',
    method: 'get'
  });
}

export function getKeywords(parameter) {
  return service({
    url: `/keywords`,
    method: 'get'
  });
}

export function getKeywordsChecked(parameter) {
  return service({
    url: `/conversations/${parameter.id}/domain`,
    method: 'post',
    data: {
      recogText: parameter.recogText
    }
  });
}

export function updateIspublic(parameter) {
  return service({
    url: `/conversations/${parameter.id}/public`,
    method: 'post',
    data: { isPublic: parameter.ispublic }
  });
}

export function deleteItem(parameter) {
  return service({
    url: `/conversations/${parameter.id}/delete`,
    method: 'post'
  });
}

export function deleteItemInNew(parameter) {
  return service({
    url: `/conversations/${parameter.id}`,
    method: 'delete'
  });
}

export function getProcessKeywords(parameter) {
  return service({
    url: `/conversations/${parameter.pId}/keywords/${parameter.kId}`,
    method: 'get'
  });
}

export function getBranchsByAgentCode(parameter) {
  return service({
    url: `/agst-mst/getAgentCompany`,
    method: 'post',
    requestBase: 'proxy',
    data: {
      method: 'GET',
      salt: 'MjAyMTA3MjIwMzAy',
      url: process.env.REACT_APP_API_PROXY_PATH + '/agst-mst/getAgentCompany',
      params: {
        agntCde: parameter.id
      }
    }
  });
}

export function getTraineeByComOrAgentCode(parameter) {

  const filterP = jsonRemoveNull(parameter)
  return service({
    url: `slsmn-sts/getAllTraineesForAgentCompany`,
    method: 'post',
    requestBase: 'proxy',
    data: {
      method: 'GET',
      salt: 'MjAyMTA3MjIwMzAy',
      url: process.env.REACT_APP_API_PROXY_PATH + '/slsmn-sts/getAllTraineesForAgentCompany',
      params: {
        ...filterP
      }
    }
  });
}

export function getRecruitersByAgentcode(parameter) {
  const url = parameter?.agentCodeList.length > 0 ? `/conversations/recruiters?agentCodes=${parameter?.agentCodeList.join(',')}` : `/conversations/recruiters`
  return service({
    url: url,
    method: 'get',
  });
}

export function getImplementer(parameter) {
  const url = parameter?.agentCodeList.length > 0 ? `/conversations/implementers?agentCodes=${parameter?.agentCodeList.join(',')}` : `/conversations/implementers`
  return service({
    url: url,
    method: 'get'
  });
}
export function exportScvFile() {
  return service({
    url: '/conversations/data/export',
    method: 'get'
  });
}
