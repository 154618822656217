import { useState } from 'react';
import { createStyles, Theme, withStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Modal, Button, Typography, FormControlLabel, RadioGroup } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import AnnotatedDialogContent from './AnnotatedDialogContent';
import AnnotatedDialogActions from './AnnotatedDialogActions';
import ModalHeadButton from './ModalHeadButton';
import { commitAnnotation } from '../../../../api/annotationApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import EventBus from '../../../../components/EventBus';
import AnnotatedConfirmDialog from './AnnotatedConfirmDialog';
import Radio from '../../../../components/RadioButtons';

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    color: '#FFFFFF',
    backgroundColor: '#21c79a',
    '&:hover': {
      backgroundColor: '#21c79a'
    }
  }
}))(Button);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      borderRadius: '3px',
      padding: '0px 0px 24px 24px',
      width: '90%'
    },
    button: {
      width: '140px',
      height: '40px',
      boxShadow: '2px 2px 5px rgb(0 0 0 / 30%)',
      borderRadius: '3px'
      // marginLeft: '32px',
      // marginRight: '40px'
    },
    anotaionArea: {
      width: '100%',
      height: '100%',
      position: 'relative'
    },
    dividor: {
      width: '100%',
      borderTop: '4px solid #7d7d7d25'
    }
  })
);

const AnnotatedModal = (props) => {
  const { open, setOpen, product, detailData, implementStatus, setImplementStatus, appraiseStatus, setAppraiseStatus } =
    props;
  const classes = useStyles();
  const trainId = useSelector((state: RootState) => state.annotation.trainId);

  const [confirmOpen, setConfirmOpen] = useState(false);

  const [actions, setActions] = useState({
    tbIndex: -1,
    index: -1,
    annotation: {}
  });

  const handleClose = () => {
    setActions({
      tbIndex: -1,
      index: -1,
      annotation: {}
    });
    setOpen(false);
    setImplementStatus('未');
    setAppraiseStatus('未');
  };
  // 完了報告
  const fixConversation = () => {
    commitAnnotation({
      trainid: trainId,
      implementStatus: implementStatus,
      appraiseStatus: appraiseStatus
    })
      .then(function (response) {
        if (response.status === 200) {
          EventBus.dispatch('show_snack', { message: '完了報告が完了しました。', type: 'info' });
          setConfirmOpen(false);
          // setImplementStatus('未');
          // setAppraiseStatus('未');
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleAnnImplmentation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    setImplementStatus(v);
  };
  const handleEvaluatio = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    setAppraiseStatus(v);
  };
  const body = (
    <div className={classes.paper}>
      <ModalHeadButton id="customized-dialog-title" onClose={handleClose} />
      <Box style={{ paddingRight: '24px' }}>
        <Grid container item direction="row" alignItems="center" justify="flex-end" style={{ margin: '10px 0px' }}>
          <Grid item direction="row" style={{display: 'inline-flex'}}>
            <Typography style={{ fontWeight: 600, width: '180px' }}>アノテーション実施:</Typography>
            <RadioGroup
              row
              aria-label="implementStatus"
              name="implementStatus"
              value={implementStatus}
              onChange={handleAnnImplmentation}
              style={{ width: '220px' }}
            >
              <FormControlLabel
                key={'0'}
                value={'未'}
                control={<Radio color="default" />}
                label={<span className={`radio-left ${implementStatus === '未' ? 'fw-700' : ''}`}>未</span>}
              />
              <FormControlLabel
                key={'1'}
                value={'済'}
                control={<Radio color="default" />}
                label={<span className={`radio-left ${implementStatus === '済' ? 'fw-700' : ''}`}>済</span>}
              />
              <FormControlLabel
                key={'2'}
                value={'対象外'}
                control={<Radio color="default" />}
                label={<span className={`radio-left ${implementStatus === '対象外' ? 'fw-700' : ''}`}>対象外</span>}
              />
            </RadioGroup>
          </Grid>
          <Grid  item direction="row"  style={{display: 'inline-flex'}}>
            <Typography style={{ fontWeight: 600, width: '130px' }}>評価用データ:</Typography>
            <RadioGroup
              row
              aria-label="appraiseStatus"
              name="appraiseStatus"
              value={appraiseStatus}
              onChange={handleEvaluatio}
              style={{ width: '180px' }}
            >
              <FormControlLabel
                key={'0'}
                value={'未'}
                control={<Radio color="default" />}
                label={<span className={`radio-left ${appraiseStatus === '未' ? 'fw-700' : ''}`}>未</span>}
              />
              <FormControlLabel
                key={'1'}
                value={'評価用'}
                control={<Radio color="default" />}
                label={<span className={`radio-left ${appraiseStatus === '評価用' ? 'fw-700' : ''}`}>評価用</span>}
              />
            </RadioGroup>
          </Grid>
          <ColorButton
            variant="contained"
            className={classes.button}
            startIcon={<DoneIcon />}
            onClick={() => setConfirmOpen(true)}
          >
            完了報告
          </ColorButton>
        </Grid>
        <Box className={classes.anotaionArea}>
          <AnnotatedDialogContent tableData={detailData} product={product} setAction={setActions} actions={actions} />
        </Box>
        <Box className={classes.dividor}></Box>
        <Box>
          <AnnotatedDialogActions actions={actions} setAction={setActions} />
        </Box>
      </Box>
      <AnnotatedConfirmDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        handleDelete={() => {
          fixConversation();
        }}
        dialogTitle="保存"
        dialogContent="編集を終了し完了報告します。よろしいですか？"
      />
    </div>
  );

  return (
    <div>
      <Modal className={classes.modal} open={open} onClose={handleClose} disableAutoFocus>
        {body}
      </Modal>
    </div>
  );
};

export default AnnotatedModal;
