import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
  return createStyles({
    arrow: {
      width: 20,
      height: '95%',
      background: '#B80000FF',
      clipPath: 'polygon(0, 0, 0, 0)' // TODO: not arrow yet
    }
  });
});

const Arrow: React.FC<ArrowProps> = (props: ArrowProps): JSX.Element => {

  const { className } = props;
  const classes = useStyles();

  const classNames = (classes: (string | '' | undefined)[]): string => classes.join(' ');

  return (
    <>
      <div className={ classNames([ classes.arrow, className && className ]) }/>
    </>
  );
};

interface ArrowProps {
  className?: string;
}

export default Arrow;
