import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Step from './Step';
import Arrow from './Arrow';
import withWrap from '../../withWrap';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      width: '100%',
      height: 'auto',
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center'
    },
    arrow: {
      margin: ' auto',
      position: 'absolute',
      top: '5px',
      left: '-10px',
      right: 0,
      bottom: 0
    },
    stepWrap: {
      width: '100%',
      paddingLeft: '12.5%',
      paddingRight: '12.5%',
      maxHeight: '350px',
      overflowY: 'auto',
    },
    stepSpace: {
      marginTop: '10px'
    }
  });
});

const Process: React.FC<ProcessProps> = (props): JSX.Element => {

  const classes = useStyles();
  const step = props?.steps;
  const cId = props?.conversionId;
  return (
    <>
      <div className={classes.root}>
        <div className={classes.stepWrap}>
          <Arrow className={classes.arrow} />
          {step?.map((v: any, index: number) => (
            <Step key={v?.id} className={classes.stepSpace} text={v?.label} type={v?.checked ? 'step' : 'title'} conversionId={cId} keywordsId={v?.id} />
          ))}
        </div>
      </div>
    </>
  );
};

interface ProcessProps {
  steps?: any;
  conversionId?: any;
}

export default withWrap<ProcessProps>({
  styled: true,
  title: '提案プロセス'
})(Process);
