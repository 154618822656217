import React, { memo } from 'react';
import ResponsiveDialog from '../../../../components/management/ResponsiveDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import SynonymForm from './SynonymForm';

const SynonymSetDialog = (props) => {
  const { open, setOpen, onSave, type, synonymSetData } = props;
  return (
    <ResponsiveDialog open={open} onClose={() => setOpen(false)} maxWidth="sm">
      <DialogTitle>{type === 'Add' ? '同意語を追加' : '同意語を編集'}</DialogTitle>
      <SynonymForm onSave={onSave} onClose={() => setOpen(false)} initialWords={synonymSetData} />
    </ResponsiveDialog>
  );
};

export default memo(SynonymSetDialog);
