import React, { memo } from 'react';
import ResponsiveDialog from '../../../../components/management/ResponsiveDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import SynonymForm from './KeyWordsForm';

function EditKeywordsSetDialog(props) {
  const { open, setOpen, onSave, updateStatus, synonymSetToEdit } = props;
  return (
    <ResponsiveDialog open={open} onClose={() => setOpen(false)} maxWidth="sm">
      <DialogTitle>Edit KeyWords</DialogTitle>
      <SynonymForm
        onSave={onSave}
        onClose={() => setOpen(false)}
        initial={synonymSetToEdit}
        status={updateStatus}
      />
    </ResponsiveDialog>
  );
}

export default memo(EditKeywordsSetDialog);
