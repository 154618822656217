import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  TablePagination,
  Button,
  Theme,
  Typography,
  IconButton
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TablePaginationActions from '../../../components/TablePaginationActions';
import AddIcon from '@material-ui/icons/Add';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { getAiTrainList, downloadZip, deploy, createAiTrain, updateDesc } from '../../../api/aiTrainApi';
import EnhancedTableHead from '../../../components/EnhancedTableHead';
import { formatDate,blobExport } from '../../../utils/ExportUtil';
import EditIcon from '@material-ui/icons/Edit';
import DialogOnly from '../../../components/management/DialogOnly';
import EventBus from './../../../components/EventBus';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleSearch: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    titleText: {
      fontWeight: 800,
      fontSize: '20px'
    },
    inputDel: {
      cursor: 'pointer',
      marginRight: '5px',
      opacity: '0.3',
      '&:hover': {
        opacity: '0.5'
      }
    },
    tableWrap: {
      alignItems: 'center',
      marginTop: '30px',
      boxShadow: '0 3px 4px rgb(0 0 0 / 14%), 0 3px 3px rgb(0 0 0 / 12%), 0 1px 8px rgb(0 0 0 / 20%)',
      borderRadius: '0px'
    },
    table: {
      width: '100%'
    },
    tableCellAction: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    fab: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      margin: theme.spacing(3),
      zIndex: 100
    },
    tableRow: {
      backgroundColor: '#e98300',
      '& .MuiTableCell-body': {
        color: '#fff'
      }
    },
    iconBtn: {
      padding: 'unset',
      verticalAlign: 'unset'
    }
  })
);

const Machinelearnreflection = (props) => {
  const classes = useStyles();
  // const navigate = useNavigate()

  //  table header begin
  const headCellsDef = [
    {
      id: 'model_id', // db column name
      disablePadding: false,
      label: 'モデルID', // table header column name
      active: false, // mouseout display is none except current is cliked
      order: 'asc', // default sort
      sorted: true // this column sort or not
    },
    {
      id: 'descriptions',
      disablePadding: false,
      label: 'モデル説明',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'status',
      disablePadding: false,
      label: 'ステータス',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'created_at',
      disablePadding: false,
      label: '作成日',
      active: false,
      order: 'asc',
      sorted: true
    },
    {
      id: 'action',
      disablePadding: false,
      label: '学習確認 / デプロイ',
      active: false,
      order: 'asc',
      sorted: false,
      style: {
        minWidth: '100px'
      }
    }
  ];

  // table datas
  const [rows, setRows] = useState([]);

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [totalCount, setTotalCount] = React.useState(0);

  const [orderby, setOrderby] = useState('');

  const [sort, setSort] = useState('');

  const [headCells, setHeadCells] = React.useState(headCellsDef);

  const [editDialogOpen, setEditDialogOpen] = React.useState(false);

  const [description, setDescription] = React.useState('');

  const [modelId, setModelId] = React.useState(0);

  const descriptionRef = React.useRef<HTMLInputElement>(null);

  const handleSort = (property) => {
    const changeSort = headCells?.map((v: any, i) => {
      if (v?.id === property) {
        if (v.order === 'asc') {
          v.order = 'desc';
        } else {
          v.order = 'asc';
        }
        setOrderby(property);
        setSort(v.order);
        v.active = true;
      } else {
        v.active = false;
      }
      return v;
    });
    setHeadCells([...changeSort]);
  };

  function getformData(searchData?) {
    getAiTrainList({
      orderby: orderby,
      sort: sort,
      words: searchData,
      pageSize: rowsPerPage,
      page: page
    })
      .then(function (response) {
        if (response.status === 200) {
          setRows(response.data.aiModels);
          setTotalCount(response.data.total);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(() => {
    getformData();
  }, [orderby, sort, page, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onDownloadClick = (modelId) => {
    downloadZip({ id: modelId })
      .then(function (response) {
        if (response.status === 200) {
          blobExport(`${modelId}.zip`, response.data);
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        EventBus.dispatch('show_snack', { message: 'ファイルのダウンロードに失敗しました', type: 'error' });
        console.log(error);
      });
  };

  const onDeployClick = (modelId) => {
    deploy({ id: modelId })
      .then(function (response) {
        if (response.status === 200) {
          getformData();
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleTrain = (params) => {
    createAiTrain()
      .then(function (response) {
        if (response.status === 200) {
        } else {
          console.log('code error');
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  let EditDialog = {
    title: 'モデル説明を編集',
    data: [
      {
        type: 'input',
        name: 'description',
        label: 'モデル説明',
        placeholder: 'モデル説明',
        value: descriptionRef.current?.value || description,
        uref: descriptionRef,
        required: false,
        handleChange: setDescription
      }
    ],
    handleSubmit: () => {
      updateDesc({ id: modelId, descriptions: descriptionRef.current?.value })
        .then(function (response) {
          if (response.status === 200) {
            getformData();
          } else {
            console.log('code error');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      setEditDialogOpen(false);
    }
  };

  const onHandleEditClick = (modelId, modelDesc) => {
    setDescription(modelDesc);
    setModelId(modelId);
    setEditDialogOpen(true);
  };
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
  };
  return (
    <Box className="root-m">
      <Box className={classes.titleSearch}>
        <Typography className={classes.titleText}>AI学習</Typography>
      </Box>
      <TableContainer component={Paper} className={classes.tableWrap}>
        <Table className={classes.table} aria-label="visitor detail">
          <EnhancedTableHead handleSort={handleSort} headCells={headCells} />
          <TableBody>
            {rows?.map((row: any) => (
              <TableRow key={row?.id} className={[row?.status === '反映' ? classes.tableRow : ''].join(' ')}>
                <TableCell align="left">{row.modelId}</TableCell>
                <TableCell align="left">{row.descriptions}</TableCell>
                <TableCell align="left">{row.status}</TableCell>
                <TableCell align="left">{formatDate(row.createdAt)}</TableCell>
                <TableCell align="center">
                  <EditIcon
                    style={{ marginRight: '15px', cursor: 'pointer' }}
                    onClick={() => onHandleEditClick(row?.modelId, row?.descriptions)}
                  />
                  {row?.status === '作成済' ||  row?.status === '反映'  ? (
                    <SaveAltIcon
                      style={{ marginRight: '15px', cursor: 'pointer' }}
                      onClick={() => onDownloadClick(row?.modelId)}
                    />
                  ) : (
                    <IconButton className={classes.iconBtn} disabled>
                      <SaveAltIcon style={{ marginRight: '15px', cursor: 'pointer' }} />
                    </IconButton>
                  )}
                  {row?.status === '作成済' ? (
                    <LocalShippingIcon style={{ cursor: 'pointer' }} onClick={() => onDeployClick(row?.modelId)} />
                  ) : (
                    <IconButton className={classes.iconBtn} disabled>
                      <LocalShippingIcon/>
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100, { label: 'All', value: totalCount }]}
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                labelRowsPerPage={''}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <DialogOnly
        title={EditDialog.title}
        data={EditDialog.data}
        handleSubmit={EditDialog.handleSubmit}
        open={editDialogOpen}
        setOpen={handleEditDialogClose}
      />
      <Button
        onClick={handleTrain}
        // disabled={disabled}
        className={classes.fab}
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
      >
        学習ファイル作成
      </Button>
    </Box>
  );
};

export default Machinelearnreflection;
